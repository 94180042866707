import store from '../store/store'

export default {
  checkAge(arr, path) {
    return (arr = path)
  },

  /*  findPermission(path) {
    try {
      let readPath = []
      if (store.getters.readPath != undefined) {
        readPath = store.getters.readPath
      }
      let enable = false
      let value = readPath.find((element) => {
        return element == path
      })
      if (value != null) {
        enable = true
      }
      return enable
    } catch (e) {
      return false
    }
  }, */

  findPermissionRead(keyname, path) {
    try {
      let permission = store.getters.permissions
      let permissionData = permission[keyname]
      let enable = false
      let isMenu = permission[keyname].menu

      if (permissionData !== undefined) {
        let menu = permissionData.menu
        if (permissionData.enable === true && permissionData.lock === false) {
          if (menu.length > 0) {
            for (const property in isMenu) {
              if (menu[property].path == path) {
                enable = menu[property].read
              }
            }
          } else {
            enable = permissionData.read
          }
        }
      }
      return enable
    } catch (e) {
      return false
    }
  },

  findPermissionExport(keyname, path) {
    try {
      let permission = store.getters.permissions
      let permissionData = permission[keyname]
      let enable = false
      let isMenu = permission[keyname].menu

      if (permissionData !== undefined) {
        let menu = permissionData.menu
        if (permissionData.enable === true) {
          if (menu.length > 0) {
            for (const property in isMenu) {
              if (menu[property].path == path) {
                enable = menu[property].export
              }
            }
          } else {
            enable = permissionData.export
          }
        }
      }
      return enable
    } catch (e) {
      return false
    }
  },

  findPermissionEdit(keyname, path) {
    try {
      let permission = store.getters.permissions
      let permissionData = permission[keyname]
      let enable = false
      let isMenu = permission[keyname].menu

      if (permissionData !== undefined) {
        let menu = permissionData.menu
        if (permissionData.enable === true) {
          if (menu.length > 0) {
            for (const property in isMenu) {
              if (menu[property].path == path) {
                enable = menu[property].edit
              }
            }
          } else {
            enable = permissionData.edit
          }
        }
      }
      return enable
    } catch (e) {
      return false
    }
  },

  findPermissionRemove(keyname, path) {
    try {
      let permission = store.getters.permissions
      let permissionData = permission[keyname]
      let enable = false
      let isMenu = permission[keyname].menu
      if (permissionData !== undefined) {
        let menu = permissionData.menu
        if (permissionData.enable === true) {
          if (menu.length > 0) {
            for (const property in isMenu) {
              if (menu[property].path == path) {
                enable = menu[property].remove
              }
            }
          } else {
            enable = permissionData.remove
          }
        }
      }
      return enable
    } catch (e) {
      return false
    }
  },

  showMenu(keyname, keyindexMenu) {
    try {
      let permission = store.getters.permissions

      let permissionData = permission[keyname]
      let enable = false

      if (permissionData !== undefined) {
        if (permissionData.lock === false) {
          let menu = permissionData.menu
          if (keyindexMenu !== -1) {
            if (this.checkKey(menu) > 0) {
              enable = menu[keyindexMenu].read
            }
          } else {
            enable = permissionData.enable
          }
        }
      }

      return enable
    } catch (e) {
      return false
    }
  },

  editMenu(keyname, keyindexMenu) {
    try {
      let permission = store.getters.permissions
      let permissionData = permission[keyname]
      let enable = false
      if (permissionData !== undefined) {
        let menu = permissionData.menu
        if (keyindexMenu !== -1) {
          if (this.checkKey(menu) > 0) {
            enable = menu[keyindexMenu].edit
          }
        } else {
          enable = permissionData.edit
        }
      }
      return enable
    } catch (e) {
      return false
    }
  },

  exportMenu(keyname, keyindexMenu) {
    try {
      let permission = store.getters.permissions
      let permissionData = permission[keyname]
      let enable = false
      if (permissionData !== undefined) {
        let menu = permissionData.menu
        if (keyindexMenu !== -1) {
          if (this.checkKey(menu) > 0) {
            enable = menu[keyindexMenu].export
          }
        } else {
          enable = permissionData.export
        }
      }
      return enable
    } catch (e) {
      return false
    }
  },

  deleteMenu(keyname, keyindexMenu) {
    try {
      let permission = store.getters.permissions
      let permissionData = permission[keyname]
      let enable = false
      if (permissionData !== undefined) {
        let menu = permissionData.menu
        if (keyindexMenu !== -1) {
          if (this.checkKey(menu) > 0) {
            enable = menu[keyindexMenu].remove
          }
        } else {
          enable = permissionData.remove
        }
      }
      return enable
    } catch (e) {
      return false
    }
  },

  checkKey(menu) {
    return Object.keys(menu).length
  },
}
