import Vue from 'vue'
import Router from 'vue-router'
import axios from '../services/auth'
import firebase from 'firebase/app'
import store from '../store/store'

// import NProgress from 'nprogress'
// import '../../node_modules/nprogress/nprogress.css'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

//Views - Login
const Login = () => import('@/views/login/Login')
const ForgotPassword = () => import('@/views/login/ForgotPassword')
const SilomConnect = () => import('@/views/login/SilomConnect')
const SilomSuccess = () => import('@/views/login/SilomSuccess')
const SilomConnectQR = () => import('@/containers/SilomConnectQR')
const EmailVerify = () => import('@/views/login/EmailVerify')
const SilomAlert = () => import('@/views/setting/SilomAlert')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Users
const User = () => import('@/views/users/User')
const Logout = () => import('@/views/users/Logout')
const PackageRenew = () => import('@/views/users/PackageRenew')

const PageNotFound = () => import('@/views/404')
const ErrorPage = () => import('@/views/401')
const LinkPOS = () => import('@/views/LinkPOS')

//Views - Shop
const Shop = () => import('@/views/shop/Shop')

//Views - Member
const Member = () => import('@/views/member/Member')
const CreateMember = () => import('@/views/member/CreateMember')
const MemberDetail = () => import('@/views/member/MemberDetail')
const MemberTransaction = () => import('@/views/member/Transaction')
const EditMember = () => import('@/views/member/EditMember')
const AddressList = () => import('@/views/member/AddressList')
const AddAddress = () => import('@/views/member/AddAddress')
const EditAddress = () => import('@/views/member/EditAddress')
const EditTaxInvoiceAddress = () => import('@/views/member/EditTaxInvoiceAddress')
const ImportMember = () => import('@/views/member/ImportMember')

//Views - Product
const Product = () => import('@/views/product/Product')
const ProductCategory = () => import('@/views/product/ProductCategory')
const ProductUnit = () => import('@/views/product/ProductUnit')
const AddProduct = () => import('@/views/product/AddProduct')
const ProductDetail = () => import('@/views/product/ProductDetail')
const PLUType = () => import('@/views/product/product-type/PLUType')
const BOMType = () => import('@/views/product/product-type/BOMType')
const SerialType = () => import('@/views/product/product-type/SerialType')
const AddBOM = () => import('@/views/product/product-type/AddBOM')
const ProductChannels = () => import('@/views/product/Channel/Channel') //channels
const ProductOptionGroup = () => import('@/views/product/option/ProductOptionGroup')
const ProductOption = () => import('@/views/product/option/ProductOption')
const AddOption = () => import('@/views/product/option/AddOption')
const EditOption = () => import('@/views/product/option/EditOption')
const AddOptionRatio = () => import('@/views/product/option/AddOptionRatio')
const AddOptionGroup = () => import('@/views/product/option/AddOptionGroup')
const EditOptionGroup = () => import('@/views/product/option/EditOptionGroup')
const ImportProduct = () => import('@/views/product/ImportProduct')
const ProductManage = () => import('@/views/product/ManageDelivery')
const ManageDelivery = () => import('@/views/product/ManageDelivery')
const SettingSalesChannels = () => import('@/views/product/SalesChannels')
const MangeSalesHours = () => import('@/views/product/SalesHours/MangeSalesHours')
const AddSalesHours = () => import('@/views/product/SalesHours/AddSalesHours')
const EditSalesHours = () => import('@/views/product/SalesHours/EditSalesHours')
const RankingManagement = () => import('@/views/product/RankingManagement')


//Add Product

const NewAddProduct = () => import('@/views/product/AddProduct/MainProductDetail')
const BasicInfo = () => import('@/views/product/AddProduct/Information')
const BomProduct = () => import('@/views/product/AddProduct/Bom')
const DiscountProduct = () => import('@/views/product/AddProduct/Discount')
const PackageProduct = () => import('@/views/product/AddProduct/Package')
const PriceProduct = () => import('@/views/product/AddProduct/Price')
const AddOnProduct = () => import('@/views/product/AddProduct/AddOn')
const SerialProduct = () => import('@/views/product/AddProduct/Serial')




//Views - Report
const TaxReport = () => import('@/views/report/Tax')
const CategoryReport = () => import('@/views/report/Category')
const ProductReport = () => import('@/views/report/Product')
const UnsellReport = () => import('@/views/report/Unsell')
const PaymentReport = () => import('@/views/report/Payment')
const CashierReport = () => import('@/views/report/Cashier')
const VoidBillReport = () => import('@/views/report/VoidBill')
const DailyReport = () => import('@/views/report/Daily')
const DailyDetail = () => import('@/views/report/DailyDetail')
const SkuReport = () => import('@/views/report/Sku')
const SellReport = () => import('@/views/report/Sell')
const PluReport = () => import('@/views/report/Plu')
const CloseSellReport = () => import('@/views/report/CloseSell')
const Timesheet = () => import('@/views/report/Timesheet')
const DailyOrder = () => import('@/views/report/DailyOrder')
const DailyOrderDetail = () => import('@/views/report/DailyOrderDetail')
const SellOrder = () => import('@/views/report/SellOrder')
const VoidBillOrder = () => import('@/views/report/VoidBillOrder')
const VoidBillOrderDetail = () => import('@/views/report/VoidBillOrderDetail')
const Delivery = () => import('@/views/report/Delivery')
const CustomPay = () => import('@/views/report/CustomPay')
const NonAdjust = () => import('@/views/report/ProductNonAdjustStock')
const StockInReport = () => import('@/views/report/StockIn')
const StockOutReport = () => import('@/views/report/StockOut')
const ExportProductList = () => import('@/views/report/ExportProduct')
const Optional = () => import('@/views/report/Optional')
const NewPayment = () => import('@/views/report/NewPayment')
const CashInOut = () => import('@/views/report/CashIn-Out')
const SalesChannels = () => import('@/views/report/SalesChannels')
const Table = () => import('@/views/report/table')
const Phone = () => import('@/views/report/Phone')
const OrderStatus = () => import('@/views/report/OrderStatus')
const OptionSales = () => import('@/views/report/OptionSales')
const TaxBillSales = () => import('@/views/report/TaxBillSales')
const TaxInvoice = () => import('@/views/report/TaxInvoice')
const DeliveryChannelsRoport = () => import('@/views/report/DeliveryByChannel')
const GrabDashboard = () => import('@/views/report/Grab')
const PromotionDashboard = () => import('@/views/report/Promotion')


//Views - Inventory
const SkuInventory = () => import('@/views/inventory/Sku')
const PluInventory = () => import('@/views/inventory/Plu')
const SerialInventory = () => import('@/views/inventory/Serial')
const SerialInventoryDetail = () => import('@/views/inventory/SerialDetail')
const StockCard = () => import('@/views/inventory/StockCard')
const StockCardDetail = () => import('@/views/inventory/StockCardDetail')
const LessStockInventory = () => import('@/views/inventory/LessStock')
const OutOfStockInventory = () => import('@/views/inventory/OutOfStock')

//Views - Stock
const StockInInventory = () => import('@/views/inventory/stock-in/StockIn')
const CreateStockIn = () => import('@/views/inventory/stock-in/CreateStockIn')
const StockInDetail = () => import('@/views/inventory/stock-in/StockInDetail')
const StockOutInventory = () => import('@/views/inventory/stock-out/StockOut')
const CreateStockOut = () =>
  import('@/views/inventory/stock-out/CreateStockOut')
const StockOutDetail = () =>
  import('@/views/inventory/stock-out/StockOutDetail')
const AdjustStockInventory = () =>
  import('@/views/inventory/adjust-stock/AdjustStock')
const CreateAdjustStock = () =>
  import('@/views/inventory/adjust-stock/CreateAdjustStock')
const AdjustStockDetail = () =>
  import('@/views/inventory/adjust-stock/AdjustStockDetail')
const CheckStockInventory = () =>
  import('@/views/inventory/check-stock/CheckStock')
const CreateCheckStock = () =>
  import('@/views/inventory/check-stock/CreateCheckStock')
const CheckStockDetail = () =>
  import('@/views/inventory/check-stock/CheckStockDetail')
const TransferInventory = () => import('@/views/inventory/transfer/Transfer')
const CreateTransferInventory = () => import('@/views/inventory/transfer/TransferOut')
const TransferOutDetailInventory = () => import('@/views/inventory/transfer/TransferOutDetail')
const TransferReport = () => import('@/views/inventory/transfer/TransferReport')

const TransactionReport = () => import('@/views/report/Transaction')
const CurrentBillReport = () => import('@/views/report/CurrentBill')

//setting
const Setting = () => import('@/views/setting/Setting')
const ShopSetting = () => import('@/views/setting/Shop')
const LanguageSetting = () => import('@/views/setting/Language')
const DeliveryProvidersSetting = () =>
  import('@/views/setting/DeliveryProviders')
const AdministratorToolsSetting = () =>
  import('@/views/setting/AdministratorTools')
const PermissionSetting = () => import('@/views/setting/Permission')
const SmartMenuSetting = () => import('@/views/setting/SmartMenu')
const PaymentSetting = () => import('@/views/setting/Payment')
const UserCashierSetting = () => import('@/views/setting/UserCashier')
const KioskSetting = () => import('@/views/setting/Kiosk')
const ProductSelfService = () => import('@/views/setting/SelfService')
const QROrderSetting = () => import('@/views/setting/QROrder')
const SidebarHelp = () => import('@/views/setting/SidebarHelp')
const EcommerceSetting = () => import('@/views/ecommerce/EcommerceSetting')
const EcommerceOrderList = () => import('@/views/ecommerce/OrderList')
const EcommerceProductList = () => import('@/views/ecommerce/ProductList')
const EcommerceOrderDetail = () => import('@/views/ecommerce/OrderDetail')
const EcommerceProductDetail = () => import('@/views/ecommerce/ProductDetail')
const EcommerceConnected = () => import('@/views/ConnectedSuccess')
const EcommerceConnectedFail = () => import('@/views/ConnectedFail')
const SelectProduct = () => import('@/views/ecommerce/SelectProduct')
const AddProductEcommerce = () => import('@/views/product/AddProduct')

//Views - Promotion
const Promotion = () => import('@/views/promotion/Promotion')
const CreatePromotion = () => import('@/views/promotion/CreatePromotion')
const PromotionDetail = () => import('@/views/promotion/PromotionDetail')
const PromotionTemplate = () => import('@/views/promotion/PromotionTemplate')
const PromotionTemplateForm = () => import('@/views/promotion/TemplateForm')

const InventoryNotification = () =>
  import('@/views/setting/InventoryNotification')

const GrabIntergration = ()=> import('@/views/setting/GrabIntergration')
const DeliveryOnOff = () => import('@/views/setting/DeliveryShopSetting')


// Call back
const KbankCallBack = () => import('@/views/callback/KbankCallback')
const Checkout = () => import('@/views/callback/checkout')

Vue.use(Router)

const router = new Router({
  //mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: configRoutes(),
})

function configRoutes() {
  return [
    {
      path: '*',
      component: PageNotFound,
    },
    {
      path: '/error',
      component: ErrorPage,
    },

    {
      path: '/open-download',
      component: LinkPOS,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/silom-connect/login',
      name: 'SilomConnect',
      component: SilomConnect,
    },
    {
      path: '/silom-connect/success',
      name: 'SilomSuccess',
      component: SilomSuccess,
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/acctmgmt/__/auth/action',
      name: 'emailverify',
      component: EmailVerify,
    },
    {
      path: '/ConnectedSuccess',
      name: 'ConnectedSuccess',
      component: EcommerceConnected,
    },
    {
      path: '/ConnectedFail',
      name: 'ConnectedFail',
      component: EcommerceConnectedFail,
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'grabdashboard',
          name: 'GrabDashboard',
          component: GrabDashboard
        },
        {
          path: 'promotiondashboard',
          name: 'promotionDashboard',
          component: PromotionDashboard
        },
        {
          path: 'silom-connect',
          name: 'SilomConnectQR',
          component: SilomConnectQR,
        },
        {
          path: 'user',
          meta: {
            label: 'User',
          },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              component: User,
            },
            {
              path: 'logout',
              component: Logout,
            },
            {
              path: 'package/renew',
              component: PackageRenew,
            },
          ],
        },
        {
          path: 'shop',
          meta: {
            label: 'Shop',
          },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              component: Shop,
            },
          ],
        },
        {
          path: 'member',
          meta: {
            label: 'Member',
          },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/member/data',
              name: 'Member',
              component: Member,
            },
            {
              path: '/member/create',
              name: 'CreateMember',
              component: CreateMember,
            },
            {
              path: '/member/:objectId/getdetail',
              name: 'MemberDetail',
              component: MemberDetail,
            },
            {
              path: '/member/:objectId/gettransaction',
              name: 'MemberTransaction',
              component: MemberTransaction,
            },
            {
              path: '/member/:objectId/editmember',
              name: 'EditMember',
              component: EditMember,
            },
            {
              path: '/member/:objectId/address',
              name: 'AddressList',
              component: AddressList,
            },
            {
              path: '/member/:objectId/address/add',
              name: 'AddAddress',
              component: AddAddress,
            },
            {
              path: '/member/:objectId/address/:addressObjectId',
              name: 'EditAddress',
              component: EditAddress,
            },
            {
              path: '/member/:objectId/editmember/editTaxInvoiceAddress',
              name: 'EditTaxInvoiceAddress',
              component: EditTaxInvoiceAddress,
            },
            {
              path: 'ImportMember',
              name: 'ImportMember',
              component: ImportMember,
            },
          ],
        },
        {
          path: 'product',
          redirect: '/product',
          meta: {
            label: 'Product',
          },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [

            {
              path: '',
              name: 'Product',
              component: Product,
            },
            {
              path: 'category',
              name: 'ProductCategory',
              component: ProductCategory,
            },
            {
              path: 'saleschannels',
              name: 'SettingSalesChannels',
              component: SettingSalesChannels,
            },
            {
              path: 'unit',
              name: 'ProductUnit',
              component: ProductUnit,
            },
            {
              path: 'addProduct',
              name: 'AddProduct',
              component: AddProduct,
            },
            {
              path: 'newAddproduct',
              name: 'newAddproduct',
              component: NewAddProduct,
              children: [
                // {
                //   path: '',
                //   name: 'BasicInfo',
                //   component: BasicInfo,
                // },
                // {
                //   path: 'info',
                //   name: 'BasicInfo',
                //   component: BasicInfo,
                // },
                {
                  path: 'bom/:productSKUObjectId',
                  name: 'BomProduct',
                  component: BomProduct,
                },
                {
                  path: 'discount/:productSKUObjectId',
                  name: 'DiscountProduct',
                  component: DiscountProduct,
                },
                {
                  path: 'package/:productSKUObjectId',
                  name: 'PackageProduct',
                  component: PackageProduct,
                },
                {
                  path: 'price/:productSKUObjectId',
                  name: 'PriceProduct',
                  component: PriceProduct,
                },
                {
                  path: 'addon/:productSKUObjectId',
                  name: 'AddOnProduct',
                  component: AddOnProduct,
                },

                {
                  path: 'addon/:productSKUObjectId',
                  name: 'SerialProduct',
                  component: SerialProduct
                }
              ]
            },
            {
              path: 'productdetail/',
              name: 'ProductDetail',
              component: NewAddProduct,
              children: [

                {
                  path: ':productSKUObjectId',
                  name: 'ProductDetail',
                },
                {
                  path: 'bom/:productSKUObjectId',
                  name: 'BomProductDetail',
                  // component: BomProduct,
                },
                {
                  path: 'discount/:productSKUObjectId',
                  name: 'DiscountProductDetail',
                  // component: DiscountProduct,
                },
                {
                  path: 'package/:productSKUObjectId',
                  name: 'PackageProductDetail',
                  // component: PackageProduct,
                },
                {
                  path: 'price/:productSKUObjectId',
                  name: 'PriceProductDetail',
                  component: PriceProduct,
                },
                {
                  path: 'addon/:productSKUObjectId',
                  name: 'AddOnProductDetail',
                  component: AddOnProduct,
                },
                {
                  path: 'serial/:productSKUObjectId',
                  name: 'SerialProductDetail',
                  component: SerialProduct
                },
              ]
            },
            // {
            //   path: 'productdetail/:productSKUObjectId',
            //   name: 'ProductDetail',
            //   component: ProductDetail,
            // },
            // {
            //   path: 'productdetail/plu/:productSKUObjectId',
            //   name: 'PLUType',
            //   component: PLUType,
            // },
            // {
            //   path: 'productdetail/bom/:productSKUObjectId',
            //   name: 'BOMType',
            //   component: BOMType,
            // },
            // {
            //   path: 'productdetail/serial/:productSKUObjectId',
            //   name: 'SerialType',
            //   component: SerialType,
            // },
            // {
            //   path: 'productdetail/bom/add/:productSKUObjectId',
            //   name: 'AddBOM',
            //   component: AddBOM,
            // },
            // {
            //   path: 'productdetail/channels/:productSKUObjectId',
            //   name: 'ProductChannels',
            //   component: ProductChannels,
            // },
            {
              path: 'optiongroup',
              name: 'ProductOptionGroup',
              component: ProductOptionGroup,
            },
            {
              path: 'option',
              name: 'ProductOption',
              component: ProductOption,
            },
            {
              path: 'option/addOption',
              name: 'AddOption',
              component: AddOption,
            },
            {
              path: 'option/editOption/:objectId',
              name: 'EditOption',
              component: EditOption,
            },
            {
              path: 'option/addOptionRatio/:objectId',
              name: 'AddOptionRatio',
              component: AddOptionRatio,
            },
            {
              path: 'optiongroup/addOptionGroup',
              name: 'AddOptionGroup',
              component: AddOptionGroup,
            },
            {
              path: 'optionGroup/editOptionGroup/:objectId',
              name: 'EditOptionGroup',
              component: EditOptionGroup,
            },
            {
              path: 'importProduct',
              name: 'ImportProduct',
              component: ImportProduct,
            },
            {
              path: 'deliverymanage',
              name: 'ManageDelivery',
              component: ManageDelivery
            },
            {
              path: 'mangesaleshours',
              name: 'MangeSalesHours',
              component: MangeSalesHours
            },
            {
              path: 'managemenuranking',
              name : RankingManagement,
              component: RankingManagement
            }
            // {
            //   path : 'mangesaleshours/add',
            //   name : 'AddSalesHours',
            //   component :AddSalesHours
            // },{
            //   path : 'mangesaleshours/edit/:objectId',
            //   name : 'EditSalesHours',
            //   component :EditSalesHours
            // }
          ],
        },
        {
          path: 'report',
          redirect: '/report',
          name: 'Report',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'tax',
              name: 'TaxReport',
              component: TaxReport,
            },
            {
              path: 'category',
              name: 'CategoryReport',
              component: CategoryReport,
            },
            {
              path: 'product',
              name: 'ProductReport',
              component: ProductReport,
            },
            {
              path: 'unsell',
              name: 'UnsellReport',
              component: UnsellReport,
            },
            {
              path: 'payment',
              name: 'PaymentReport',
              component: PaymentReport,
            },
            {
              path: 'cashier',
              name: 'CashierReport',
              component: CashierReport,
            },
            {
              path: 'voidbill',
              name: 'VoidBillReport',
              component: VoidBillReport,
            },
            {
              path: 'nonadjust',
              name: 'NonAdjust',
              component: NonAdjust,
            },
            {
              path: 'daily',
              name: 'DailyReport',
              component: DailyReport,
            },
            {
              path: '/report/daily/:shopObjectId/:date',
              name: 'DailyDetail',
              component: DailyDetail,
            },
            {
              path: 'transaction',
              name: 'TransactionReport',
              component: TransactionReport,
            },
            {
              path: 'currentbill',
              name: 'CurrentBill',
              component: CurrentBillReport,
            },
            {
              path: 'sku',
              name: 'SkuReport',
              component: SkuReport,
            },
            {
              path: 'sell',
              name: 'SellReport',
              component: SellReport,
            },
            {
              path: 'plu',
              name: 'PluReport',
              component: PluReport,
            },
            {
              path: 'closesell',
              name: 'CloseSellReport',
              component: CloseSellReport,
            },
            {
              path: 'Timesheet',
              name: 'Timesheet',
              component: Timesheet,
            },
            {
              path: 'dailyOrder',
              name: 'DailyOrder',
              component: DailyOrder,
            },
            {
              path: 'sellOrder',
              name: 'SellOrder',
              component: SellOrder,
            },
            {
              path: '/report/dailyOrder/:shopObjectId/:date',
              name: 'DailyOrderDetail',
              component: DailyOrderDetail,
            },
            {
              path: 'voidBillOrder',
              name: 'VoidBillOrder',
              component: VoidBillOrder,
            },
            {
              path: '/report/voidBillOrder/:shopObjectId/:date',
              name: 'VoidBillOrderDetail',
              component: VoidBillOrderDetail,
            },
            {
              //Delivery
              path: 'delivery',
              name: 'Delivery',
              component: Delivery,
            },
            {
              //CustomPay
              path: 'customPay',
              name: 'CustomPay',
              component: CustomPay,
            },
            {
              path: 'stockin',
              name: 'StockInReport',
              component: StockInReport,
            },
            {
              path: 'stockout',
              name: 'StockOutReport',
              component: StockOutReport,
            },
            {
              path: 'export-product',
              name: 'ExportProductList',
              component: ExportProductList,
            },
            {
              path: 'optional',
              name: 'Optional',
              component: Optional,
            },
            {
              path: 'newPayment',
              name: 'NewPayment',
              component: NewPayment,
            },
            {
              path: 'cashIn-Out',
              name: 'CashIn-Out',
              component: CashInOut,
            },
            {
              path: 'salesChannels',
              name: 'SalesChannels',
              component: SalesChannels,
            },
            {
              path: 'table',
              name: 'Table',
              component: Table,
            },
            {
              path: 'phone',
              name: 'Phone',
              component: Phone,
            },
            {
              path: 'orderStatus',
              name: 'OrderStatus',
              component: OrderStatus,
            },
            {
              path: 'optionSalesByDate',
              name: 'OptionSalesByDate',
              component: OptionSales,
            },
            {
              path: 'optionSalesByCost',
              name: 'OptionSalesByCost',
              component: OptionSales,
            },
            {
              path: 'taxBillSales',
              name: 'TaxBillSales',
              component: TaxBillSales,
            },
            {
              path: 'taxInvoice/:receiptObjectId',
              name: 'TaxInvoice',
              component: TaxInvoice,
            },
            {
              path: 'deliveychannels',
              name: 'DeliveryChannelsRoport',
              component: DeliveryChannelsRoport,
            },
          ],
        },
        {
          path: 'inventory',
          redirect: '/inventory',
          name: 'Inventory',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'sku',
              name: 'SkuInventory',
              component: SkuInventory,
            },
            {
              path: 'plu',
              name: 'PluInventory',
              component: PluInventory,
            },
            {
              path: 'serial',
              name: 'SerialInventory',
              component: SerialInventory,
            },
            {
              path: 'lessStock',
              name: 'LessStockInventory',
              component: LessStockInventory,
            },
            {
              path: 'outOfStock',
              name: 'OutOfStockInventory',
              component: OutOfStockInventory,
            },
            {
              path: 'serial/:objectId',
              name: 'SerialInventoryDetail',
              component: SerialInventoryDetail,
            },
            {
              path: 'stockcard',
              name: 'StockCard',
              component: StockCard,
            },
            {
              path: '/inventory/stockcard/:shopObjectId/:productSKUObjectId',
              name: 'StockCardDetail',
              component: StockCardDetail,
            },
            {
              path: 'stock-in',
              name: 'StockIn',
              component: StockInInventory,
            },
            {
              path: 'stock-in/create',
              name: 'CreateStockIn',
              component: CreateStockIn,
            },
            {
              path: 'stock-in/:stockInObjectId',
              name: 'StockInDetail',
              component: StockInDetail,
            },
            {
              path: 'stock-out',
              name: 'StockOut',
              component: StockOutInventory,
            },
            {
              path: 'stock-out/create',
              name: 'CreateStockOut',
              component: CreateStockOut,
            },
            {
              path: 'stock-out/:stockOutObjectId',
              name: 'StockOutDetail',
              component: StockOutDetail,
            },
            {
              path: 'adjust-stock',
              name: 'AdjustStock',
              component: AdjustStockInventory,
            },
            {
              path: 'adjust-stock/create',
              name: 'CreateAdjustStock',
              component: CreateAdjustStock,
            },
            {
              path: 'adjust-stock/:adjustStockObjectId',
              name: 'AdjustStockDetail',
              component: AdjustStockDetail,
            },
            {
              path: 'check-stock',
              name: 'CheckStock',
              component: CheckStockInventory,
            },
            {
              path: 'check-stock/create',
              name: 'CreateAdjustStock',
              component: CreateCheckStock,
            },
            {
              path: 'check-stock/:checkStockObjectId',
              name: 'AdjustStockDetail',
              component: CheckStockDetail,
            },
            {
              path: 'transferOut',
              name: 'TransferOut',
              component: TransferInventory,
            },
            {
              path: 'transferIn',
              name: 'TransferIn',
              component: TransferInventory,
            },
            {
              path: 'transfer/createTransfer',
              name: 'CreateTransfer',
              component: CreateTransferInventory,
            },
            {
              path: 'transfer/transferOutDetail/:transferObjectId',
              name: 'TransferOutDetail',
              component: TransferOutDetailInventory,
            },
            {
              path: 'transfer/transferReport',
              name: 'TransferReport',
              component: TransferReport,
            },
          ],
        },
        {
          path: 'setting',
          name: 'setting',
          component: Setting,
          children: [
            {
              path: 'shop',
              name: 'ShopSetting',
              component: ShopSetting,
            },
            {
              path: 'delivery',
              name: 'DeliveryProvidersSetting',
              component: DeliveryProvidersSetting,
            },
            {
              path: 'language',
              name: 'LanguageSetting',
              component: LanguageSetting,
            },
            {
              path: 'tools',
              name: 'AdministratorToolsSetting',
              component: AdministratorToolsSetting,
            },
            {
              path: 'smart-menu',
              name: 'SmartMenuSetting',
              component: SmartMenuSetting,
            },
            {
              path: 'permission',
              name: 'PermissionSetting',
              component: PermissionSetting,
            },
            {
              path: 'payment',
              name: 'PaymentSetting',
              component: PaymentSetting,
            },
            {
              path: 'cashier',
              name: 'UserCashierSetting',
              component: UserCashierSetting,
            },
            {
              path: 'self-service/qr-order',
              name: 'QRORder',
              component: QROrderSetting,
            },
            {
              path: 'self-service/kiosk',
              name: 'Kiosk',
              component: KioskSetting,
            },
            {
              path: 'self-service',
              name: 'AddProduct',
              component: ProductSelfService,
            },
            {
              path: 'sidebarHelp',
              name: 'SidebarHelp',
              component: SidebarHelp,
            },
            {
              path: 'grabintegration',
              name: 'GrabIntergration',
              component : GrabIntergration
            },
            {
              path : 'deliveryonoff',
              name : 'DeliveryOnOff',
              component : DeliveryOnOff
            },
            {
              path: 'silom-alert',
              name: 'SilomAlert',
              component: SilomAlert,
            },

            /* {
              path: 'inventory-notification',
              name: 'InventoryNotification',
              component: InventoryNotification,
            }, */
          ],
        },
        {
          path: 'ecommerce',
          name: 'ecommerce',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'ecommerceSetting',
              name: 'EcommerceSetting',
              component: EcommerceSetting,
            },
            {
              path: 'OrderList',
              name: 'EcommerceOrderList',
              component: EcommerceOrderList,
            },
            {
              path: 'OrderDetail/:order_sn',
              name: 'EcommerceOrderDetail',
              component: EcommerceOrderDetail,
            },
            {
              path: 'ProductList',
              name: 'EcommerceProductList',
              component: Product,
            },
            // {
            //   path: 'ProductDetail',
            //   name: 'EcommerceProductDetail',
            //   component: EcommerceProductDetail,
            // },
            {
              path: 'ProductList/ProductDetail:productSKUObjectId',
              name: 'EcommerceProductDetail',
              component: EcommerceProductDetail,
            },
            {
              path: 'ProductList/channels/:productSKUObjectId',
              name: 'EcommerceProductChannels',
              component: ProductChannels,
            },
            {
              path: 'ProductList/plu/:productSKUObjectId',
              name: 'EcommerceProductPLUType',
              component: PLUType,
            },
            {
              path: 'SelectProduct',
              name: 'EcommerceSelectProduct',
              component: SelectProduct,
            },
            {
              path: 'AddProductEcommerce',
              name: 'AddProductEcommerce',
              component: AddProduct,
            },
          ]
        },
        {
          path: 'promotion',
          redirect: '/promotion',
          meta: {
            label: 'Promotion',
          },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Promotion',
              component: Promotion,
            },
            {
              path: 'create',
              name: 'CreatePromotion',
              component: CreatePromotion,
            },
            {
              path: 'details/:objectId',
              name: 'PromotionDetail',
              component: PromotionDetail,
            },
            {
              path: 'template',
              name: 'PromotionTemplate',
              component: PromotionTemplate,
            },
            {
              path: 'template/create',
              name: 'PromotionTemplateForm',
              component: PromotionTemplateForm,
            },
          ],
        },
      ],
    },
    {
      path: '/callback/kbank/creditcard',
      name: 'PaymentCallBack',
      component: KbankCallBack,
    },
    {
      path: '/callback/kbank/thaiqr',
      name: 'PaymentCallBack',
      component: KbankCallBack,
    },
    {
      path: '/callback/kbank/alipay',
      name: 'PaymentCallBack',
      component: KbankCallBack,
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: Checkout,
    },
  ]
}

router.beforeEach((to, from, next) => {
  if (to.path === '/dashboard') {
    if (to.query.auth === undefined) {
      //เข้าระบบปกติ
      if (!localStorage.getItem('token')) {
        next('/login')
      }
      next()
    } else {
      //id = uid , auth = accessToke
      if (to.query.id !== undefined) {
        //NProgress.start()
        const uid = to.query.id
        const accessToken = to.query.auth
        localStorage.setItem('token', accessToken)
        localStorage.setItem('uid', uid)
        window.location.href = '/dashboard'
        //NProgress.done()
      }
    }
  } else if (to.path === '/login') {
    if (localStorage.getItem('token')) {
      next('/dashboard')
    }
    next()
  } else {
    next()
  }
})

// router.beforeResolve((to, from, next) => {
//   if (to.name) {
//     NProgress.start()
//   }
//   next()
// })

// router.afterEach((to, from) => {
//   NProgress.done()
// })

export default router
