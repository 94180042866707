import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/auth'
import user from '@/store/user'
import shop from '@/store/shop'
import product from '@/store/product'
// import access from '@/store/access'
import setting from '@/store/setting'

Vue.use(Vuex)

const modules = {
  Auth: auth,
  User: user,
  Shop: shop,
  Product : product,
  // Access: access,
  Setting: setting,
}

export default new Vuex.Store({
  modules,
})
