import { verify } from "jsonwebtoken"

const messages = {
    manageMenuRanking: 'Manage Menu Ranking',
    moreproduct: 'More Product',
    duration: '',
    stepToIntergrate: 'View the steps to connect Grab here: ',
    stepGrabandPOS: 'How to connect Grab with Silom POS',
    warningBeforeIntergration: 'Warning, please read : ',
    warningBeforeIntergrationDes: 'Always press the "Export Menu" button to retrieve your GrabFood menu to the POS machine. If you skip this step, your GrabFood menu will be lost and cannot be recovered.',
    connectGrab: 'Click to integration between Grab and Silom POS',
    openOrderTxt: 'Order ON/OFF',
    openOrderDes: 'Orders on the POS (You can still accept orders on the Merchant)',
    openOrder: 'Open for Orders',
    closeOrder: 'Close for Orders',
    duration30M: 'Temporary Close 30 Min',
    duration1H: 'Temporary Close 1 Hour',
    duration24H: 'Close 24 Hours',
    shopOnOff: 'Shop On/Off',
    deliveryShopSetting: 'Delivery On/Off',
    decShopDelivery: 'Set delivery opening and closing times, and manage the order receiving status for delivery.',
    pleaseFillShopName: 'Please enter the shop name.',
    pleaseFillBranchName: 'Please enter the branch name.',
    pleaseFillBusinessType: 'Please select a business type.',
    deliveryGrab: 'Manage Grab Delivery',
    grabIntegration: 'Grab Integration',
    grabIntergrationDes: 'The integration between Grab and Silom POS allows merchants to manage orders from Grab automatically within the POS system. <br> This reduces the hassle of manual data entry, increases accuracy and speed, and helps manage inventory and sales in real-time. <br/> Overall, it enhances operational efficiency and opens opportunities for business expansion through online channels effectively.',
    grabIntergrationDes1: 'The process of connecting with Grab takes approximately 30 minutes to 1 hour, so it is recommended to proceed after store closing hours.',
    grabIntergrationDes2: 'If you haven’t registered for the GrabMerchant app yet, please complete the registration via the app or the Grab website before proceeding.',
    grabIntergrationDes3: 'After successful integration, it is recommended to create products exclusively through Silom POS rather than on GrabMerchant.',
    grabIntergrationDes4: 'Complete all data updates in one go before uploading, as you will need to wait in line for Grab to fetch the information.',
    grabIntergrationDes5: 'When you reach the Enabling Integration page, do not exit the website or navigate to another menu under any circumstances.',
    grabIntergrationDes6: 'to prevent potential errors and ensure that data is synced accurately and completely.',
    manageStock: 'Manage Stock',
    immediately: 'Immediately',
    everyDay: 'Every Day',
    everyWeek: 'Every Week',
    maxStock: 'Maximum Stock',
    replenishment: 'Replenishment',
    deliveryStock: 'Set product stock quantity and define stock replenishment.',
    enterStockOndelivery: 'Input Stock on Product Delivery',
    deliveryMD: 'Manage data for product delivery.',
    syncWhenEdit: 'Synchronize data every time changes are made to delivery items to update the information in Grab.',
    syncWhenEditProduct: 'If data is edited but synchronization is not performed, the information in Grab will not be updated.',
    lastSync: 'Last Sync',

    setSellingHoursForProduct: 'Set the selling hours for the product to display it according to Grab’s sales hours.',
    salesHours: 'Sales Hours',
    promotionDashboardMenu: 'Promotion Report',
    grabDashboardMenu: 'Grab Report',
    confirmDeletSellingHours: 'Confirms Delete Sales Hours',
    dialigDeleteSellingHours: 'Do you want to delete this sales hour?',
    timeSlotOverlap: "Time slots cannot overlap. Please adjust the time.",
    maxSlotsReached: "The maximum number of time slots has been reached.",
    noMoreTimeSlots: "No more time slots can be added.",
    selfPickUp: 'Self Pick Up',
    delivery: 'Delivery',
    editsalesHoursManagement: 'Edit Sales Hours',
    salesHoursSetting: 'Sales Hours Setting',
    setSelling: 'Define sales periods.',
    txt2SalesHoursSetting: 'You can define the sales periods for products by navigating to **Delivery Management**',
    addsalesHoursManagement: 'Add Sales Hours ',
    txtSalesHoursSetting: "To define sales periods for items in the Grab menu, allowing up to 3 sales periods per day, with a maximum of 3 time slots per day",
    txtSalesHoursSettingDes: 'Ex.  Morning <br>  <p class="ml-3">• Monday has 3 time slots as follows: 8:00 - 9:00 , 10:00 - 11:00 , 10:01 - 12:00 </p>',
    fillSaleName: 'Please enter the sales name',
    salesTime: "Sales Hours",
    txtsalesHours: "Sales Hours Name",
    addSalesHours: 'Add Sales Hours',
    salesHoursManagement: 'Sales Hours Management',

    connectDes: 'Your key assistant that helps you manage your business effortlessly,<br> check sales instantly, and stay connected to your store anytime, anywhere.',
    scanQrtoAddLine: "Scan QR Code to add LINE",
    applyForSilomConnect: 'How to Apply for Silom Smart Connect',
    allOrders: 'All ',
    successOrders: 'Success',
    cancelOrders: 'Cancel',
    topFivePromotion: "Top 5 Promotion",
    shopPromotion: 'Shop Promotion',
    promotionUsage: 'Promotion Usage',
    promotionTransection: 'Promotion Transaction',
    grabPromotion: 'Grab Promotion',
    shopPromotion: 'Shop Promotion',
    grabPromotion: 'Grab Promotion',
    promotionStartDate: "Promotion Start Date",
    PromotionEndDate: "Promotion End Date",
    promotionRemaining: "promotionRemaining",
    synchQueueing: "Synch Queueing",
    synchProcessing: "Synch Processing",
    synchFail: "Synch Fail",
    synchFailDescription: "An error occurred while checking the sync status. Please try again.",
    synchSuccess: "Synch Success",
    totals: "Totals",
    addBom: "Add Material",
    activeMat: "Material Active",
    addOnActive: ' Add On Active',
    moredeli: "More Delivery Price",
    deliveryPrice: 'Delivery Price',
    itemCount: "Item(Count)",
    grabReportMenu: "Grab Report",
    promotionReportMenu: "Promotion",
    deliveryManagement: "Delivery Management",
    noData: "No data",
    orderStatus: "Order Status",
    orderSuccess: "Order Success",
    orderCancel: "Order Cancel",
    orderTotal: "Order Total",
    totalRevenue: "Total Revenue",
    success: "Success",
    hourlySalesReport: "Hourly Sales Report",
    grabReport: "Grab Report",
    orderSummary: "Order Summary",
    price: "",
    confirmSynch: "Confirm Synch Menu",
    synchSuccess: "Synch Menu Success",
    promotionCode: "Promotion Code",
    promotionTotal: "Promotion Total",
    txtSalesPromotionReport: "Promotion Report",
    priceOndelivery: "Price on Deilivery",
    deliveryOnDemand: "Delivery",
    deliveryScheduled: "Delivery on scheduled",
    selfPickUpOnDemand: "Self PickUp",
    dineInOnDemand: "Dine In",
    productDes: "Product Description",
    salesChannels: 'Sales Channels',
    channelsDescriptionConfig: 'Sales channels seting for delivery items',
    isAVGCostCalc: 'AVG Cost Calculate',
    passwordInvalid: "The password is invalid or the user does not have a password.",
    emailBadlyFormatted: "The email address is badly formatted.",
    userNotFound: "There is no user record corresponding to this identifier. The user may have been deleted.",
    wrongEmail: 'The email address is badly formatted',
    tryVerifiedEmail: 'Try verifying your email again',
    signinNewAcc: 'You can now sign in with your new account',
    emailVeified: 'Your email has been verified',
    loginNewPass: 'You can now sign in with your new password',
    passwordchanged: 'Password changed',
    tryresetpassword: 'Try resetting your password again',
    checkEmail: 'The system has successfully sent an email. Please check your inbox.',
    for: 'for',
    requirenewpass: 'Request a new password',
    plsLoginAgain: 'Your token has expired. <br> Please log in again to update the information. <br> Would you like to log in again?',
    requestQR: 'If you have not received the verification code, you can request it here',
    passwordNotCorrect: 'Your code is incorrect. Please check again',
    addressDesc: 'Store address for delivery and QR-Order service',
    adminToolDesc: 'Only one device (or the number allowed by your package) can be active per account at the time, If you need real-time monitoring, please use the Dashboard application. For placing orders, use the Staff Order application',
    loginToPOS: 'Login to Silom POS',
    loginDes: 'Please fill in the following form to enter the Silompos system.',
    help: 'help',
    transferwarn: 'The transfer of products between branches must only occur between branches created under the same email. It cannot be used with branches registered under different emails.',
    carousel: {
        slide1: {
            title: "Try QR-ORDER for Free",
            buttonLabel: "Contact Staff"
        },
        slide2: {
            title: "Join Activities with Silom POS",
            buttonLabel: "Join Activity"
        },
        slide3: {
            title: "Have you registered for Line Alert?",
            buttonLabel: "Activate"
        },
        slide4: {
            title: "Contact Us @SilomPOS",
            buttonLabel: "Contact Staff"
        },
        slide5: {
            title: "Big Menu, Simplified for You!",
            buttonLabel: ""
        },
        slide6: {
            title: "Connect with Grab, Boost Sales!",
            buttonLabel: ""
        },
        slide7: {
            title: "Boost Sales with Full Promotions!",
            buttonLabel: ""
        },
        slideLineNotify: {
            title: "Annoucement from Silom POS",
            buttonLabel: ""
        },
        slideLineNotifyAds: {
            title: "Receive notifications of every movement.",
            buttonLabel: "Activate"
        }
    },
    wrongCountryCode: 'Wrong country code.',
    dupTel: 'Duplicate Tel.',
    contactAddress: 'Contact Address',
    createNew: 'Create',
    updateMember: 'Update',
    saveAction: 'Save New',
    saveUpdate: 'Save and Update',
    saveAs: 'Save As',
    importMember: 'Import Member',
    totalOfImportMembers: 'Total Of Import Members',
    confirmImportMemberHeader: 'Save Member List',
    alertImportMemberDialog: 'Members that failed validation will not be saved',
    alertImportMemberDialog2: 'The information to be updated will be the most recent data saved.',
    confirmImportMember: 'Do you want to save the member list?',
    saveMemberSuccessDialog: 'Member list imported successfully',
    saveMemberSuccessDialog2: 'Please check the saved data',
    importMemberWarning: 'Member import in progress',
    importMemberWarningDialog: 'The previous member import has not been completed yet',
    importMemberPleaseWait: 'Please wait 5 minutes before importing new members again',
    nameMemberRequired: "Name is required.",
    phoneRequired: "Phone is required.",
    invalidPhone: "Invalid Phone Number.",
    phoneExistsUpdate: "Phone already exists. Data will be updated.",
    validationFailed: "Validation Failed",
    countryCode: 'Country code',
    onhandQtyBalanceLessthan0: 'Inventory quantity is less than 0',
    onhandNow: 'Remaining stock balance',
    store: 'Store',
    online: 'Online',
    stockOutGuideTitle: "Guide for Creating Stock-Out Documents",
    stockOutMethodsIntro: "Stock-out documents are created to reduce the inventory in the system to match the physical warehouse stock.",
    stockOutStepsTitle: "Steps to Create Stock-Out Documents",
    stockOutStep1: "Click the button to create a stock-out document.",
    stockOutStep2: "Click the button to add products or import a stock-out document.",
    stockOutStep3: "Fill in the necessary information such as quantity and price.",
    stockOutStep4: "Click the button to save the document.",
    stockInGuideTitle: "Guide for Creating Stock-In Documents",
    stockInMethodsIntro: "Stock-in documents are created to increase the inventory in the system to match the physical warehouse stock.",
    stockInStepsTitle: "Steps to Create Stock-In Documents",
    stockInStep1: "Click the button to create a stock-in document.",
    stockInStep2: "Click the button to add products or import a stock-in document.",
    stockInStep3: "Fill in the necessary information such as quantity and price.",
    stockInStep4: "Click the button to save the document.",
    checkStockGuideTitle: "Guide for Creating Check Stock Documents",
    checkStockMethodsIntro: "A Check Stock document is used to record the count of stock items, which can be later used in receipt, issue, and adjust stock documents.",
    checkStockStepsTitle: "Steps for Creating a Check Stock Document",
    checkStockStep1: "Press the Create Document button.",
    checkStockStep2: "Enter the document name in the designated field.",
    checkStockStep3: "Press the Add Product button and select the products you want to count.",
    checkStockStep4: "Enter the quantity of products in the counting field (this can be edited later when creating receipt or issue documents).",
    checkStockStep5: "Press the Confirm button to save the document.",
    adjustGuideTitle: "Guide for Creating Adjust Stock Documents",
    adjustMethodsIntro: "A adjust stock document is used to adjust individual stock items to ensure that the quantities in the warehouse match those in the system.",
    adjustStepsTitle: "Steps for Creating a Adjust Stock Document",
    adjustStep1: "Press the Adjust Stock button.",
    adjustStep2: "Press the Add Product button or import from the check stock document.",
    adjustStep3: "Enter the stock quantity for each product and press Confirm.",
    setMenu: 'How To Set Menu',
    setShopOpenClose: 'How to Set Store Opening and Closing Hours',
    exportInvoice: 'How to Issue a Tax Invoice',
    moreVideo: 'Go to Silom Support',
    videofor: 'Video Tutorial For',
    searchPlaces: "Search for a place",
    goToShopSetting: 'Go to Setting',
    storeLocationDespt: "The store must specify its location",
    customerLocationAccessDespt: "Customers' mobile phones must allow location access",
    customerRadiusDespt: "Customers must be within a 100-meter radius of the store",
    staffOpenTableDespt: "Staff must open the table before allowing customers to scan and access the QR Order system",
    suggest: 'Suggested',
    enterPinDespt: "Customers must enter a 4-digit pin from the staff to access the QR Order system",
    bestSecure: "Excellent",
    goodSecure: "High",
    moderateSecure: "Moderate",
    noSecure: "Not secure",
    securityLevel: 'Security Level',
    dynamicQR: 'Dynamic',
    staticQR: 'Static',
    dynamicQRDespt: "Print a new one every time the table is opened",
    staticQRDespt: "Print once and stick it on the table",
    qrType: 'Type of QR',
    setSecurityOfQR: 'Set the security level of QR Order',
    setSecurityOfQRDespt: 'To prevent food orders from unauthorized individuals outside the restaurant, <br> please set the conditions for accessing QR Order system',
    noLocationPinned: 'The location has not been pinned yet',
    pinShopLocation: 'Pin Shop\'s Location',
    changeOpenClose: 'Changing the stores opening and closing hours will affect the calculation of bills in the daily sales report. Please make the changes before processing the first bill of the modified date.',

    plsLoginShopee: 'Please log in to Shopee again to verify your identity.',
    pullSuccess: 'Save Receipt success',
    shopeeDesc1: 'After editing the data, you need to sync the products from the marketplace again for the changes to take effect.',
    shopeeDesc2: 'The changes will only affect the first product option. To edit other product options, you must modify the other package sizes.',
    descinformation: 'Description',
    thailand: 'Thailand',
    existsBill: 'Exists bill',
    noExistsBill: 'Not exists bill',
    unavailable: 'Unavailable',
    incancel: 'In cancel',
    complete: 'Complete',
    invoicePending: 'Invoice Pending',
    authorize: 'Authorize',
    confirmSaveProductTitle: 'Confirm Product Retrieval',
    confirmPullProduct: 'Do you want to retrieve data from the marketplace?',
    plsLoginShopee: 'Please log in to marketplace again to verify your account.',
    inventoryQuantity: 'Inventory quantity',
    notFoundProduct: 'Product not found in the store. Please add product information from the marketplace.',
    readyToShip: 'Ready to ship',
    payed: 'Payed',
    cancelled: 'Cancelled',
    shipping: 'Shipping',
    payWaiting: 'Waiting pay',
    shipped: 'Shipped',
    itemTotal: 'Item Total',
    printReceiptToPOS: 'Save receipt',
    morethan15days: "Please select a date range no more than 15 days in the past.",
    syncSuccess: 'Sync Success',
    pleaseChooseMoreTimeRange: 'Please select a start date and an end date that are not the same.',
    systemError: 'The system is currently experiencing an error. Please try again later.',
    shopCoverShipping: 'Sellers covers the shipping costs',
    customerAccount: 'Customer Account',
    addressShipping: 'Shipping Address',
    pullproductFail: "Failed to pull product",
    pullFailDesc: "The product could not be imported into the system. Please reconnect the marketplace.",
    exists: "The product is already in the system",
    noExists: "The product is not in the system yet",
    currency: 'currency',
    connectDetail: 'Connected Detail',
    addConnect: 'Add Connecting',
    shopeeDesc: 'Connecting with Shopee streamlines your online business management by enabling real-time synchronization of product information, orders, and inventory. This reduces redundant tasks, saves time, increases accuracy, and expands your market reach to a large customer base on the Shopee platform.',
    selectProductDesc: 'The products will be retrieved from the marketplace. If a product does not already exist in the system, it will be added as a new entry. If the product already exists, its information will be updated accordingly.',
    selectProduct: 'Select products from the marketplace.',
    eComSetting: 'Marketplace Settings',
    saveProductWarn: 'The products will be updated on the e-commerce website',
    used: 'Used',
    otherDes: 'I will ship out within 2 business days. (excluding public holidays and courier service non-working days)',
    yes: 'Yes',
    no: 'No',
    Condition: 'Condition',
    preOrder: 'Pre-Order',
    others: 'Others',
    maximumPurchase: 'Maximum Purchase Quantity',
    minimumPurchase: 'Minimum Purchase Quantity',
    specialtyDiet: 'Specialty Diet',
    volume: 'Quantity',
    packageSize: 'Package Size',
    expiryDate: 'Expiry Date',
    licenseNum: 'FDA Registration No.',
    shelfLife: 'Shelf life',
    ingredient: 'Ingredient',
    countryOrigin: 'Country of Origin',
    brand: 'Brand',
    fillProductDetail: 'Please fill product detail',
    shippingWarn: 'Shipping settings will be applicable for this product only. Shipping fees displayed are base rates and will be subject to change depending on buyer and seller location.',
    shippingFee: 'Shipping Fee',
    parcelSize: 'Parcel Size',
    weight: 'weight',
    shipping: 'Shipping',
    salesInfo: 'Sales Information',
    specification: 'Specification',
    ecomProduct: 'Product from Marketplace',
    pleaseCheckProductSync: 'Please check the products after updating the information.',
    connectEcomDesc: 'The system will automatically import all product and customer information from this channel after a successful connection. Other important information, such as payment and shipping details, will only be imported for events that occur after the connection with the store is completed.',
    syncInfo: 'Sync Infomation',
    authorizeExpire: 'Authorize Expired Date',
    country: 'Country/Region ',
    authorizeBy: 'Authorized By',
    storeID: 'Store ID',
    storeInfo: 'Store Information',
    authorizeDate: 'Authorize Date',
    storeName: 'Store Name',
    sync: 'Sync',
    connect: 'Connect',
    connectMarketPlaces: 'Connect your marketplace accounts',
    myincomedetails: 'My income details',
    orderamendments: 'Order amendments',
    incomefromorder: 'Income from order',
    feePayment: 'Payment fee',
    totalCommission: 'Total commission',
    shippingByBuyer: 'Shipping paid by buyer',
    shippingByOwner: 'Shipping paid by provider',
    feesandcharges: 'Fees and charges',
    promotionForCreditCard: 'Promotion for credit card',
    totalShippingPrice: 'Total shipping cost',
    totalPricePayMent: 'Total amount payable',
    paymentDetailTxt: 'Payment details',
    purchaseOrderDetail: 'Purchase order details',
    shippingAddressTxt: 'Shipping address',
    nameforShipping: 'Name for shipping',
    telShippig: 'Contact number',
    shippingDetailTxt: 'Shipping information',
    printShippingLabel: 'Print shipping label',
    deliveryDriver: 'Delivery driver',
    lastUpdated: 'Last Updated :',
    OrderListTXT: 'Order List From E-Commerce',
    ProductListMenu: 'Product Details',
    OrderListMenu: 'Order List',
    completeTime: 'Complete Time',
    shippingTime: 'Shipping Time',
    paymentTime: 'Payment Time',
    orderTime: 'Ordering Time',
    EcommerceMenu: 'Integraions Marketplace',
    EcommerceMenuTag: 'Integraions',
    priceMustBeGreaterThanZero: "The price must not be less than 0",
    importRemark: 'After importing the product list into the system, you will no longer be able to modify or import new products with duplicate barcodes. If you wish to edit product details such as names or prices, you can do so via the POS system or the product menu. To import new products, you must first remove any duplicate products.',
    allItems: 'all Items',
    inventoryQuantity: 'Inventory Quantity',
    totalCostValue: 'Total Cost Value',
    totalSalesValue: 'Inventory Value',
    DestinationdonthaveItem: 'The destination branch does not have the selected item',
    allItems: 'All Items',
    taxInvoiceBill: 'Tax Invoice',
    printTaxInvoiceBill: 'Print Full-Format Tax Invoice',
    printshortTaxInvoiceBill: 'Print Simplified Tax Invoice',
    totalCost: 'Total Cost',
    searchMembersale: 'Please Enter Member Name',
    searchtelsale: 'Please Enter Member Tel.',
    totalITemVat: 'Total for VAT items',
    vat: 'VAT amount',
    netTotalIncVATDiscount: 'Total for VAT items (after discount)',
    netTotalBeforeVat: 'Total before VAT',
    isnotVatIncluded: 'Is not Vat Included',
    vatType: 'VAT Type',
    promotionPrice: 'Promotion price',
    memberPrice: 'Member price',
    taxBillSalesMenu: 'Sales for Tax Invoice Bills',
    taxBillSalesTXT: 'Sales for Tax Invoice Bills Report',
    optionSalesMenu: 'Add-on Category Sales',
    optionSalesByDateMenu: 'Add-on Category Sales By Date',
    optionSalesByCostMenu: 'Cost By Add-on',
    optionSalesReportTXT: 'Add-on Category Sales Report',
    optionSalesByDateReportTXT: 'Add-on Category Sales By Date Report',
    optionSalesByCostReportTXT: 'Cost By Add-on Report',
    orderStatusReportMenu: 'Order Status',
    orderStatusReportTXT: 'Order Status Report',
    billStatus: 'Bill Status',
    phoneReportMenu: 'Sales By Customer',
    phoneReportTXT: 'Sales By Customer Report',
    orderType: 'Order Type',
    dineIn: 'Dine-in',
    takeAway: 'Take Away',
    agreed: 'Accept',
    exampleCover: 'Example of displaying the store cover image',
    coverDespt: 'Display as the background image with the logo on the start page when customers scan to access the QR Order system',
    basket: 'Basket',
    searchByName: 'Search By Name...',
    viewAllFood: 'All',
    foodCategory: 'Category',
    tableEx: 'Table No.7',
    themeSuggest: 'The background color should be dark to ensure it does not blend with the white text',
    selfCustom: 'Customize',
    shopThemeColor: 'Shop Theme Color',
    gridSuggest: 'Suitable for restaurants that want to highlight food images',
    listSuggest: 'Suitable for restaurants with a large number of menu items',
    gridLayout: 'Grid',
    listLayout: 'List',
    menuLayout: 'Menu Layout',
    exTermOfService: 'Ex. A fine of 20 Baht per 100 grams for unfinished food',
    showOnFirstLogin: 'Show when customer login for the first time',
    termOfService: 'Terms of Service',
    setUpQR: 'Set Up QR Order',
    setUpKiosk: 'Set Up Kiosk',
    nonCost: 'Cost-free Product',
    ratioCount: 'Quantity',
    quantityCount: 'Ratio',
    stdCostWarning: 'Cost is more than price',
    transferMenu: 'Transfer Product',
    userManual: "User Manual",
    transferOutGuideTitle: "Guide for Creating Outbound Transfer Documents",
    transferOutMethodsIntro: "There are two methods for transferring goods between branches: pending approval or immediate transfer. The steps are as follows",
    transferOutStepsTitle: "Steps for Creating Outbound Transfer Documents",
    transferOutStep1: "Click the 'Create Transfer Out' (TO) button.",
    transferOutStep2: "Select the destination branch.",
    transferOutStep3: "Set the due date for the destination branch to receive the goods.",
    transferOutStep4: "Select the goods to be transferred to the destination branch. (If the destination branch does not have the goods the source branch wants to transfer, the system will not be able to create the outbound transfer document.)",
    transferOutStep5: "Enter the quantity of goods to be transferred to the destination branch. If you want to add a note, you can enter it in the note field below.",
    transferOutStep6: "Click the 'Transfer' button after filling in the details.",
    transferOutStep7: "Select either 'Pending Approval' or 'Immediate Transfer'.",
    transferOutNoteTitle: "Notes",
    transferOutNote1: "If you choose to create a pending approval transfer document: The user can edit the quantity and notes at any time until the destination branch accepts the goods. However, you cannot delete or add items to be transferred.",
    transferOutNote2: "If you choose to create an immediate transfer document: The system will automatically transfer the goods without the destination branch having to accept the goods, and the user will not be able to edit the quantity or notes afterwards.",
    transferOutNote3: "If the destination branch does not accept the full quantity transferred by the source branch, the system will display a progress bar. The source branch can check the document to see how much the destination branch has received and can accept the unreceived goods back into the warehouse.",
    transferOutNote4: "The user can view detailed reports of sent or received goods in the Inventory > Send or Receive Goods menu to see details of transferred and received goods.",
    transferInHelpTitle: "Guide for Receiving Stock Between Branches",
    transferInHelpintro: "The steps for receiving stock between branches are as follows:",
    transferInstep1: "Select the receipt document that is in Pending status, whether yellow or blue.",
    transferInstep2: "Enter the desired quantity to receive. If the receiving side does not accept the full quantity sent by the transferring side, the system will notify the transferring side to accept the return of the unreceived stock.",
    transferInstep3: "Click approve.",
    transferInstep4: "Once the goods receipt is successfully approved, you can check the list of stock received into the warehouse in the Inventory > Stock Movement .",
    transferInnote: "Please check the inventory movements every time to ensure the accuracy of stock.",
    diffQuantity: 'Received less than transferred ',
    refNo: 'Ref. No.',
    receivedAt: 'Received At',
    overDueDate: 'Received after the due date',
    dueDate: 'Due Date',
    transferReportTXT: 'Transfer and Receipt Report',
    transferReportMenu: 'Transfer and Receipt',
    searchTransfer: 'Search Documant No.',
    allDoc: 'All',
    successDoc: 'Success',
    pendingDoc: 'Pending',
    cancelDoc: 'Cancel',
    approve: 'Approve',
    transferOutMenu: 'Transfer-Out',
    transferInMenu: 'Transfer-In',
    createTransferDoc: 'Create Transfer Out (TO)',
    transferOutNo: 'Transfer Out No. (TO)',
    transferInNo: 'Transfer In No.  (TI)',
    saveSuccess: 'Save Success',
    docSaveEditComplete: 'Document saved successfully. Please review the document again.',
    saveEdit: 'Save edit',
    saveDocInvalid: 'Document can not save. Please try again.',
    ApproveDocDes: 'Do you want to transfer immediately?',
    ProductNotEnought: 'Not enough stock',
    pendingTransfer: 'Pending Transfer',
    ApproveTransfer: 'Approve Transfer',
    quantityReceived: 'Quantity received',
    TransferDocNotFound: 'Transfer document not found.',
    transferDocumentCreationFailed: "Failed to create transfer document.",
    destinationSiteProductNotFound: "Destination site product not found.",
    invalidStatus: "Invalid Document Status.",
    siteNotFound: "Site Not Found.",
    documentAlreadyClosed: "Document Already Closed.",
    transferToSameSite: "Transfer to same site.",
    transferqtyis0: "The destination branch product has been deleted or its quantity is zero.",
    productTransferOut: 'Product Transfer Out',
    transferOutdescrip: 'Add products to the transfer out list and configure each item',
    pending: 'pending',
    transferInTxt: 'Transfer In',
    transferOutTxt: 'Transfer Out',
    inventoryAftertransfer: 'Balance After',
    onhandQtyBefore: 'Balance Before',
    transfer: 'Transfer',
    createUser: 'Creater',
    transferIn: 'Transfer In',
    transferOut: 'Transfer Out',
    nightMode: 'Night Mode',
    importWarning: 'Import product  is currently in use',
    importWarningDialog: 'Previous import is currently in progress',
    importPleaseWait: 'Please wait 5 minutes before importing new items again',
    startScreenSaverWhen: 'Start Screen Saver when inactive for',
    videoKioskScreenSaving: 'Set as video',
    imageKioskScreenSaving: 'Set as photos',
    screenSaverConfig: 'Set Screen Saver',
    selfService: 'Self Service',
    productDetail: 'Product Management',
    staffTxt: 'Staff',
    taxTxt: 'Tax',
    inventoryTxt: 'Inventory',
    salesTxt: 'Sales',
    done: 'Done',
    moreInfo: 'See more Information at',
    importProductTxt: 'Import Product',
    fail: 'Fail',
    pass: 'Pass',
    donotShow: 'Do not show this message again.',
    skuRequired: 'Attribute sku is required.',
    barcodeRequired: 'Attribute barcode is required.',
    categoryRequired: 'Attribute category is required.',
    nameRequired: 'Attribute name is required.',
    priceRequired: 'Attribute price is required.',
    costRequired: 'Attribute cost must be a number.',
    ratioRequired: 'Attribute ratio must be a number.',
    barcodedup: 'Barcode duplicated',
    priceMustBeNumber: 'Attribute price must be a number.',
    barcodeExists: 'Barcode already exists.',
    skuInvalid: 'Attribute sku is invalid.',
    barcodeInvalid: 'Attribute barcode is invalid.',
    categoryInvalid: 'Attribute category is invalid.',
    nameInvalid: 'Attribute name is invalid.',
    priceInvalid: 'Attribute price is invalid.',
    costNotAlphabet: 'Attribute cost should not contain alphabet.',
    priceNotAlphabet: 'Attribute price should not contain alphabet.',
    vatMustBeChar: "Attribute VAT must be a character.",
    serialNumberisDup: 'Serial Number is duplicated.',
    importFile: 'Import File',
    downloadExampleFile: 'Example File',
    warehouseTxt: 'Inventory Management',
    invenTransfer: 'Inventory Transfer',
    askForReceipt: 'If you need a receipt, please contact the staff',
    tableNumber: 'Table',
    paymentSuccess: 'Payment Successful',
    period: 'Period',
    yearly: 'Yearly',
    monthly: 'Monthly',
    Kiosk: 'Kiosk',
    unSelect: 'Undefined',
    optionalChoice: 'Optional',
    orderHere: 'Order Here',
    onBoardingPagePreview: 'On boarding preview',
    menuPagePreview: 'Menulists Preview',
    printReceipt: "Print Receipt",
    paymentMethod: 'Payment Method',
    receiptRenew: 'Receipt',
    payToAmount: 'Total',
    haveNotPaidYet: 'Have not paid yet',
    retryPayment: 'Retry Payment',
    QRCodeExpired: 'QR Code Exipred',
    checkStatus: 'Check Status',
    payWithIn: 'QR Code expire in',
    minutes: 'minutes',
    alertSelectPaymentMethod: 'Please select payment method',
    scanForPay: 'Scan QR Code for Payment',
    scanForPayDescription: 'Open your banking app and scan the QR code to make a payment',
    QRPayment: 'QR Payment',
    QRPromptPay: 'QR PromptPay',
    QRCredit: 'QR Credit Card',
    selectPaymentedthod: 'Select Payment Method',
    selectPaymentedthodDescription: 'Choose a payment method to renew your package',
    inputTaxForm: 'Fill in the information to issue a tax invoice.',
    exit: 'Complete',
    transactionSuccess: 'Transection Successfully',
    notRequired: 'Not Required',
    next: 'Next',
    packagePurchaseDate: 'Purchase Date',
    packageName: 'Package',
    billingPackageCycle: 'Billing Cycle',
    packageNo: 'Package No.',
    pricePackage: 'Price',
    renewPackage: 'Renew Package',
    packgeUsageHistory: 'Current Package',
    purchaseHistory: 'Purchase History',
    packageAndRenew: 'Package and Renew',
    addCoverImage: 'Cover Image',
    addLogoDespt: 'Insert a Logo or Main Product Image Without a Background Color',
    addOption: 'Add Add-on',
    editOption: 'Edit Add-on',
    fillOption: 'Please Enter Add-on Name',
    txtOption: 'Add-on Name',
    savedOption: 'Save Add on Success ',
    pleaseMakeCopyToDownload: "Please make a copy to download the file from Google Sheet Template ",
    pleaseMakeCopyToDownload2: "and import no more than 1000 items at a time to ensure accuracy and data integrity.",
    pleaseMakeCopyToDownload3: 'The import system will only import new products. Currently, it cannot modify existing product reports.',
    download: 'Download',
    requirementFileCreationWarning: "Precautions in file creation",
    requirementFillMandatoryFields: "Must fill in all ",
    must: 'required',
    everyFields: 'columns.',
    requirementSameProductDifferentSizeColor: "For the same product with different sizes or colors, use the same Product ID.",
    requirementUniqueBarcode: "The Barcode column",
    requirenotduplicate: 'must not contain duplicates.',
    requirementVAT: "VAT:",
    requirementVATWithTax: "V = Product with tax",
    requirementVATWithoutTax: "N = Product without tax",
    requirementProductType: "Product Type:",
    requirementProductTypeGeneral: "P = General product",
    requirementProductTypeBOM: "BOM = Bundle product",
    requirementProductTypeSN: "SN = Product with Serial Number",
    requirementProductTypeSV: "SV = Service product",
    requirementNumericValues: "Product price, cost, and ratio must be numbers only.",
    requirementSync: 'ฺBefore and after completing the product import, please sync the data with the POS system to ensure it is up to date.',
    importdesc: '<img src="/img/icon/warning.png" width="24px" class="mr-2">Before and after uploading the product import file, <span class="text-danger">please make sure to sync the data with the POS system first.</span>',
    importdesc2: 'In cases where there are multiple branches, please ensure that the data is synced with the POS system at every branch before and after uploading the file.',
    requirementDownloadCSV: "Download the file as CSV before importing on the website.",
    noPackage: 'No Data!',
    noPackageDescription: 'Contact Us',
    maximum: 'Maximum',
    require: 'Required',
    dialigDeleteGroup: 'Do you want to delete Add-on Group?',
    confirmDeletGroup: 'Confirm delete Add-on Group',
    dialigDeleteOption: 'Do you want to delete Add-on?',
    confirmDeletOption: 'Confirm delete Add-on',
    addOptionGroup: 'Add Add-on Group',
    editOptionGroup: 'Edit Add-on Group',
    fillOptionGroup: 'Please Enter Add-on Group Name',
    txtOptionGroup: 'Add-on Group Name',
    addSKUratio: 'Add SKU Ratio',

    productDataSaving: 'Saving Product Details',
    imageUploading: 'Uploading the image to cloud storage',
    videoUploading: 'Uploading the video to cloud storage',
    videoSizeNotTooBig: 'Video size not exceeding 20 MB',
    bigVideoAlert: 'This Video is bigger than 20 MB',
    pasteURL: 'Paste URL',
    deleteVideo: 'Delete Video',
    uploadVideo: 'Upload Video',
    activeAll: 'Active All Products',
    deactiveAll: 'Deactive All Products',
    inputURLPlaceholder: 'Enter URL',
    confirmSaveQRConfigDialog: 'Do you want to save the QR-ORDER settings?',
    confirmSaveQRConfig: 'Confirm to save the QR-ORDER settings',
    confirmSaveKioskConfigDialog: 'Do you want to save the Kiosk settings?',
    confirmSaveKioskConfig: 'Confirm to save the Kiosk settings',
    linkBannerTo: 'Link banner image to',
    externalLink: 'URL',
    addBanner: 'Banner Image',
    addBannerSuggestion: 'Recommend the image aspect ratio of 2:1 or 1280x640 pixels',
    addBannerSuggestionKiosk: 'Recommend the image aspect ratio of 3:1 or 2000x667 pixels',
    onSale: 'On Sale',
    shopGreetingTitle: 'Greeting Phrase',
    shopGreetingDescription: 'Sub Greeting Phrase',
    shopGreetingDialog: 'Ex. Shop Name or Greeting Phrase',
    previewScreen: 'Preview',
    previewDialog: 'The proportion depends on the screen size',
    viewAll: 'View All',
    seemore: 'see more',
    recommendMenu: 'Recommend',
    qrMobileConfig: 'Set Up Self Service',
    qrMenuDetailsAdjustment: 'Set Up Menu on QR Order',
    useInQr: 'Picture/Video in 500x500 px or 1:1',
    txtStore: 'Store',
    additionalImage: 'Additional product image/video <br>Recommend the image aspect ratio of 1:1 or 500x500 pixels',
    inactiveStock: 'Inactive-Stock',
    nonStock: 'non-stock',
    enterProvince: 'Enter Province',
    enterAmphoe: 'Enter District',
    enterTambon: 'Enter Sub-District',
    enterAddress: 'Enter Address',
    enterPostcode: 'Enter Post Code',
    enterFirstname: 'Enter name',
    enterLastname: 'Enter Lastname',
    enterCustomerName: 'Enter Name or Company',
    enterTaxIDNumber: 'Enter ID Number or Tax ID Number',
    enterTel: 'Enter Phone Number',
    enterBranchname: 'Enter Branch Name',
    enterEmail: 'Enter E-Mail',
    createCheckStock: 'Create Check Stock Documents',
    checkStockDoc: 'Check Stock',
    checkStockTXT: 'Check Stock Documents',
    checkStockName: 'Document Name',
    selectCheckStockDoc: 'Select Check Stock Documents',
    importcheckStockDoc: 'Import Documents',
    checkStockProduct: 'Check Stock Products',
    validateCheckStock: 'Enter Check Stock Document Name',
    optionTotal: 'Add-on Total',
    customerInfo: 'Customer Infomation',
    chooseBirthDate: 'Choose Date of Birth',
    shopname: 'Shop Name',
    branchName: 'Branch Name',
    businessType: 'Business Type',
    taxID: 'Tax ID',
    nameTaxpayer: 'Personal Name or Company Name',
    txtTaxAddress: 'Tax Invoice Address',
    vat: 'Tax',
    exclusive: 'Exclusive',
    inclusive: 'Inclusive',
    serviceCharge: 'Service Charge',
    address: 'Address',
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    tel: 'Tel',
    tax: 'Tax',
    businessType1: 'General',
    businessType2: 'Restaurant',
    businessType3: 'Hostel',
    shopopen: 'Open - Closed',
    open: 'Open',
    closed: 'Closed',
    languagetxt: 'Language',
    language: 'Language & Timezone',
    languageTH: 'Thai',
    languageEN: 'English',
    setting: 'Settings',
    shopsetting: 'Shop Settings',
    shop: 'Shop',
    timezone: 'Timezone',
    GMTminus12: '(GMT-12:00) International Date Line West',
    deliveryProviders: 'Delivery Providers',
    adddeliveryProviders: 'Add Providers',
    lastUpdate: 'Last update',
    logo: 'Logo',
    updateData: 'Update data',
    submit: 'Submit',
    cancel: 'Cancel',
    normal: 'Normal',
    loading: 'loading...',
    editing: 'Editing',
    verifying: 'Verifing...',
    confirmUpdate: 'Do you want to updated data?',
    selectedShoptype: 'Selected Business Type...',
    selectshop: 'Select Branch Name...',
    save: 'Save',
    reporttxt: 'Reports',
    txtSupplier: 'Supplier',
    general: 'General',
    signOut: 'Sign Out',
    signIn: 'Sign In',
    forgotPassword: 'Forgot Password?',
    or: 'Or',
    registration: '',
    unit: 'Unit',
    category: 'Category',
    product: 'Product',
    export: 'Export',
    exportKBank: 'Export K-Bank',
    date: 'Date',
    to: 'To',
    status: 'Status',
    vatAmount: 'Tax Amount',
    netTotalNonVAT: 'Total (non-Tax)',
    nonVatPtoduct: 'Non-VAT Product',
    receiptNumber: 'Receipt No.',
    taxInvoiceReceipt: 'Tax Invoice Receipt No.',
    grandTotal: 'Grand Total',
    netAmountVATTotal: 'Sub-Total(ex-Tax)',
    netTotalIncVAT: 'Total (incl-Tax)',
    proofOfPayment: 'Proof of payment',
    paymentType: 'Payment Type',
    paymentType0: 'Cash',
    paymentType1: 'Credit card',
    paymentType2: 'Coupon',
    paymentType3: 'Quickpay',
    paymentType4: 'Promptpay',
    paymentType5: 'Alipay',
    paymentType6: 'Deposit',
    paymentType7: 'Delivery',
    paymentType8: 'Online',
    paymentType9: 'Line Pay',
    paymentType10: 'Custom Pay',
    paymentType12: 'KBank',
    paymentType14: 'EDC',
    otherPayment: 'etc.',
    paymentAmount: 'Amount',
    paid: 'Payment value',
    change: 'Change',
    sentSuccessfully: 'Sent successfully',
    pluCode: 'Barcode',
    netAmount: 'Sub Total',
    discount: 'Discount',
    tableNo: 'Table  No.',
    avgDuration: 'Duration Avg. (min.)',
    avgGrandTotal: 'Grand Total Avg.(baht)',
    avgNumCust: 'Customer Avg.(person)',
    numCust: 'Customer Total',
    avgItemsPerBill: 'Items Avg.',
    avgNumCustPerBill: 'Customer Avg.',
    avgBillPerDay: 'Total of tables opened',
    avgDurationPerBill: 'Duration Avg.',
    itemperbill: 'Items/Bill/Table',
    avgDurationPerBill: 'Time Avg.',
    tableUsage: 'Table Usage',
    totalItems: 'Total Item',
    personperbill: 'Person/Table',
    person: 'person',
    totalTime: 'Total Time',
    tableperbill: '/Table',
    minperbill: 'hr./table',
    timeperday: 'Bill/Table/Day',
    times: 'Times',
    avgItems: 'Items Avg.(Item)',
    tableStatsReport: 'Statistical Table Usage Report',
    tableStats: 'Statistical Table Usage',
    timeOfOrder: 'Time of order',
    orderDate: 'Order Date',
    preparing: 'Preparing',
    paymentDetail: 'Payment Detail',
    orderNumber: 'Order Number',
    delivered: 'Due date',
    deliveredDate: 'Lead time',
    payment: 'Payment',
    productName: 'Product Name',
    quantity: 'Quantity',
    priceUnit: ' Price / Unit',
    baht: 'Baht',
    profit: 'Profit',
    sales: 'Sales',
    billAmount: 'Bill Total',
    tableAmount: 'Table Usage total',
    transferQty: 'Transfers Quantity',
    transferReceived: 'Transfer Received',
    inventoryBalance: 'Inventory Balance',
    destinationQty: 'Destination branch balance',
    shopQty: 'Source branch balance',
    billAmount: 'Bill total',
    tableAmount: 'Table Usage total',
    total: 'Total',
    bill: 'Bill',
    discountAmount: 'Discount Amount',
    averageBill: 'Average / bill',
    voidbillTotal: 'Voidbill Total',
    salesByDaily: 'Sales By Daily',
    salesByMonth: 'Sales By Month',
    bestSellers: 'Best sellers',
    bestSellerstype: 'Best sellers category',
    salesBydate: 'Sales by date',
    number: 'No.',
    categoryName: 'Category Name',
    newOrder: 'New Order',
    reject: 'Reject',
    inProgressTime: 'In Progress time',
    rejectTime: 'Reject Time',
    cancelTime: 'Cancel Time',
    cause: 'Cause',
    preparingToShip: 'Preparing',
    totalAmount: 'Total',
    order: 'Order',
    deposit: 'Deposit',
    billSave: 'Save',
    ReceivingOrder: 'Open table/Receiving order',
    orderNo: 'Order No.',
    remark: 'Remark',
    receiptStatus: 'Receipt Status',
    back: 'Back',
    customerCate: 'Customer Category',
    detail: 'Detail',
    countSales: 'Quantity',
    onhandQty: 'Balance',
    stdCost: 'Cost',
    employees: 'Employees',
    employeesname: 'Employees Name',
    salesTotal: 'Sales Total',
    totalBill: 'All bill count',
    billActive: 'Selling bills count',
    billVoice: 'Cancel bills count',
    billDeposit: 'Deposit bills count',
    discountItem: 'Total item discount',
    billDiscountAmount: 'Total bill discount',
    billDiscount: 'Billing discount count',
    payTotal: 'Payment Total',
    deliveryProvider: 'Delivery Provider',
    deliveryDetail: 'Delivery Detail',
    customDetail: 'Custom payment Detail',
    customPaymentName: 'Custom Payment Name',
    firstInvoiceNO: 'First bill no.',
    lastInvoiceNO: 'Last bill no.',
    roundNo: 'Round No.',
    closeAt: 'Close At',
    closeBy: 'Close By',
    totalCashSales: 'Total Cash Sales',
    initialChange: 'Initial Change',
    totalCashIn: 'Total cash in',
    totalCashOut: 'Total cash out',
    actualInDrawer: 'Actual in drawer',
    expectedInDrawer: 'Expected in drawer',
    difference: 'Difference',
    voidBillDetail: 'Void bill detail',
    cancelBy: 'Cancel By.',
    cancelDate: 'Cancel Date',
    searchByEmployees: 'Search by employees',
    searchEmployees: 'Search by employees',
    search: 'Search',
    timeIn: 'Clock in',
    timeOut: 'Clock out',
    time: 'Time',
    hours: 'Hours',
    seconds: 'Seconds',
    netTotal: 'Total',
    reason: 'Reason',
    searchByProduct: 'Search by product',
    sortBy: 'Sort By',
    last: 'Newest',
    price: 'Product price',
    sortByNumMin: 'Min price',
    sortByNumMax: 'Max price',
    sortBySalesMin: 'Min Sales',
    sortBySalesMax: 'Max Sales',
    sortByBillAmountMin: 'Min Bill Amount',
    sortByBillAmountMax: 'Max Bill Amount',
    documentNo: 'Document No.',
    transactionType: 'Transaction Type',
    updatedBy: 'Updated By',
    transactionType1: 'Sell',
    transactionType2: 'Refund',
    transactionType3: 'Stock in',
    transactionType4: 'Stock out',
    transactionType5: 'Adjust stock in',
    transactionType6: 'Adjust stock out',
    transactionType7: 'Redeem point',
    transactionType8: 'Delete stock in',
    transactionType9: 'Delete stock out',
    transactionType10: 'Edit stock in',
    transactionType11: 'Edit stock out',
    transactionType12: 'Begining Balance',
    transactionType13: 'BOM',
    transactionType14: 'Add-on',
    transactionType15: 'Transfer Out',
    transactionType16: 'Transfer In',
    transactionType17: 'Cancel Transfer',
    transactionType18: 'Reverse from reference transaction ',
    transactionType19: 'Delete item from Transfer Document',
    transactionOther: 'Other',
    billNo: 'Bill No.',
    docTotal: 'Total',
    customer: 'Supplier',
    username: 'Username',
    dateOfBill: 'Date Of Bill',
    selectDate: 'Select Date',
    dialogSelectCategory: 'Please select category for search',
    dialogUpdateDocumentSuccess: 'Update document successfully',
    dialogfound: 'The information was entered incorrectly.',
    isVatIncluded: 'Is VAT Included',
    vatIccludes: 'VAT',
    confirmStock: 'Confirm stock',
    confirm: 'Confirm',
    deleteDoc: 'Delete Document',
    confirmUpdateDocModal: 'Would you like to update the check stock document?',
    confirmSaveDoc: 'Confirm saving documents',
    confirmDeleteDoc: 'Confirm delete documents',
    confirmDeleteModal: 'Would you like to delete the check stock document?',
    confirmDeleteTransferModal: 'Would you like to delete the transfer document?',
    dialogSaveDocumentSuccess: 'Save document successfully',
    createStockIn: 'Create Stock-In',
    confirmSaveDocModal: 'Are you sure you want to save the stock-in document?',
    confirmCheckStockMsg: 'Are you sure you want to save the check stock document?',
    confirmAdjustStockMsg: 'Are you sure you want to save adjust stock document?',
    selectShopPlease: 'Select shop please',
    selectProductPlease: 'Select product please',
    duplicateProduct: 'You have selected this item.',
    productInvilid:
        'The item you selected is invalid. Please contact the system administrator.',
    confirmStockOut: 'Confirm stock out',
    dialogSaveDocumentOutSuccess: 'Save document successfully',
    confirmSaveDocStockOutModal: 'Would you like to save stock out document?',
    adjustDocType: 'AdjustDoc Type',
    adjustDocType1: 'Improve',
    adjustDocType2: 'Reduce',
    beforeAdjust: 'Before Adjust stock',
    afterAdjust: 'After Adjust stock',
    adjust: 'Adjust stock',
    createDoucument: 'Create Document',
    transferType: 'Transfers Type',
    transferType1: 'Transfers in',
    transferType2: 'Transfers out',
    new: 'New',
    finish: 'Finish',
    refDocument: 'Document Reference',
    statusDoc: 'Document Status',
    dialogSaveTransferDocumentSuccess: 'Create transfers doucument successfully',
    destinationShop: 'Destination shop',
    destinationShopBranch: 'Destination Branch',
    sourceBranch: 'Source Branch',
    sourceShop: 'Source Shop',
    sourceAddress: 'Source address',
    destinationAddress: 'Destination address',
    saveTransfer: 'Save transfers stock',
    confirmSaveTransfer: 'Would you like to save the transfers out stock?',
    savingDoc: 'Saving Document',
    selectdestinationPlease: 'Please Select Desination Branch',
    savinserviceChargegDoc: 'Saving document',
    selectdestinationPlease: 'Please select destination branch ',
    validatedestinationproduct: "In destination branch don't have product",
    validateProduct: 'Please select product',
    created_by: 'By.',
    receive: 'Receive',
    updateDoc: 'Update document',
    cancelDoc: 'Cancel document',
    confirmCancelDoc: 'Do you want to cancel this document?',
    cancelingDoc: 'Canceling documents',
    updatingDoc: 'Updatting documents',
    updateDocFail: "Can't updated documents",
    cancelDocSuccess: 'Cancel documents',
    cancelDocFail: "Can't cancel documents",
    refDocumentTransfer: 'Ref. Document',
    comfrimTransferIn: 'Confirm stansfer stock in',
    dilogcomfrimTransferIn: 'Do you want to transfers stock in document?',
    transferInDetail: 'Transfers stock detail',
    totalNet: 'Net total',
    confirmAdjust: 'Confirm adjust stock',
    createStockOut: 'Create stock out',
    favorite: 'Favorite',
    isOnScreen: 'Show on POS',
    negotiatePrice: 'Negotiate Price',
    productTypeP: 'General Product',
    productTypeBOM: 'Bill of Meterial',
    productTypeSN: 'Serial Product',
    productTypeSV: 'Service (non-stock)',
    productPLU: 'Other packaging sizes',
    productBOM: 'Meterial',
    productSN: 'Serial Number',
    morePrice: 'Other Prices',
    cost: 'Cost',
    quantityPrice: 'Quantity',
    exceedUc: 'Price',
    changeImg: 'Image change',
    addProduct: 'Add Product',
    selectImg: 'Select image',
    deleteImg: 'Delete image',
    selectImgFromPC: 'Select image from PC',
    confirmSaveImgDialog: 'Do you want to save the product?',
    confirmSaveImg: 'Confirm to save product.',
    clearTableDataDialog: 'Do you want to clear the data in the table?',
    clearTableData: 'Confirm clear the data in the table.',
    addPrice: 'Add Price',
    editPrice: 'Edit Price',
    dialigDeleteProduct: 'Do you want to delete the product?',
    confirmDeleteProduct: 'Confirm to delete this product.',
    productType: 'Product Type',
    countProduct: 'Count of product',
    addUnit: 'Add Unit',
    editUnit: 'Edit Unit',
    active: 'Active',
    inactive: 'In Active',
    confirmSaveUnitDialog: 'Do you want to save unit?',
    confirmSaveUnit: 'Confirm to save this unit.',
    dialigDeleteUnit: 'Do you want to delete the unit?',
    confirmDeletUnit: 'Confirm to delete this unit.',
    confirmSaveCategoryDialog: 'Do you want to save category?',
    confirmSaveCategory: 'Confirm to save this category.',
    dialigDeleteCategory: 'Do you want to delete the category?',
    confirmDeleteCategory: 'Confirm to delete category.',
    savedCategory: 'Product category has been saved',
    addCategory: 'Add Category',
    editCategory: 'Edit Category',
    bgColor: 'Background color',
    selectbgColor: 'Select background color',
    validateCategoryname: 'Please enter a category name.',
    pressEnterSelect: 'Press Enter to Select',
    pressEnterRemove: 'Press Enter to Remove',
    selected: 'Selected',
    selectCategoryIcon: 'Select category icon',
    customCategoryIcon: 'Category icon',
    icon: 'Icon',
    clear: 'Clear',
    sequence: 'Sequence',
    enterProduct: 'Please enter product name.',
    enterPrice: 'Please enter product price.',
    selectCategory: 'Please enter a product category.',
    selectProductType: 'Please enter a product type.',
    selectUnit: 'Please enter a product unit.',
    confirmSaveProduct: 'Do you want to save this product?',
    confirmSaveChangeModal: 'If you want to modify the material,',
    confirmSaveChangeModal2: 'you will not be able to cancel the previous sales bill ',
    confirmSaveChangeModal3: 'as it may affect the stock inventory',
    confirmSaveChangeModal4: 'Do you want to proceed with modifying the material??',
    confirmDeleteProduct: 'Do you want to delete this product?',
    confirmSaveProductPrice: 'Do you want to save other price?',
    validatepackQty: 'Please enter a Price/Unit.',
    validatepackUc: 'Please enter a price.',
    validatePRU: 'Duplicate data, Please enter again.',
    createProductSuccess: 'Created product successfully',
    updateProductSuccess: 'Updated product successfully',
    addSerial: 'Add Serial',
    add: 'Add',
    createSerial: 'Generate Serial number',
    available: 'Ready to sales',
    soldOut: 'Sold Out',
    deleteSuccess: 'Deleted successfully',
    enterSerial: 'Please enter Serial Number',
    confirmSaveSerial: 'Do you want to save this Serial Number?',
    confirmDelete: 'Do you want to delete this Serial Number?',
    addProductPLU: 'Add packaging sizes',
    img: 'Image',
    netAmountVATTotal: 'Sub-total (ex-Tax)',
    SKURatio: 'Ratio',
    editProductPLU: 'Edit packaging sizes',
    enterSKURatio: 'Please enter Ratio',
    comfrimDeletePLU: 'Do you want to delete this packaging sizes?',
    useStepPrice: 'Use step price',
    addBOM: 'Add Material',
    confirmSaveBOM: 'Do you want to save Material?',
    user: 'User',
    chooseTimezone: 'Choose Timezone',
    userDetail: 'User Detail',
    planDetail: 'Plan Detil',
    currentPlan: 'Current Plan',
    expireDate: 'Expire Date',
    startDate: 'Start Date',
    dialogSignOut: 'Do you want to sign out?',
    confirmSignOut: 'Confirm for sign out',
    countMember: 'All members', //
    addMenber: 'Add member',
    searchMember: 'Search by name email and Tel',
    dateOfMember: 'Date of member',
    recentVisit: 'Recent visit',
    memberName: 'Member name',
    email: 'E-mail',
    paymentTotal: 'Payment total',
    balancePoints: 'Balance Points',
    point: 'Point',
    pointtxt: 'Point',
    postalCode: 'Postal code',
    saveing: 'Saving . . .',
    edit: 'Edit',
    editBOM: 'Edit',
    editMember: 'Edit member',
    deleteing: 'Deleting . . .',
    dialogDeleteMember: 'Do you want to delete member ',
    confirmDeleteMember: 'Confirm for delete',
    valtdateMember: 'Please fill information ',
    createMemberSuccess: 'Add member successfully',
    updateMemberSuccess: 'Edit member successfully',
    deleteMemberSuccess: 'Delete member successfully',
    onhandQtyMin: 'Onhand Min',
    onhandQtyMax: 'Onhand Max',
    onhandQtySort: 'OnhandQty',
    showProductAll: 'All Product type',
    updateAt: 'Last update',
    sortName: 'Name',

    txtOptionCategory: 'Add-on Category',
    txtOption: 'Add-on',
    salesQty: 'Sales Quantity',
    itemQty: 'Items',
    stockUnit: 'Cost/Unit',
    deleteCategory: 'Deleted category',
    selectd: 'Select',
    searchProduct: 'Search Product',
    reset: 'Reset',
    selectdProduct: 'Select product',
    information: 'Information',
    adjustStockList: 'Adjust stock product',
    adjustDetail: 'Add product to adjust stock list add setting product',
    checkStockDetail: 'Add product to check stock list add setting product',
    transferoutList: 'Transfer stock product',
    transferoutList: 'Transfers stock product',
    addProductTransfer:
        'Add product to transfers stock list add setting product',
    transferDetail: 'Product to Stock out list add setting product',
    transferinList: 'Product to Stock in list add setting product',
    stockOutList: 'Stock out product',
    addstockOutList: 'Add product to Stock out list add setting product',
    stockinList: 'Stock in product',
    addProductStockin: 'Add product to Stock in list add setting product',
    checkCount: 'Check stock',
    createAdjustStock: 'Create adjust stock',
    memberDetail: 'Member infomation',
    package: 'Package detail',
    startPackate: 'Start Package',
    endPackate: 'End Package',
    name: 'Name',
    productImg: 'Product image',
    fistname: 'Fristname',
    lastname: 'Lastname',
    saveMember: 'Save infomation',
    confirmSaveMember: 'Do yo want save member ',
    memberSource: 'Channel',
    yesOrNo: 'yes or no',
    totaValue: 'Total',
    deleteMember: 'Delete member',
    frequently: 'Frequently product',
    recentPurchase: 'Last purchase',
    paymentDescription: 'Payment description',
    validateInfomation: 'Please input information',
    validateName: 'Personal Name or Company Name is incorrect',
    validateTel: 'Tel is incorrect',
    validateTaxID: 'TaxID is incorrect',
    validateAddress: 'Address is incorrect',
    validateEmail: 'Email is incorrect',
    saveSuccess: 'Saved successfully',
    saveSuccessDialog: "The import of products has been successfully saved. ",
    saveSuccessDialog2: 'Please verify the accuracy of the products.',
    alertImportProductDialog: "Products that fail the inspection will not be saved.",
    txtcustomer: 'Customer',
    customerName: 'Customer name',
    customerCount: 'Count of customer',
    addCustomer: 'Add customer',
    permissionManage: 'User Permission',
    mainMenu: 'Main Menu',
    page: 'Page',
    show: 'Show',
    delete: 'Delete',
    administratorTools: 'Administrator tools',
    deviceName: 'Device Name', //ชื่ออุปกรณ์
    lastSyncProduct: 'Last Sync', //เวลาใช้งานล่าสุด
    version: 'Version',
    sex: 'Sex',
    dayMYBirth: ' Date of Birth',
    male: 'Male',
    female: 'Female',
    notSpectified: 'Not Spectified',
    verify: 'Verify',
    confirmUpdateUserInfo: 'Do you want to save this infomation?',
    posstatus: 'Status', //สถานะการเชื่อมต่อกับระบบ
    posNumber: 'POS Number',
    titleSilomconnect: 'Never miss any sales with services from',
    updateSilom: 'Update daily sales via LINE for free',
    addfriend: 'Add friend',
    enterToReciveService: 'Press to receive a code for using the service.',
    verifyCodeToUsed:
        'To start using the system. You can press to receive a code to verify your identity.',
    verifyfivemin: 'The verification code can be used within 5 minutes.',
    yourVertify: 'Your verify code is', //รหัสของคุณคือ
    pressCode: 'Press receive code', //กดรับรหัส
    exCode: 'The code expires within', //รหัสหมดอายุภายใน
    sessionEx: 'Login session expired. Please login again.', // เซสชันการเข้าสู่ระบบหมดอายุ กรุณาล็อกอินใหม่อีกครั้ง
    refresh: 'Refresh',
    selectedFile: 'Selected File',
    fileSize: 'File size no more than 2 MB.',
    isBeingToUsed: 'Is being used',
    notInUse: 'Not in use',
    delete: 'Delete',
    confirmUpdateInformation: 'Confirm to update information',
    memberCount: 'Count',
    orderMachine: 'Order Device',
    textMember: 'Member',
    manageShop: 'Shop information management',
    lansetting: 'Shop setting language and timezone',
    timezoneSetiing: 'Setting time zone for shop',
    deliveryProviderSetting: 'Delivery Providers for shop ',
    toolsSetting: 'Manage POS machines for shop',
    checkStockPLU: 'Product balace < 0',
    checkStockPLUMsg: 'On-hand quantity is insufficient. Please recheck product stock.',
    searching: 'Searching',
    allProduct: 'All Product',
    foundProductOnhand: 'Found product have stock onhand < 0 ',
    confirmAdjustBefor: 'Do you want to adjust by system automatic?',
    requireAdjust: 'Yes',
    imrequireAdjust: 'No',
    islimitItemTodoc: 'You can select up to 200 items / 1 document.',
    barcodePrint: 'Barcode Print',
    printEx: 'Print & Example',
    print: 'Print',
    downloadAndExport: 'Download and print',
    selectFormat: 'Select paper format',
    peperFormat: 'Paper format',
    optional: 'Add-on',
    standardA427: 'Standard A4 : 27 items / page',
    standardA450: 'Standard A4 : 52 items / page',
    standardA460: 'Standard A4 : 60 items / page',
    standardA556: 'Standard A4 : 56 items / page (sticker size:A5)',
    standardA550: 'Paper A5 : 52 items / page',
    standardA560: 'Paper A5 : 60 items / page',
    countBarcode: 'Count barcode',
    barcode: 'Barcode',
    setFontsize: 'Font setting',
    accessPermissionDes: 'Assign access for users.',
    deleteUser: 'Confirm to delete user',
    confirmDeleteUser: 'Would you like to delete user ?',
    passAtless: 'Password should be at least 6 characters.',
    inValidPass: 'Incorrect password',
    createBy: 'Created By',
    adjustAutoDes:
        "If you don't want the system to update automatically May cause the total balance of the product to be wrong.",
    addUser: 'Add user',
    isMenu: 'show customer menu',
    menuSmart: 'Set up product menu',
    smartSettingTitle: 'Menu Setting',
    onoffMenu: 'Enable/Disable Menu for Kiosk and QR-Order',
    smartSetting: 'Setting menu for Silom CRM ',
    confirmEnable: 'Do you want to enable menu ?',
    confirmDisEnable: 'Do you want to disable menu ?',
    confirmSelfOrderEnable: 'Do you want to enable self-order menu ?',
    confirmSelfOrderDisable: 'Do you want to disable self-order menu ?',
    enable: 'Enable',
    freeTrial: 'Free Trial',
    disable: 'Disable',
    usabilityStatus: 'Enabled',
    manageSmartOnProduct:
        'You caadd/edit" the list of products you want to show on menu for customer at the page.',
    manageSmartOnCategory:
        'You can "add/edit" the list of category you want to show on menu for customer at the page.',
    turnonSmart: 'and enable menu',
    manageShopDes: 'You can refer to the user manual in -',
    desSmartMenu:
        'Set up product menu for displaying the E-menu  on Line for easier customer access and can order products by themself.',
    link: 'link',
    createTranferOut: 'Create transfers out document',
    tranferOut: 'Transfers out document',
    roundingValue: 'Rounding Adj.',
    limitItems: 'Can select product to limit 200 items',
    IncomeCategory: 'Income ',
    ExpenseCategory: 'Expense ',
    saveBy: 'Save by',
    saveDate: 'Save date',
    drawerCate: 'Categories',
    openingHours: 'Opening hours',
    shopLocation: 'Shop location',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sun: 'Sun',
    mon: 'Mon',
    tues: 'Tue',
    wed: 'Wed',
    thur: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    lat: 'Latitude',
    lng: 'Longitude',
    shopCoverSetting: 'Shop cover setting',
    shopCoverSettingDes: 'Setting cover shop for displays on screen customer',
    locationSettingDes: 'Location setting ',
    updatelocation: 'Update location', //Update location
    searchLocation: 'Search Location ', //Search Location
    imaginaryLine: 'Imaginary Line', //Imaginary Line
    openingHoursDes: 'Setting opening time for shop',
    paymentDescriptionConfig: 'Select Payment Method',
    creditCard: 'Credit Card',
    promptPay: 'Promptpay',
    coupon: 'Coupon',
    quickpay: ' Krungsri Quick Pay',
    alipay: 'Alipay',
    linepay: 'Rabbit LINE Pay',
    thaidotcom: 'Thai Dot Com Payment',
    mounth: 'mounth',
    dayMY: 'Date',
    item: 'Items',
    byStockIn: 'Stock-in',
    stockOutOther: 'Other',
    drawerSales: 'Sales',
    sumIncomeExpenses: 'Summary of income-expenses for ', //Summary of income-expenses for the month
    incomeOther: 'Income Other',
    loss: 'Loss',
    grossProfit: 'Gross Profit',
    newUser: 'New user',
    userType: 'User Type',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    ParticipatingProducts: 'Select products',
    descriptionFreeTrialLine4:
        'Please allow 5 business days for activation. Drop your contact number and email for verification.',
    descriptionFreeTrialLine3:
        'To use QR Order, Smart+ subscription is required, with a QR ORDER subscription costing 1,800 THB. ',
    descriptionFreeTrialLine2:
        'Smart+ subscribers can trial it for 3 months, while Starter+ package subscribers can try it for 1 month.',
    descriptionFreeTrialLine1:
        'SILOM POS now offers current annual subscribers the chance to try QR ORDER at no extra cost or commitment.',
    addLine: 'more information add line Silom Official',
    descriptionSilomOrder: 'Select products for mobile-qr.silompos.app',
    cashierManagement: 'Cashier Management',
    cashierManageButton: 'Manage',
    cashierUsernamePassword: 'Create Username/Password',
    cashierCRUDDescription: 'For login mobile-qr.silompos.app',
    password: 'Password',
    placeholderPassword: '6 characters minimum',
    resetPassword: 'Reset password',
    oldPassword: 'Old password',
    oldPasswordPlaceholder: 'Old password',
    newPassword: 'New password',
    purchaseDetail: 'Purchase Details',
    uploadImage: 'Upload Image',
    uploadFile: 'Upload File',
    downloadFileExample: 'Download Example File',
    totalOfImportProduct: 'Total of Import Products',
    keyword: 'keyword',
    firstname: 'First Name',
    lastname: 'Last Name',
    province: 'Province',
    district: 'District',
    subdistrict: 'Sub-District',
    addressNumber: 'Address',
    setAsDefaultAddress: 'Set as Default address',
    setAsTaxAddress: 'Set as Tax address',
    setAsShippingAddress: 'Set as Shipping address',
    contact: 'Contact',
    setAsDefault: 'Set as default',
    addressLists: 'Address lists',
    default: 'Default',
    taxAddress: 'Tax invoice',
    shippingAddress: 'Shipping',
    addAddress: 'Add',
    createdAt: 'Created',
    updatedAt: 'Updated',
    by: 'by',
    unlock: 'Unlock',
    readyForSale: 'ready for sale',
    sold: 'sold',
    delete: 'Delete',
    items: 'Items',
    itemDiscount: 'Item discount',
    failedToSave: 'Failed to save!',
    dateOfBirth: 'Date of birth',
    menu: 'Menu',
    dashboardMenu: 'Dashboard',
    transactionMenu: 'Transaction',
    currentBillMenu: 'Current Bill',
    reportMenu: 'Report',
    orderDeviceMenu: 'Order Device',
    memberMenu: 'Member',
    inventoryMenu: 'Inventory',
    productMenu: 'Product',
    selfOrderMenu: 'Sales Channels',
    settingMenu: 'Setting',
    salesReportByDate: 'Sales by Date',
    salesReportByBill: 'Sales by Bill Detail',
    salesQuantitySummaryByProductReport: 'Sales by Product',
    quantitySummaryByPLUReport: 'Sales by Product (PLU)',
    nonSellingProduct: 'Non Selling Products',
    salesSummaryByCashierReport: 'Sales by Cashiers',
    salesSummaryByCategoryReport: 'Sales by Category',
    paymentReport: 'Payment',
    salesTaxReport: 'Sales Tax',
    drawerReport: 'Drawer',
    voidBillReport: 'Void bill',
    timesheetReport: 'Timesheet',
    stockInByListReport: 'Stock In by Product',
    stockOutByListReport: 'Stock Out by Product',
    nonAdjustStockProductReport: 'Unadjusted Stock',
    exportProductReport: 'Export Products',
    optionalReport: 'Sales by Add-on',
    newPaymentReport: 'Tax Invoice',
    cashInOutReport: 'Cash In-Out',
    salesChannelsReport: 'Sales by Channels',
    txtSalesChannels: 'Sales Channels',
    tableSummaryReport: 'Table Usage Summary Report',
    taxFlowAccReport: 'Tax Summary By Flow Account Report',
    cashIn: 'Cash In',
    cashOut: 'Cash Out',
    beforeGP: 'Bafore GP',
    afterGP: 'After GP',
    billingCycle: 'Billing Cycle',
    salesSummaryByOrderReport: 'By Order Device',
    salesSummaryProductByOrderReport: 'By Product',
    voidBillByOrderReport: 'Void bill',
    inventoryReport: 'Inventory Summary',
    inventoryReportByPLU: 'PLU Inventory Report',
    inventoryReportBySerial: 'Serial No. Inventory Report',
    inventoryReportByLessStock: 'Low Stock Report',
    inventoryReportByOutOfStock: 'Out of Stock Report',
    stockMovement: 'Stock Movements',
    stockInDocument: 'Stock-In',
    stockOutDocument: 'Stock-Out',
    adjustStockDocument: 'Adjust Stock',
    selfOrder: 'Mobile QR-ORDER',
    selfOrdertxt: 'Mobile QR-ORDER',
    cashier: 'Cashier',
    permission: 'User Permission',
    menuSetting: 'E-Menu Setting (CRM)',
    settingKitchenOpenTimesHeader: "Set Kitchen Opening-Closing Times",
    settingKitchenOpenTimesDespt: "Set the kitchen opening-closing times for the self-ordering system. Customers can only place orders during the kitchen's operating hours.",
    settingKitchenOpenTimes: 'Kitchen Opening Hours Setting',
    applyToAllDay: 'Apply to All Day',
    openTimes: "Opening Time",
    closeTimes: "Closing Time",
    closeOn: "Closed",
    today: 'Today',
    days: 'Days',
    yesterday: 'Yesterday',
    last7days: 'Last 7 Days',
    last30days: 'Last 30 Days',
    thismonth: 'This Month',
    lastmonth: 'Last Month',
    management: {
        txtManagement: 'Management',
        user: 'Main account',
        shopBranch: 'Shop and Branch',
        productManagement: 'Product',
        unitManagement: 'Unit',
        categorytManagement: 'Category',
        inventory: 'Inventory',
        stockIn: 'Stock-In',
        stockOut: 'Stock-Out',
        adjustStock: 'Adjust Stock',
        checkStock: 'Check stock',
        stockMovement: 'Stock Movement',
        transferStock: 'Transfers Stock',
        reportByPLU: 'Inventory Report by PLU',
        reportBySKU: 'Inventory Report',
        txtreportBySKU: 'Inventory Report',
        txtreportByPLU: 'Inventory Report by PLU',
        stockInDoc: 'Stock-In Documents',
        stockOutDoc: 'Stock-Out Documents',
        adjustStockDoc: 'Adjust Stock Documents',
        transferStockDoc: 'Transfers Out Documents (TO)',
        transferStockInDoc: 'Transfers In Documents (TI)',
        transferStockOutDoc: 'Transfers Out Documents',
        txtreportByLessStock: 'Inventory Report By Low Stock',
        txtreportByOutOfStock: 'Inventory Report By Out Of Stock',
        productDeliveryManagement: 'Product Delivery Management'
    },
    report: {
        dashboard: 'Dashboard',
        transaction: 'Transactions',
        currentbill: 'Current Bill',
        daily: 'By Date',
        txtdaily: 'Sales report by Date',
        dailyDes: 'Sales report by date detail',
        sellbyProduct: 'By Bill Detail',
        txtsellbyProduct: 'Sales report by Bill Detail',
        sku: 'By Product',
        txtsku: 'Sales report by Product',
        plu: 'By Product (PLU)',
        textPlu: 'Sales report by Product (PLU)',
        paymentreport: 'Payment Report',
        txtpaymentreport: 'Payment Report',
        timesheet: 'Timesheet',
        txtTimesheet: 'Timesheet report',
        unsell: 'Non Selling Products',
        txtUnsell: 'Non Selling Products',
        byCategory: 'By Category',
        txtbyCategory: 'Sales report by Category',
        byCashier: 'By Cashier',
        txtbyCashier: 'Sales report by Cashiers',
        salesTax: 'Sales Tax report',
        txtSalesTax: 'Sales Tax report',
        voidbill: 'Void Bill',
        txtvoidbill: 'Void bill report',
        closedSale: 'Closed Sale',
        txtCloseSale: 'Drawer report ',
        salesByOrder: 'Sales summary',
        productByOrder: 'By Product ',
        unsalesByOrder: 'Void Bill ',
        txtDeliveryRoport: 'Payment by delivery report',
        txtCustomReport: 'Payment by custom report',
        txtSalesByOrder: 'Sales product by Order Device',
        txtDailyOrder: 'Sales by Order Device report',
        txtViodOrder: 'Void bill by Order Device',
        nonadjust: 'Non adjust stock product',
        txtnonadjust: 'Non adjust stock product report',
        stockInReport: 'Stock in by Product report',
        stockOutReport: 'Stock out by Product report',
        stockIn: 'Stock in by Product',
        stockOut: 'Stock Out by Product',
        exportProduct: 'Export Products',
        txtExportProduct: 'Export product report',
        cashDrawer: 'Drawer',
        txtCashDrawerr: 'Cash management report',
        cashRevenue: 'Cash Revenue',
        txtOptionalsales: 'Sales By Add-on Report',
        txtTaxSummary: 'Tax Invoice Report',
        txtCashInOut: 'Cash In-Out Report',
        txtSalesChannelsReport: 'Sales By Channels Report'
    },
    drawer: {
        cashDrawer: 'Drawer',
        txtCashDrawerr: 'Cash management report',
        cashRevenue: 'Summary of income-expenses',
        txtcashRevenue: 'Summary of income-expenses report',
    },
    member: {
        txtmember: 'Member',
        register: 'Shop register (LINE OA)',
        loyaltyPoint: 'Loyalty Point',
        storeCredit: 'Store Credit',
        deal: 'Deal',
        giftVoucher: 'Gift Voucher',
        referral: 'Invite',
        crm: 'Silom CRM',
    },
    unableStockDescription: 'Unable to edit product due to stock update after document creation.',
    noItem: 'No item',
    enterProductName: 'Enter product name',
    ByCategory: 'By Category',
    ByCategoryDetail: 'By Category detail',
    totalStockIn: 'Total',
    totalStockOut: 'Total',
    totalDiscount: 'Total Discount',
    stockInQty: 'Quantity',
    stockOutQty: 'Quantity',
    documentQty: 'Document Qty.',
    beforeDiscount: 'Before Discount',
    gmtLinewest: '(GMT-12:00) International Date Line West',
    gmtMidway: '(GMT-11:00) Midway Island, Samoa',
    gmtHonolulu: '(GMT-10:00) Hawaii',
    gmtAlaska: '(GMT-09:00) Alaska',
    gmtLos_Angeles: '(GMT-08:00) Pacific Time (US & Canada)',
    gmtTijuana: '(GMT-08:00) Tijuana, Baja California',
    gmtArizona: '(GMT-07:00) Arizona',
    gmtChihuahua: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
    gmtMoutain: '(GMT-07:00) Mountain Time (US & Canada)',
    gmtManagua: '(GMT-06:00) Central America',
    gmtCentral: '(GMT-06:00) Central Time (US & Canada)',
    gmtMexico: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    gmtSaskatchewan: '(GMT-06:00) Saskatchewan',
    gmtBogota: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
    gmtEastern: '(GMT-05:00) Eastern Time (US & Canada)',
    gmtIndiana: '(GMT-05:00) Indiana (East)',
    gmtAtlantic: '(GMT-04:00) Atlantic Time (Canada)',
    gmtCaracas: '(GMT-04:00) Caracas, La Paz',
    gmtManaus: '(GMT-04:00) Manaus',
    gmtSantiago: '(GMT-04:00) Santiago',
    gmtNewfoundland: '(GMT-03:30) Newfoundland',
    gmtSao_Paulo: '(GMT-03:00) Brasilia',
    gmtBuenos_Aires: '(GMT-03:00) Buenos Aires, Georgetown',
    gmtGodthab: '(GMT-03:00) Greenland',
    gmtMontevideo: '(GMT-03:00) Montevideo',
    gmtNoronha: '(GMT-02:00) Mid-Atlantic',
    gmtCape_Verde: '(GMT-01:00) Cape Verde Is.',
    gmtAzores: '(GMT-01:00) Azores',
    gmtCasablanca: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
    gmtGreenwich: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    gmtAmsterdam: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    gmtBelgrade: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    gmtBrussels: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    gmtSarajevo: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    gmtLagos: '(GMT+01:00) West Central Africa',
    gmtAmman: '(GMT+02:00) Amman',
    gmtAthens: '(GMT+02:00) Athens, Bucharest, Istanbul',
    gmtBeirut: '(GMT+02:00) Beirut',
    gmtCairo: '(GMT+02:00) Cairo',
    gmtHarare: '(GMT+02:00) Harare, Pretoria',
    gmtHelsinki: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    gmtJerusalem: '(GMT+02:00) Jerusalem',
    gmtMinsk: '(GMT+02:00) Minsk',
    gmtWindhoek: '(GMT+02:00) Windhoek',
    gmtKuwait: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
    gmtTbilisi: '(GMT+03:00) Tbilisi',
    gmtTehran: '(GMT+03:30) Tehran',
    gmtMuscat: '(GMT+04:00) Abu Dhabi, Muscat',
    gmtBaku: '(GMT+04:00) Baku',
    gmtYerevan: '(GMT+04:00) Yerevan',
    gmtKabul: '(GMT+04:30) Kabul',
    gmtYekaterinburg: '(GMT+05:00) Yekaterinburg',
    gmtKarachi: '(GMT+05:00) Islamabad, Karachi, Tashkent',
    gmtCalcutta: '(GMT+05:30) Sri Jayawardenapura',
    gmtChennai: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    gmtKatmandu: '(GMT+05:45) Kathmandu',
    gmtAlmaty: '(GMT+06:00) Almaty, Novosibirsk',
    gmtDhaka: '(GMT+06:00) Astana, Dhaka',
    gmtRangoon: '(GMT+06:30) Yangon (Rangoon)',
    gmtBangkok: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    gmtHongkong: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    gmtKualaLumpur: '(GMT+08:00) Kuala Lumpur, Singapore',
    gmtIrkutsk: '(GMT+08:00) Irkutsk, Ulaan Bataar',
    gmtPerth: '(GMT+08:00) Perth',
    gmtTokyo: '(GMT+09:00) Osaka, Sapporo, Tokyo',
    gmtSeoul: '(GMT+09:00) Seoul',
    gmtYakutsk: '(GMT+09:00) Yakutsk',
    gmtAdelaide: '(GMT+09:30) Adelaide',
    gmtDarwin: '(GMT+09:30) Darwin',
    gmtBrisbane: '(GMT+10:00) Brisbane',
    gmtCanberra: '(GMT+10:00) Canberra, Melbourne, Sydney',
    gmtHobart: '(GMT+10:00) Hobart',
    gmtGuam: '(GMT+10:00) Guam, Port Moresby',
    gmtVladivostok: '(GMT+10:00) Vladivostok',
    gmtMagadan: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    gmtAuckland: '(GMT+12:00) Auckland, Wellington',
    gmtFiji: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
    gmtTongatapu: '(GMT+13:00) Nuku alofa',
    menu: {
        newReportMenu: "The new report menu is divided into 5 main sections: Sales, Inventory, Tax, Employee, and Order Machines",
        salesMenu: "The Sales menu displays various sales-related reports such as sales by day, bill details, sales by product, packaging size, product options, product groups, payments, sales closing, sales cancellations, and table usage statistics",
        inventoryMenu: "The Inventory menu displays warehouse-related reports such as product receipt list, product issue list, unadjusted stock, product export list, inventory by SKU, packaging size, products with Serial No., product movements, low stock, and out-of-stock products",
        taxMenu: "The Tax menu displays tax-related reports such as sales tax summary and invoiced bills",
        employeeMenu: "The Employee menu displays reports related to employees such as sales by employee and working hours",
        orderMachinesMenu: "The Order Machines menu displays reports related to Staff Order machines such as product sales, sold products, and sales cancellations",
        dataManagement: "Following the reports section is the data management section, divided into 3 main menus: Product Details, Inventory, and Store & Branch",
        productDetailsMenu: "The Product Details menu is used for creating, editing, or deleting product items, product groups, packaging units, add-on options, and option groups, as well as bulk importing product items",
        inventoryManagementMenu: "The Inventory Management menu is used for managing warehouse documents, where related reports are categorized under the inventory category. It allows importing or issuing product items, stock adjustments, and stocktaking",
        storeBranchMenu: "The Store & Branch menu contains information related to managing stores and branches",
        selfOrderingMenu: "The Self-Ordering menu includes options for QR-ORDER and kiosks for self-service ordering in stores that offer this service",
        membersMenu: "The Members menu includes options for store memberships and a CRM system integrated with the store's website",
        settingsMenu: "The Settings menu includes options for store settings, language and timezone, payment methods, employees, access rights, delivery service providers, admin tools, product menu settings, Silom Line Alert, and store account settings"
    },
    menuDescription: "Menu Usage Description",
    printFoodOrderStickers: "Print QR Order Stickers",
    registerToReceiveFoodOrderStickers: "Register to Receive Food Order Stickers",
    stickerSize: "Sticker Size: 10 x 10 cm",
    preciseEdgeCutting: "Precise Edge Cutting",
    waterproofPVCMaterial: "Waterproof PVC Material",
    deliveryWithin14Days: "Delivery within 14 Days",
    firstTimeRegistrationFree: "First-Time Registration Free",
    contactus: 'Contact Us',
    exportToPdf: 'Export as PDF',
    exportingDespt: 'Generating file, this may take a moment...',
    copy: 'Copy',
    settingSelfService: 'Self Service Setting',
    setDeliveryDomain: 'Set the domain name for accessing the Silom Delivery',
    setDeliveryDomainDespt: 'Customers can access the Silom Delivery system via the specified URL',
    setDeliveryDomainDespt2: 'Once the domain name is saved, it cannot be changed later',
    plsSetDomainName: 'Please set the domain name',
    domianInUsed: 'This domain is already used',
    qrTypeSuggest: '• Dynamic QR: Staff must print a new order slip every time a customer comes in for service.<br>• Static QR: Print the sticker only once and place it on the table for ordering',
    sampleSticker: 'Sample sticker for table',
    generatingStickerQR: 'Generating PDF file, the time to create the stickers will depend on the number of tables',
    warningStaticQR: 'The restaurant may be at risk of receiving orders from external individuals',
    promotion: 'Promotion',
    ITEM_FIXED_PRICE: 'Promotion Price (Item)',
    CATEGORY_FIXED_PRICE: 'Promotion Price (Category)',
    ITEM_PERCENTAGE_DISCOUNT: 'Item Discount (%)',
    ITEM_DOLLAR_DISCOUNT: 'Item Discount (฿)',
    CATEGORY_PERCENTAGE_DISCOUNT: 'Category Discount (%)',
    CATEGORY_DOLLAR_DISCOUNT: 'Category Discount (฿)',
    RECEIPT_PERCENTAGE_DISCOUNT: 'Receipt Discount (%)',
    RECEIPT_DOLLAR_DISCOUNT: 'Receipt Discount (฿)',
    FREE_ITEM: 'Free Item',
    FIXED_PRICE_OPTION: "Promotion Price",
    DOLLAR_DISCOUNT_OPTION: "Item Discount (฿)",
    PERCENTAGE_DISCOUNT_OPTION: "Item Discount (%)",
    RECEIPT_DOLLAR_DISCOUNT_OPTION: 'Bill Discount (฿)',
    RECEIPT_PERCENTAGE_DISCOUNT_OPTION: 'Bill Discount (%)',
    FREE_ITEM_OPTION: "Free Item",
    FIXED_PRICE_OPTION_DESP: 'Set a special price for selected items in the promotion',
    RECEIPT_DOLLAR_DISCOUNT_OPTION_DESP: 'Discount in a fixed amount applied at the end of the bill based on promotion conditions',
    RECEIPT_PERCENTAGE_DISCOUNT_OPTION_DESP: 'Percentage discount applied at the end of the bill based on promotion conditions',
    DOLLAR_DISCOUNT_OPTION_DESP: 'Apply a fixed dollar amount discount on items based on the promotion conditions',
    PERCENTAGE_DISCOUNT_OPTION_DESP: 'Apply a percentage discount on items based on the promotion conditions',
    FREE_ITEM_OPTION_DESP: 'Provide free items when the conditions of the promotion are met',
    ITEM_LEVEL: 'Item Level',
    CATEGORY_LEVEL: 'Category Level',
    RECEIPT_LEVEL: 'Receipt Level',
    selectPromotion: "Select Promotion",
    selectPromotionDesp: "Choose the promotion you want to apply for sales.",
    selectPromotionLevel: "Select Promotion Level",
    selectPromotionLevelDesp: "Select the level of the promotion, such as item level or category level",
    selectCondition: "Select Condition",
    selectConditionDesp: "Choose the conditions you want for this promotion.",
    inputPromotionDetails: "Enter Promotion Details",
    inputPromotionDetailsDesp: "Provide a detailed description of the promotion, such as benefits, eligibility, and any special conditions.",
    inputPromotionDetailsDesp: "Enter the relevant details for the promotion, such as discounts and dates.",
    BUNDLE_ITEM: "Bundled Items",
    BUNDLE_ITEM_DESP: "This promotion applies when customers purchase a specific set of bundled items.",
    BUNDLE_CATEGORY: "Bundled Categories",
    BUNDLE_CATEGORY_DESP: "This promotion applies when customers purchase from a specific set of categories.",
    QUANTITY_ITEM_EXCEED: "Item Quantity Exceed",
    QUANTITY_ITEM_EXCEED_DESP: "This promotion applies when customers purchase more than the specified number of items.",
    QUANTITY_CATEGORY_EXCEED: "Category Quantity Exceed",
    QUANTITY_CATEGORY_EXCEED_DESP: "This promotion applies when customers purchase a certain quantity from specific categories.",
    QUANTITY_RECEIPT_EXCEED: "Receipt Quantity Exceed",
    QUANTITY_RECEIPT_EXCEED_DESP: "This promotion applies when the total number of items in the receipt exceeds a specified amount.",
    PRICE_ITEM_EXCEED: "Item Price Exceed",
    PRICE_ITEM_EXCEED_DESP: "This promotion applies when the total price of specific items exceeds the defined threshold.",
    PRICE_CATEGORY_EXCEED: "Category Price Exceed",
    PRICE_CATEGORY_EXCEED_DESP: "This promotion applies when the total price from specific categories exceeds the defined threshold.",
    PRICE_RECEIPT_EXCEED: "Receipt Price Exceed",
    PRICE_RECEIPT_EXCEED_DESP: "This promotion applies when the total price on the receipt exceeds the defined threshold.",
    NO_CONDITION: "No Condition",
    NO_CONDITION_DESP: "This promotion applies with no additional conditions.",
    limit: 'Limit',
    BUNDLE_OPTION: 'Bundle Item',
    QUANTITY_EXCEED_OPTION: 'Quantity Exceeds',
    PRICE_EXCEED_OPTION: 'Total Exceeds',
    createPromotion: 'Create Promotion',
    promotionActive: 'Active',
    promotionNotActive: 'Inactive',
    startAt: 'Start at',
    endAt: 'End at',
    exitPage: 'Exit',
    promotionCoverImg: 'Promotion Cover Image (Optional)',
    promotionCoverImgDesp: 'Use it for store displays or online media to make your promotion more appealing and attract more customers. Recommended image ratio is 16:9 or 1280x720 pixels.',
    promotionName: 'Promotion Name',
    promotionNameDesp: 'Enter the promotion name, such as "New Year Welcome Discount" or "Instant 20% Off on Final Bill".',
    conditionInput: 'Details and Conditions',
    conditionInputDesp: 'Enter details or conditions for using the promotion, e.g., immediate discount when pairing hot coffee with a croissant',
    promotionDuration: 'Promotion Duration',
    promotionDurationDesp: 'Specify the promotion period, such as start and end dates for the offer.',
    promotionPriority: 'Promotion Priority',
    promotionPriorityDesp: 'Specify the importance level of this promotion to determine its priority when multiple promotions apply.',
    promotionScope: 'Promotion Usage Channel',
    promotionScopeDesp: 'Define the scope of the promotion, such as for in-store sales or delivery options.',
    automaticPromotion: 'Automatic Promotion',
    automaticPromotionDesp: 'Set up automatic promotions that apply based on specified conditions. Note that automatic promotions cannot be configured as code-based promotions.',
    useReferenceCode: 'Use Discount Code',
    defineRefCode: 'Set Reference Code',
    useReferenceCodeDesp: 'Set a reference code that customers can enter to receive the promotion benefits.',
    promotionUsageLimit: 'Promotion Quota Limit',
    promotionUsageLimitDesp: 'Set the maximum number of times this promotion can be used by customers within the promotion period.',
    canCombineWithOtherPromotions: 'Can be combined with other promotions',
    canCombineWithOtherPromotionsDesp: 'Allow this promotion to be used together with other active promotions to maximize customer benefits.',
    promotionUsageDaysLimit: 'Limit Promotion Days',
    promotionUsageDaysLimitDesp: 'Specify the days of the week when this promotion can be applied, allowing you to control availability for specific days.',
    promotionDetailHeader: 'Promotion Details',
    promotionDetailHeaderDesp: 'Provide specific details about the promotion to clearly explain its benefits, usage, and any relevant conditions for the customers.',
    conditionHeader: 'Condition',
    conditionHeaderDesp: 'Define specific conditions for the promotion, such as minimum purchase or product combinations, to tailor the promotion to specific requirements.',
    bundles: 'Bundles',
    QTYinput: 'Quantity',
    selectCategoryInput: 'Select Category',
    whenBuyThese: "When buying these items together",
    piecesOrMore: "pieces or more",
    whenBuyCategoriesTogether: "When buying items in these categories together",
    whenBuyItem: 'When buying items',
    whenTotalItemPrice: "When the total price of items reaches",
    whenTotalReceiptPrice: "When the total bill reaches",
    promotionHeader: "Promotion",
    bahtOrMore: "Baht or more",
    itemHeader: "Item",
    setPromotionPrice: "Set Promotion Price",
    currencyBaht: "Baht",
    limitQTY: "Limit Quantity",
    itemsPerBill: "Items per Bill",
    categoryHeader: "Category",
    priceLimit: "Maximum Item Price",
    addMoreDiscounts: "Add More Discounts",
    maximunDiscount: 'Maximun',
    freeItem: 'Free ',
    promotionDiscountTotal: 'Promotion Discount',
    promotionDiscount: 'Promotion Discount',
    freeItemLimit: "Promotion Usage Limit",
    freeItemLimitDesp: "For free item promotions",
    X_FREE_Y_OPTION: 'Buy X Get Y',
    ITEM_X_FREE_Y: 'Buy X Get Y Free',
    CATEGORY_X_FREE_Y: 'Buy X Get Y Free',
    conditionHeader: 'Condition',
    ITEM_DISCOUNT_OPTION: 'Item Discount',
    CATEGORY_DISCOUNT_OPTION: 'Category Discount',
    RECEIPT_DISCOUNT_OPTION: 'Bill Discount',
    categoryNameHeader: 'Category Name',
    stepPromotion1: 'Discount',
    stepPromotion2: 'Condition',
    stepPromotion3: 'Enter Condition',
    stepPromotion4: 'Enter Discount',
    stepPromotion5: 'Other Details',
    inCorrectDiscount: 'Incorrect Discount',
    fixedPriceCanNotBeGreater: 'The promotional price must not be higher than the regular price',
    buy: 'Buy',
    pieceGetFree: 'Pieces then Get Free',
    piece: 'Pieces',
    discountedTo: 'Discounted to',
    highest: 'Highest',
    lowest: 'Lowest',
    bathPerPiece: 'Baht per Item',
    confirmSavePromotionHeader: 'Confirm Saving Promotion',
    confirmSavePromotionDesp: 'Promotion edits can be made at least 1 hour before the promotion start time.<br>Once the promotion is saved, it will be activated automatically.',
    promotion001: 'Item Discount ฿',
    promotion002: 'Category Discount ฿',
    promotion003: 'Receipt Discount ฿',
    promotion004: 'Item Discount %',
    promotion005: 'Category Discount %',
    promotion006: 'Receipt Discount %',
    promotion007: 'Free Item',
    promotion008: 'Special Price when quantity is reached',
    promotion009: 'Discount ฿ when quantity is reached',
    promotion010: 'Discount % when quantity is reached',
    templatePromotion: 'Templates',
    promotionTemplateDesp: 'For creating promotions, helping you choose and apply templates that suit your business conveniently and quickly, saving time and increasing efficiency in promotion management',
    inventoryHelp: {
        transferOut: {
            title1: "How to Transfer Products Between Branches",
            title2: "Steps to Receive Products into the Warehouse Between Branches"
        },
        transferIn: {
            title1: "How to Transfer Products Between Branches",
            title2: "Steps to Receive Products into the Warehouse Between Branches"
        },
        inventoryStock: {
            title1: "Using the Warehouse System in Silom POS"
        }
    },
    settingHelp: {
        shop: {
            title1: 'How to set store open/close time',
            title2: 'How to configure Silom POS store settings',
            title3: 'How to configure additional service charges',
            title4: 'Using restaurant mode and Staff Order feature'
        },
        qrOrder: {
            title1: 'Introducing Mobile Order: Ordering at the table via QR code',
            title2: 'How to set up and use Mobile Order with Static QR Code',
            title3: 'How to set up and use Mobile Order with Dynamic QR Code'
        },
        staff: {
            title1: 'Enable employee mode, check-in/out times for employees',
            title2: 'Configure basic user permissions'
        }
    },
    productHelp: {
        product: {
            title1: "Creating Composite Products (BOM)",
            title2: "Creating and Selling Products in Various Package Sizes",
            title3: "Creating Serial-Numbered Products",
            title4: "Creating and Selling Products with Step Pricing",
            title5: "Creating Service Products"
        },
        addproduct: {
            title1: "Setting up VAT in SilomPOS",
            title2: "Creating Retail, Member, and Wholesale Prices",
            title3: "Setting up Additional Service Charges",
            title4: "Setting up Low Stock Alert System",
            title5: "Setting up Sales Price for Delivery Channels",
            title6: "Using Topping or Add-on Options"
        },
        option: {
            title1: "Using Topping or Add-on Options"
        },
        import: {
            title1: "How to Import Products"
        }
    },
    reportHelp: {
        daily: {
            title1: "Steps to View and Export Sales Summary Reports"
        },
        transaction: {
            title1: "How to Issue a Tax Invoice",
            title2: "How to Cancel a Bill"
        },
        transfer: {
            title1: "How to Transfer Products Between Branches",
            title2: "Steps to Receive Products in the Branch Warehouse"
        },
        payment: {
            title1: "Setting up Mixed Payment",
            title2: "Setting up Fixed Payment",
            title3: "Setting up EDC Machine Connection for Kbank with POS"
        },
        staff: {
            title1: "Enable Staff Mode and Check In/Out Times",
            title2: "Basic User Permission Settings"
        },
        closesell: {
            title1: "How to Set Up Opening/Closing Sales Shift",
            title2: "Cash Management"
        },
        tax: {
            title1: "How to Issue a Tax Invoice"
        }
    },
    userHelp: {
        title1: "How to Renew Silom POS Package",
        title2: "How to Reset Password in Silom POS"
    },
    advanceCreate: 'Advance Create ',
    ITEM_FIXED_PRICE_DESP: 'Set a new promotional price for selected items.',
    CATEGORY_FIXED_PRICE_DESP: 'Set a new promotional price for items in selected categories.',
    ITEM_PERCENTAGE_DISCOUNT_DESP: 'Apply a percentage discount on selected items.',
    ITEM_DOLLAR_DISCOUNT_DESP: 'Apply a fixed dollar discount on selected items.',
    CATEGORY_PERCENTAGE_DISCOUNT_DESP: 'Apply a percentage discount on items in selected categories.',
    CATEGORY_DOLLAR_DISCOUNT_DESP: 'Apply a fixed dollar discount on items in selected categories.',
    RECEIPT_PERCENTAGE_DISCOUNT_DESP: 'Apply a percentage discount on the entire receipt.',
    RECEIPT_DOLLAR_DISCOUNT_DESP: 'Apply a fixed dollar discount on the entire receipt.',
    FREE_ITEM_DESP: 'Provide a free item as part of the promotion.',
    promotionExpired: 'Promotion is Expired',
    confirmEditPromotion: 'Do you want to save change?',
    confirmCreatePromotion: 'Do you want to create promotion?',
    confirmDeletePromotion: 'Confirm Promotion Deletion',
    confirmDeletePromotionDesp: 'Once you confirm deletion, this promotion will be immediately deactivated and cannot be restored.<br>Please make sure to review before proceeding with deletion',
    promotionFeatures: [
        {
            title: "",
            description: "Supports creating Grab Food campaigns"
        },
        {
            title: "",
            description: "A flexible promotion creation system with over 63 customizable formats."
        },
        {
            title: "",
            description: "Template-based system for easy promotion creation."
        },
        {
            title: "",
            description: "Specific date and time settings for promotion usage."
        },
        {
            title: "",
            description: "Dynamic promotion activation system"
        },
        {
            title: "",
            description: "Promotion code setting system"
        },
        {
            title: "",
            description: "Set limits on the number of times a promotion can be used."
        },
    ],
    promotionHeaderEmpty: 'Promotion Management',
    promotionHeaderEmptyDesp: "This system allows you to create and manage promotions efficiently, helping to boost sales and attract customers with user-friendly features and customizable conditions tailored to your needs.",
    silomPOSPromotion: 'Promotion',
    confirmActivePromotion: 'Do you want to activate this promotion?<br>The promotion will be displayed on the POS, and customers will be able to use it.',
    confirmDeActivePromotion: 'Do you want to deactivate this promotion?<br>Customers will not be able to use this promotion until it is reactivated.',
    maxPercenDiscount: 'The maximum possible total discount is',
    usedPromotionBadge: 'Used in ',
    usedPromotionBadgeDesp: 'Receipts',
    promotionApplied: 'This promotion has already been used for ',
    limitQuota: 'Limit Quota',
    invalidQuotaInput: 'Quota is Invalid',
    'DINE_IN': 'Dine-In',
    'PICK_UP': 'Pick-Up',
    'DELIVERY': 'Delivery',
    'TAKE_AWAY': 'Take Away',
    integratePromotionWith: 'Set promotion as ',
    newCustomerOnly: 'New customers only',
    newCustomerOnlyDesp: 'This promotion is exclusively available for customers who have never made a purchase or used the service before, offering special privileges for new customers only.',
    promotionUsageLimitPerCustomer: 'Promotion usage limit per customer',
    promotionUsageLimitDespPerCustomer: 'The maximum number of redemptions allowed per individual customer',
    timePerUser: 'Quota Per User',
    alertFreeItemGrab: 'For creating Grab Food promotions, only one free item can be added',
    createPromotionGrabInfoHeader: 'Promotion for Grab Food',
    createPromotionGrabInfoDesp: 'The Grab Food promotion scope will save the promotion to your store and must comply with the following terms and conditions of Grab Food:',
    grabPromotionGuidelines: {
        guideline1: 'Once the scope of use is set on Grab Food, it cannot be canceled later.',
        guideline2: 'Promotion details cannot be edited after the promotion is activated.',
        guideline3: "Promotion details can be edited at least 1 hour before the promotion start time.",
        guideline4: 'To stop a Grab Food promotion, it can be done by deleting the promotion.',
    },
    onGoingPromotionDesp: 'The promotion period is ongoing. Editing information is not allowed.',
    moreInfo: 'View more details',
    support: 'Support',
    defineForGrabCampagin: 'Used for defining Grab Campaigns',
    saveFailedHeader: 'Oops! Something Went Wrong',
    forGrabCampagin: 'This condition will only be applied to orders originating from Grab Food',
    errorMessagesGrab: {
        '404Category': 'Category not found in Grab Food store',
        '400InvalidParameters': 'Invalid input provided. Please check and try again.',
        '400CategoryOne': 'Only one category can be set for the discount.',
        '400StartTime': 'Please set the start time to at least 15 minutes from now.',
        '404Item': 'The specified item was not found in the Grab Food store.',
        '400CampaignStartTime': 'Cannot edit the campaign if it is less than 1 hour before the start time.',
        '400ItemMultiple': 'The specified item is already part of a previous promotion.',
        '400PromotionOverlaps': 'A similar promotion has already been created.',
        '400InvalidQuotas': 'The quota settings are invalid.',
        'unknownError': 'An unknown error has occurred.',
        'validateInfomation': 'Please input required information',
        '400CategoryCampaignOverlap': 'A similar category discount promotion has already been created'
    },
    createGrabPromotionDesp: 'Once the promotion is saved, it can be edited <br>at least 1 hour before the promotion start time',
    invalidInput: 'Invalid input',
    invalidDate: 'Invalid date and time',
    invalidDateDesp: 'The start date must be at least 1 hour later than the current time',
    grabPromotionDurationDesp: 'and the promotion start time must be at least 15 minutes from now',
    grabPromotionTemplateDesp: 'SilomPOS promotion template is fully compatible with Grab Food and customizable to suit your business. It supports various settings to boost sales and attract customers more effectively.',
    templates: 'Templates',
    forSmartPlusPackage: 'For Grab stores with a Smart+ package or higher',
    createPromotionInfoHeader: 'Silom POS Promotion',
    createPromotionInfoDesp: 'Promotions for use in the Silom POS system must comply with the following conditions and terms:',
    promotionGuidelines: {
        guideline1: 'After creating a promotion, details can be edited at least 1 hour before the promotion start time.',
        guideline2: 'Promotions can be temporarily disabled by toggling the switch on the promotion details page.',
    },
    high: 'High',
    medium: 'Medium',
    low: 'Low',
    receiptDiscount: 'Receipt Discount',
    maxDiscount: 'Maximum',
    itemDiscountMapper: 'Discount',
    andMoreDiscount: 'and more discounts available',
    categoryDiscount: 'Category Discount',
    specialPrice: 'Special Price',
    forUnderPrice: 'for items priced under',
    freeItemMapper: 'Free Item',
    whenPriceReceiptReach: 'Condition when total bill value reaches',
    whenQuantityReceiptReach: 'Condition when total bill quantity reaches',
    exceedPrice: 'baht or more',
    exceedQuantity: 'items or more',
    whenBuySome: 'Condition when buying',
    forTotalPrice: 'with a total value of',
    forTotalQuantity: 'with a total quantity of',
    whenBuySomeCategory: 'Condition when buying items in the category',
    enterSixDigitCode: "Enter six-digit code",
    notifications: "Notifications",
    setNotificationPreferences: "Set notification preferences for orders and store activities",
    remainingQuota: "Remaining quota",
    monthlyUsedQuota: "Quota used this month",
    dailyUsedQuota: "Quota used today",
    dailyAverageQuota: "Daily average",
    topUpQuota: "Top up quota",
    addNotificationChannel: "Add notification channel",
    registerForNotifications: "Register for notifications",
    enterReferenceCode: "Enter reference code",
    enterReferenceCodeDescription: "Please enter the reference code received from the system to proceed",
    enterSixDigitSilomBot: "Enter the six-digit code received from Silom Biz Alert",
    howToGetCode: "See how to get a code from various channels",
    goToHelp: "Go to Help",
    languageSelection: "Select notification language",
    deleteNotificationChannel: "Disconnect notification channel",
    deleteNotificationPrompt: "Do you want to remove this channel?",
    deleteNotificationWarning: "If you proceed with deletion, you will no longer receive notifications via this channel",
    salesNotification: "Sales Notification",
    salesNotificationDescription: "Track real-time sales whenever a new order is placed",
    closeShopReportNotification: "Daily Shop Closing Report Notification",
    closeShopReportNotificationDescription: "Review daily sales and get a summary report whenever the shop closes",
    cashRoundNotification: "Cash Round Notification",
    cashRoundNotificationDescription: "Get updates on cash rounds and ensure proper cash flow tracking",
    timeAttendanceNotification: "Employee Attendance Notification",
    timeAttendanceNotificationDescription: "Monitor employee clock-ins and clock-outs for better workforce management",
    selectPackage: "Select package",
    makePayment: "Make payment",
    pricingTitle: "Pricing packages",
    sufficientPack: "Sufficient Pack",
    seamlessPack: "Seamless Pack",
    proPack: "Pro Pack",
    sufficientPackDescription: "Ideal for small stores, efficiently sending messages at a great price!",
    seamlessPackDescription: "A popular choice for businesses that frequently send messages at a cost-effective rate!",
    proPackDescription: "The ultimate package for large businesses, ensuring professional and continuous messaging!",
    packagePrice: "Price",
    averagePricePerMessage: "Average price per message",
    paymentTerms: "Payment terms",
    paymentMethods: "Supports payments via credit/debit cards and bank transfers",
    creditAddedWithin5Min: "Credit will be added to the system within 5 minutes after a successful payment",
    contactSupportIfNoCredit: "If you do not receive credit, please contact customer support",
    noRefundOrChange: "Refunds or package changes are not allowed after payment is completed",
    cancelPayment: "Cancel payment",
    message: "Messages",
    starter: "Starter",
    recommended: "Recommended",
    bestValue: "Best Value",
    salesNotificationDescription: "Receive a notification when a new sale is made",
    incorrectRefCode: "Invalid reference code. Please try again!",
    unknownError: 'An unknown error has occurred',
    alertInAppBrowser: 'For the best experience, please open this page in another browser such as Chrome or Safari.',


    actiosHistory: 'History',
    qrExpired: 'QR Expired',
    qrPending: 'Pending',
    regenerateQR: 'Re-Generate QR',
    paymentSuccessTitle: "Payment Successful",
    paymentSuccessful: "Thank you for your purchase!",
    paymentSuccessDescription: "Your payment was processed successfully.<br> Your data has been updated.",
    refCode: 'Ref Code',
    'silom-alert': 'Notification',
    "createLineGroup": "Create a group chat in LINE",
    "inviteFriendsAndAddBotLine": "Invite friends and add the Silom bot to the group chat",
    "botSendsRegistrationCode": "The bot will send a 6-digit registration code",
    "ifNoCodeTypeRegister": "If you don't receive a code, type 'ลงทะเบียน' to request a new one",
    "useCodeForNotifications": "Use this code to register for notifications",

    "createTelegramGroup": "Create a group chat in Telegram",
    "inviteFriendsAndAddBotTelegram": "Invite friends and add the Silom bot by searching @silom_alert_bot and add it to the group chat",
    "typeRegisterInGroup": "Type '/register' in the group",
    "botSendsRegistrationCode": "The bot will send a 6-digit registration code",
    "useCodeForNotifications": "Use this code to register for notifications",
    "notification": {
        "basic": "Receive basic notification messages",
        "requiresCredits": "Requires message credits",
        "futureFeatures": "Additional features in the future",
        "professionalSuitable": "Ideal for businesses seeking a professional system",
    },
    headerNotifyFeature: "Notification comparison between LINE and Telegram",
    feature: 'Features',
    chooseYourBestPlatform: "Choose the platform that best suits your business"
}

export default messages
