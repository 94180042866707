<template>
    <div v-if="!loading" class="w-100 text-dark">
        <div v-if="isReadonly">
            <h5 class="mt-2"><span class="text-danger">*</span> {{ $t('promotionHeader') }} : {{ $t(promotionType) }}
            </h5>
        </div>
        <!-- ITEM_FIXED_PRICE -->
        <div v-if="promotionType == 'ITEM_FIXED_PRICE'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2" v-for="(result, resultIndex) in results"
                :key="'result-' + resultIndex">
                <h5 v-if="resultIndex !== 0">{{ $t('itemHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div v-show="!isGrabTemplate" class="mt-2 mb-2">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('limitQTY') }}</span>
                        <input v-if="!isReadonly" @input="validateQuantityLimit(result.quantityLimit, resultIndex)"
                            :readonly="isReadonly" v-model.number="result.quantityLimit" type="number"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <input v-else-if="isReadonly" :readonly="isReadonly" type="number"
                            :value="getQuantityLimitDisplay(result.quantityLimit)"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <span class="input-group-text input-icon-right">{{ $t('itemsPerBill') }}</span>
                    </div>
                </div>
                <CButton v-if="!isReadonly" @click="openSelectProductModal(resultIndex)" class="" color="success" block>
                    {{
                        $t('addProduct') }}</CButton>
                <DataTable class="mt-2" :items="getItems(resultIndex)" :fields="fields" responsive border>
                    <template #img="{ item }">
                        <td>
                            <div class="d-flex justify-content-center align-items-center">
                                <img @error="noImgUrl" v-if="item.remoteImagePath !== ''" :src="item.remoteImagePath"
                                    fluid alt="Product Image"
                                    style="width: 50px; height: 50px; border-radius: 5px; object-fit: cover;" />
                                <div v-else
                                    :style="{ width: '50px', height: '50px', borderRadius: '5px', backgroundColor: item.indexColor }"
                                    fluid alt="Product Image"></div>
                            </div>
                        </td>
                    </template>
                    <template #price="{ item }">
                        <td class="text-dark text-right">
                            <span style="color: gray;text-decoration: line-through;">{{ convertCurrency(item.price)
                                }}</span>
                        </td>
                    </template>
                    <template #promotionPrice="{ item, index }">
                        <td class="text-dark text-right">
                            <input v-if="!isReadonly" @input="validateFixedPriceInput(item.price, index)" type="number"
                                min="0" :max="item.price" style="min-width: 100px;"
                                :class="getFormValidateClass(result.items[index].fixedPrice)"
                                v-model.number="result.items[index].fixedPrice">
                            <div v-else>{{ convertCurrency(result.items[index].fixedPrice) }}</div>
                        </td>
                    </template>
                    <template #delete="{ item, index }">
                        <td @click="removeBadgeItem(resultIndex, index)" class=" p-4">
                            <i class="fi fi-rr-trash text-danger"></i>
                        </td>
                    </template>
                </DataTable>
            </div>
        </div>

        <!--CATEGORY_FIXED_PRICE -->
        <div v-if="promotionType == 'CATEGORY_FIXED_PRICE'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('categoryHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="mt-2 ">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('priceLimit') }}</span>
                        <input v-if="!isReadonly" :readonly="isReadonly" v-model.number="result.cap" type="number"
                            placeholder="∞" :class="getFormCapValidateClass(result.cap)">
                        <input v-else :readonly="isReadonly" :value="getCapDisplay(result.cap)" type="number"
                            placeholder="∞" :class="getFormCapValidateClass(result.cap)">
                        <span class="input-group-text input-icon-right">{{ $t('currencyBaht') }}</span>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('setPromotionPrice') }}</span>
                        <input :readonly="isReadonly" v-model.number="result.value" type="number"
                            :class="getFormValidateClass(result.value)">
                        <span class="input-group-text input-icon-right">{{ $t('bathPerPiece') }}</span>
                    </div>
                    <div v-show="!isGrabTemplate" class="input-group mb-3">
                        <span class="input-group-text">{{ $t('limitQTY') }}</span>
                        <input v-if="!isReadonly && !isGrabTemplate"
                            @input="validateQuantityLimit(result.quantityLimit, resultIndex)" :readonly="isReadonly"
                            v-model.number="result.quantityLimit" type="number"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <input v-else-if="isReadonly" :readonly="isReadonly" type="number"
                            :value="getQuantityLimitDisplay(result.quantityLimit)"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <span class="input-group-text input-icon-right">{{ $t('itemsPerBill') }}</span>
                    </div>
                </div>
                <div>
                    <select v-if="!isReadonly"
                        style="color: var(--success);border-color: var(--success);text-align: center"
                        ref="categorySelect" class="form-control mb-4 mt-4" @change="handleCategoryChange(resultIndex)"
                        v-model="selectedCategory">
                        <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                        <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                    </select>
                    <DataTable :items="getCategoryItems(resultIndex)" :fields="fieldsCategory" responsive border>
                        <template #delete="{ item, index }">
                            <td @click="removeBadgeCategory(resultIndex, index)" class="p-4 text-center">
                                <i class="fi fi-rr-trash text-danger"></i>
                            </td>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>

        <!-- ITEM_PERCENTAGE_DISCOUNT-->
        <div v-if="promotionType == 'ITEM_PERCENTAGE_DISCOUNT'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('itemHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="mt-2 ">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('discount') }}</span>
                        <input max="100" :readonly="isReadonly" v-model.number="result.value" type="number"
                            :class="getFormValidateClass(result.value)"
                            @input="validateResultIntegerInput(resultIndex)">
                        <span class="input-group-text input-icon-right">%</span>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('maximunDiscount') }}</span>
                        <input v-if="!isReadonly" placeholder="∞" :readonly="isReadonly" v-model.number="result.cap"
                            type="number" :class="getFormCapValidateClass(result.cap)">
                        <input v-else :readonly="isReadonly" :value="getCapDisplay(result.cap)" type="number"
                            placeholder="∞" :class="getFormCapValidateClass(result.cap)">
                        <span class="input-group-text input-icon-right">{{ $t('bathPerPiece') }}</span>
                    </div>
                    <div v-show="!isGrabTemplate" class="input-group mb-3">
                        <span class="input-group-text">{{ $t('limitQTY') }}</span>
                        <input v-if="!isReadonly && !isGrabTemplate"
                            @input="validateQuantityLimit(result.quantityLimit, resultIndex)" :readonly="isReadonly"
                            v-model.number="result.quantityLimit" type="number"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <input v-else-if="isReadonly" :readonly="isReadonly" type="number"
                            :value="getQuantityLimitDisplay(result.quantityLimit)"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <span class="input-group-text input-icon-right">{{ $t('itemsPerBill') }}</span>
                    </div>
                </div>
                <CButton v-if="!isReadonly" @click="openSelectProductModal(resultIndex)" class="mb-4 mt-4"
                    color="success" block>
                    {{
                        $t('addProduct') }}</CButton>
                <DataTable :items="getItems(resultIndex)" :fields="fields" responsive border>
                    <template #img="{ item }">
                        <td>
                            <div class="d-flex justify-content-center align-items-center">
                                <img @error="noImgUrl" v-if="item.remoteImagePath !== ''" :src="item.remoteImagePath"
                                    fluid alt="Product Image"
                                    style="width: 50px; height: 50px; border-radius: 5px; object-fit: cover;" />
                                <div v-else
                                    :style="{ width: '50px', height: '50px', borderRadius: '5px', backgroundColor: item.indexColor }"
                                    fluid alt="Product Image"></div>
                            </div>
                        </td>
                    </template>
                    <template #price="{ item }">
                        <td class="text-dark text-right">
                            <span v-if="result.value == null || result.value <= 0">{{ convertCurrency(item.price)
                                }}</span>
                            <span v-else style="color: gray;text-decoration: line-through;">{{
                                convertCurrency(item.price) }}</span>
                        </td>
                    </template>
                    <template #promotionPrice="{ item }">
                        <td class="text-dark text-right">
                            <template v-if="result.value < 100">
                                <span>
                                    {{ calculatePercentageDiscount(item.price, result.value, result.cap) }}
                                </span>
                            </template>
                            <template v-else>
                                <span class="text-danger">{{ $t('inCorrectDiscount')
                                    }}</span>
                            </template>
                        </td>
                    </template>
                    <template #delete="{ item, index }">
                        <td @click="removeBadgeItem(resultIndex, index)" class=" p-4">
                            <i class="fi fi-rr-trash text-danger"></i>
                        </td>
                    </template>
                </DataTable>
            </div>
        </div>

        <!-- ITEM_DOLLAR_DISCOUNT-->
        <div v-if="promotionType == 'ITEM_DOLLAR_DISCOUNT'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('itemHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="mt-2 ">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('discount') }}</span>
                        <input :readonly="isReadonly" v-model.number="result.value" type="number"
                            :class="getFormValidateClass(result.value)"
                            @input="validateResultIntegerInput(resultIndex)">
                        <span class="input-group-text input-icon-right">{{ $t('bathPerPiece') }}</span>
                    </div>
                    <div v-show="!isGrabTemplate" class="input-group mb-3">
                        <span class="input-group-text">{{ $t('limitQTY') }}</span>
                        <input v-if="!isReadonly && !isGrabTemplate"
                            @input="validateQuantityLimit(result.quantityLimit, resultIndex)" :readonly="isReadonly"
                            v-model.number="result.quantityLimit" type="number"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <input v-else-if="isReadonly" :readonly="isReadonly" type="number"
                            :value="getQuantityLimitDisplay(result.quantityLimit)"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <span class="input-group-text input-icon-right">{{ $t('itemsPerBill') }}</span>
                    </div>
                </div>
                <CButton v-if="!isReadonly" @click="openSelectProductModal(resultIndex)" class="mb-4 mt-4"
                    color="success" block>
                    {{
                        $t('addProduct') }}</CButton>
                <DataTable :items="getItems(resultIndex)" :fields="fields" responsive border>
                    <template #img="{ item }">
                        <td>
                            <div class="d-flex justify-content-center align-items-center">
                                <img @error="noImgUrl" v-if="item.remoteImagePath !== ''" :src="item.remoteImagePath"
                                    fluid alt="Product Image"
                                    style="width: 50px; height: 50px; border-radius: 5px; object-fit: cover;" />
                                <div v-else
                                    :style="{ width: '50px', height: '50px', borderRadius: '5px', backgroundColor: item.indexColor }"
                                    fluid alt="Product Image"></div>
                            </div>
                        </td>
                    </template>
                    <template #price="{ item }">
                        <td class="text-dark text-right">
                            <span v-if="result.value == null || result.value <= 0">{{ convertCurrency(item.price)
                                }}</span>
                            <span v-else style="color: gray;text-decoration: line-through;">{{
                                convertCurrency(item.price) }}</span>
                        </td>
                    </template>
                    <template #promotionPrice="{ item }">
                        <td class="text-dark text-right">
                            <template v-if="item.price >= result.value">
                                <span>{{ convertCurrency(item.price - result.value) }}</span>
                            </template>
                            <span class="text-danger" v-else>{{ $t('fixedPriceCanNotBeGreater')
                                }}</span>
                        </td>
                    </template>
                    <template #delete="{ item, index }">
                        <td @click="removeBadgeItem(resultIndex, index)" class=" p-4">
                            <i class="fi fi-rr-trash text-danger"></i>
                        </td>
                    </template>
                </DataTable>
            </div>
        </div>
        <!-- CATEGORY_PERCENTAGE_DISCOUNT-->
        <div v-if="promotionType == 'CATEGORY_PERCENTAGE_DISCOUNT'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('categoryHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="mt-2 ">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('discount') }}</span>
                        <input :readonly="isReadonly" min="0" v-model.number="result.value" type="number"
                            :class="getFormValidateClass(result.value)"
                            @input="validateResultIntegerInput(resultIndex)">
                        <span class="input-group-text input-icon-right">%</span>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('maximunDiscount') }}</span>
                        <input v-if="!isReadonly" placeholder="∞" :readonly="isReadonly" min="0"
                            v-model.number="result.cap" type="number" :class="getFormCapValidateClass(result.cap)">
                        <input v-else :readonly="isReadonly" :value="getCapDisplay(result.cap)" type="number"
                            placeholder="∞" :class="getFormCapValidateClass(result.cap)">
                        <span class="input-group-text input-icon-right">{{ $t('bathPerPiece') }}</span>
                    </div>
                    <div v-show="!isGrabTemplate" class="input-group mb-3">
                        <span class="input-group-text">{{ $t('limitQTY') }}</span>
                        <input v-if="!isReadonly && !isGrabTemplate"
                            @input="validateQuantityLimit(result.quantityLimit, resultIndex)" :readonly="isReadonly"
                            v-model.number="result.quantityLimit" type="number"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <input v-else-if="isReadonly" :readonly="isReadonly" type="number"
                            :value="getQuantityLimitDisplay(result.quantityLimit)"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <span class="input-group-text input-icon-right">{{ $t('itemsPerBill') }}</span>
                    </div>
                    <div v-show="!isGrabTemplate" class="input-group mb-3">
                        <span class="input-group-text">{{ $t('maxPercenDiscount') }}</span>
                        <input :readonly="true" min="0"
                            :value="getMaximunPercentageDiscount(result.cap, result.quantityLimit)" type="text"
                            :class="getFormValidateClass(getMaximunPercentageDiscount(result.cap, result.quantityLimit))">
                        <span class="input-group-text input-icon-right">{{ $t('baht') }}</span>
                    </div>
                </div>
                <div>
                    <select v-if="!isReadonly"
                        style="color: var(--success);border-color: var(--success);text-align: center"
                        ref="categorySelect" class="form-control mb-4 mt-4" @change="handleCategoryChange(resultIndex)"
                        v-model="selectedCategory">
                        <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                        <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                    </select>
                    <DataTable :items="getCategoryItems(resultIndex)" :fields="fieldsCategory" responsive border>
                        <template #delete="{ item, index }">
                            <td @click="removeBadgeCategory(resultIndex, index)" class="p-4 text-center">
                                <i class="fi fi-rr-trash text-danger"></i>
                            </td>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
        <!-- CATEGORY_DOLLAR_DISCOUNT-->
        <div v-if="promotionType == 'CATEGORY_DOLLAR_DISCOUNT'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('categoryHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="mt-2 ">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('discount') }}</span>
                        <input :readonly="isReadonly" v-model.number="result.value" type="number"
                            :class="getFormValidateClass(result.value)"
                            @input="validateResultIntegerInput(resultIndex)">
                        <span class="input-group-text input-icon-right">{{ $t('bathPerPiece') }}</span>
                    </div>
                    <div v-show="!isGrabTemplate" class="input-group mb-3">
                        <span class="input-group-text">{{ $t('limitQTY') }}</span>
                        <input v-if="!isReadonly && !isGrabTemplate"
                            @input="validateQuantityLimit(result.quantityLimit, resultIndex)" :readonly="isReadonly"
                            v-model.number="result.quantityLimit" type="number"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <input v-else-if="isReadonly" :readonly="isReadonly" type="number"
                            :value="getQuantityLimitDisplay(result.quantityLimit)"
                            :class="getQuantityLimitFormValidateClass(result.quantityLimit)" placeholder="∞">
                        <span class="input-group-text input-icon-right">{{ $t('itemsPerBill') }}</span>
                    </div>
                </div>
                <div>
                    <select v-if="!isReadonly"
                        style="color: var(--success);border-color: var(--success);text-align: center"
                        ref="categorySelect" class="form-control mb-4 mt-4" @change="handleCategoryChange(resultIndex)"
                        v-model="selectedCategory">
                        <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                        <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                    </select>
                    <DataTable :items="getCategoryItems(resultIndex)" :fields="fieldsCategory" responsive border>
                        <template #delete="{ item, index }">
                            <td @click="removeBadgeCategory(resultIndex, index)" class="p-4 text-center">
                                <i class="fi fi-rr-trash text-danger"></i>
                            </td>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>

        <!-- RECEIPT_PERCENTAGE_DISCOUNT-->
        <div v-if="promotionType == 'RECEIPT_PERCENTAGE_DISCOUNT'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <div class="mt-2 ">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('discount') }}</span>
                        <input :readonly="isReadonly" v-model.number="result.value" type="number"
                            :class="getFormValidateClass(result.value)"
                            @input="validateResultIntegerInput(resultIndex)">
                        <span class="input-group-text input-icon-right">%</span>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('maximunDiscount') }}</span>
                        <input v-if="!isReadonly" placeholder="∞" :readonly="isReadonly" v-model.number="result.cap"
                            type="number" :class="getFormCapValidateClass(result.cap)">
                        <input v-else :readonly="isReadonly" :value="getCapDisplay(result.cap)" type="number"
                            placeholder="∞" :class="getFormCapValidateClass(result.cap)">
                        <span class="input-group-text input-icon-right">{{ $t('currencyBaht') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- RECEIPT_DOLLAR_DISCOUNT-->
        <div v-if="promotionType == 'RECEIPT_DOLLAR_DISCOUNT'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <div class="mt-2 ">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('discount') }}</span>
                        <input :readonly="isReadonly" v-model.number="result.value" type="number"
                            :class="getFormValidateClass(result.value)"
                            @input="validateResultIntegerInput(resultIndex)">
                        <span class="input-group-text input-icon-right">{{ $t('currencyBaht') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- FREE_ITEM-->
        <div v-if="promotionType == 'FREE_ITEM'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('itemHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="badge-container">
                    <CBadge v-if="result.product == null && !isReadonly" @click="openSelectProductModal(resultIndex)"
                        class="mr-1 pb-0 d-flex align-items-center" color="light">
                        <i style="font-size: small;" class="fi fi-rr-plus-small btn m-0 p-0"></i>
                        <span style="font-size: small;">{{ $t('addProduct') }}</span>
                    </CBadge>
                    <CBadge v-else style="font-size: small;" class="mr-1 d-flex align-items-center" color="info">
                        <i v-if="!isReadonly" @click="removeBadgeFreeItem(resultIndex)"
                            class="fi fi-rr-cross-small text-white btn p-0"></i>
                        {{ result.product.name }}
                    </CBadge>
                </div>
                <div class="mt-2">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('freeItem') }}</span>
                        <input :readonly="isReadonly" v-model.number="result.quantity" type="number"
                            :class="getFormValidateClass(result.quantity)">
                        <span class="input-group-text input-icon-right">{{ $t('itemQty') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- ITEM_X_FREE_Y-->
        <div v-if="promotionType == 'ITEM_X_FREE_Y'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('itemHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="input-group mt-2 mb-4">
                    <span class="input-group-text">{{ $t('buy') }}</span>
                    <input :readonly="isReadonly" type="number" :class="getFormValidateClass(result.inCartQuantity)"
                        v-model.number="result.inCartQuantity">
                    <span class="input-group-text input-icon-middle">{{ $t('pieceGetFree') }}</span>
                    <input :readonly="isReadonly" type="number" :class="getFormValidateClass(result.freeItemQuantity)"
                        v-model.number="result.freeItemQuantity">
                    <span class="input-group-text input-icon-right">{{ $t('piece') }}</span>
                </div>
                <CButton v-if="!isReadonly" @click="openSelectProductModal(resultIndex)" class="" color="success" block>
                    {{
                        $t('addProduct') }}</CButton>
                <DataTable class="mt-4" :items="getItems(resultIndex)" :fields="fields" responsive border>
                    <template #img="{ item }">
                        <td>
                            <div class="d-flex justify-content-center align-items-center">
                                <img @error="noImgUrl" v-if="item.remoteImagePath !== ''" :src="item.remoteImagePath"
                                    fluid alt="Product Image"
                                    style="width: 50px; height: 50px; border-radius: 5px; object-fit: cover;" />
                                <div v-else
                                    :style="{ width: '50px', height: '50px', borderRadius: '5px', backgroundColor: item.indexColor }"
                                    fluid alt="Product Image"></div>
                            </div>
                        </td>
                    </template>
                    <template #delete="{ item, index }">
                        <td @click="removeBadgeItem(resultIndex, index)" class=" p-4">
                            <i class="fi fi-rr-trash text-danger"></i>
                        </td>
                    </template>
                </DataTable>
            </div>
        </div>

        <!-- CATEGORY_X_FREE_Y-->
        <div v-if="promotionType == 'CATEGORY_X_FREE_Y'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2 " v-for="(result, resultIndex) in results">
                <h5 v-if="resultIndex !== 0">{{ $t('itemHeader') }} <i @click="removeForm(resultIndex)"
                        class="fi fi-rr-trash text-danger btn m-0 p-0"></i>
                </h5>
                <div class="input-group mt-2 mb-4">
                    <span class="input-group-text">{{ $t('buy') }}</span>
                    <input :readonly="isReadonly" type="number" :class="getFormValidateClass(result.inCartQuantity)"
                        v-model.number="result.inCartQuantity">
                    <span class="input-group-text input-icon-middle">{{ $t('pieceGetFree') }}</span>
                    <input :readonly="isReadonly" type="number" :class="getFormValidateClass(result.freeItemQuantity)"
                        v-model.number="result.freeItemQuantity">
                    <span class="input-group-text input-icon-right">{{ $t('piece') }}</span>
                </div>
                <div>
                    <select v-if="!isReadonly && result.categories == 0"
                        style="color: var(--success);border-color: var(--success);text-align: center"
                        ref="categorySelect" class="form-control " @change="handleCategoryChange(resultIndex)"
                        v-model="selectedCategory">
                        <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                        <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                    </select>
                    <DataTable class="mt-4" :items="getCategoryItems(resultIndex)" :fields="fieldsCategory" responsive
                        border>
                        <template #delete="{ item, index }">
                            <td @click="removeBadgeCategory(resultIndex, index)" class="p-4 text-center">
                                <i class="fi fi-rr-trash text-danger"></i>
                            </td>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>

        <!-- <div class="ml-1" v-if="isStackAble() && results.length <= 5">
            <CButton class="mb-2" @click="addEmptyForm()" color="success" variant="outline">{{ $t('addMoreDiscounts') }}
            </CButton>
        </div> -->
        <AppProductPicker v-if="!isReadonly" :key="'picker' + refreshKey" ref="select_product_component"
            :limitItem="limitSelectProduct" @update-selected-items="handleSelectedItems">
        </AppProductPicker>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import { CButton } from '@coreui/vue';
import AppProductPicker from '@/components/AppProductPicker';
import pos from '@/services/local'
import DataTable from "@/containers/DataTable"
import util from '@/util/util'
import '@/assets/styles/promotion.css'

export default {
    components: {
        AppProductPicker,
        DataTable
    },
    props: {
        promotionType: {
            type: String,
            required: true,
            default: 'FREE_ITEM',
        },
        resultsDisplay: {
            type: Array,
            required: false,
            default: () => [],
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        isGrabTemplate: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            results: [],
            refreshKey: 0,
            selectedIndex: 0,
            allCategories: [],
            selectedCategory: null,
            limitSelectProduct: 1,
            loading: false,
            isCalledFromParent: false,
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        shopObjectId() {
            return localStorage.getItem('shopObjectId')
        },
        fields() {
            const baseFields = [
                { key: "index", label: '', _classes: "text-dark text-center", _style: "width:10%; vertical-align: middle; text-dark" },
                { key: "img", label: '', _classes: "text-dark text-center", _style: "width:10%; vertical-align: middle; text-dark" },
                { key: "pluCode", label: this.$t('pluCode'), _classes: "text-dark text-left", _style: "width:15%; vertical-align: middle; text-dark" },
                { key: "name", label: this.$t("productName"), _classes: "text-dark", _style: "width:50%; vertical-align: middle; text-dark" },
                { key: "category", label: this.$t("category"), _classes: "text-dark", _style: "width:50%; vertical-align: middle; text-dark" },
                { key: "price", label: this.$t("price"), _classes: "text-dark text-right", _style: "width:15%; vertical-align: middle; text-dark" },
            ];

            if (this.promotionType !== 'ITEM_X_FREE_Y') {
                baseFields.push({ key: "promotionPrice", label: this.$t("discountedTo"), _classes: "text-dark text-right", _style: "width:30%; vertical-align: middle; text-dark" });
            }

            if (!this.isReadonly) {
                baseFields.push({ key: "delete", label: this.$t(""), _classes: "text-dark text-center", _style: "width:15%; vertical-align: middle; text-dark" });
            }

            return baseFields;
        },
        fieldsCategory() {
            const baseCategoryFields = [
                { key: "index", label: this.$t(""), _classes: "text-dark text-center", _style: "width:5%; vertical-align: middle; text-dark" },
                { key: "name", label: this.$t("categoryNameHeader"), _classes: "text-dark", _style: "width:50%; vertical-align: middle; text-dark" },
            ];

            if (!this.isReadonly) {
                baseCategoryFields.push({ key: "delete", label: this.$t(""), _classes: "text-dark text-center", _style: "width:5%; vertical-align: middle; text-dark" });
            }

            return baseCategoryFields;
        },

    },
    async created() {
        this.loading = true
        this.setForm()
        await this.getCategory()
        this.setLimitSelectProduct()
        this.setComponentDisplay()
    },
    methods: {
        setForm() {
            const defaultForms = {
                'CATEGORY_FIXED_PRICE': { value: null, categories: [], quantityLimit: null, cap: null },
                'ITEM_FIXED_PRICE': { items: [], quantityLimit: null },
                'ITEM_PERCENTAGE_DISCOUNT': { value: null, items: [], quantityLimit: null, cap: null },
                'ITEM_DOLLAR_DISCOUNT': { value: null, items: [], quantityLimit: null },
                'CATEGORY_PERCENTAGE_DISCOUNT': { value: null, categories: [], quantityLimit: null, cap: null },
                'CATEGORY_DOLLAR_DISCOUNT': { value: null, categories: [], quantityLimit: null },
                'RECEIPT_PERCENTAGE_DISCOUNT': { value: null, cap: null },
                'RECEIPT_DOLLAR_DISCOUNT': { value: null },
                'FREE_ITEM': { product: null, quantity: null },
                'ITEM_X_FREE_Y': { items: [], freeItemQuantity: null, inCartQuantity: null },
                'CATEGORY_X_FREE_Y': { categories: [], freeItemQuantity: null, inCartQuantity: null, },
            };
            const defaultForm = defaultForms[this.promotionType] || {};
            this.results.push(defaultForm);
        },
        addEmptyForm() {
            if (this.isStackAble()) {
                const emptyForms = {
                    'CATEGORY_FIXED_PRICE': { value: null, categories: [], quantityLimit: null, cap: null },
                    'ITEM_FIXED_PRICE': { items: [], quantityLimit: null },
                    'ITEM_PERCENTAGE_DISCOUNT': { value: null, items: [], quantityLimit: null, cap: null },
                    'ITEM_DOLLAR_DISCOUNT': { value: null, items: [], quantityLimit: null },
                    'CATEGORY_PERCENTAGE_DISCOUNT': { value: null, categories: [], quantityLimit: null, cap: null },
                    'CATEGORY_DOLLAR_DISCOUNT': { value: null, categories: [], quantityLimit: null },
                    'FREE_ITEM': { product: null, quantity: null },
                    'ITEM_X_FREE_Y': { items: [], freeItemQuantity: null, inCartQuantity: null },
                    'CATEGORY_X_FREE_Y': { categories: [], freeItemQuantity: null, inCartQuantity: null, },
                };
                const emptyForm = emptyForms[this.promotionType] || {};
                this.results.push(emptyForm);
            }
        },
        removeForm(index) {
            this.results.splice(index, 1)
        },
        isStackAble() {
            const canNotStackType = ['RECEIPT_PERCENTAGE_DISCOUNT', 'RECEIPT_DOLLAR_DISCOUNT', 'ITEM_X_FREE_Y', 'CATEGORY_X_FREE_Y', 'ITEM_FIXED_PRICE'];
            return !canNotStackType.includes(this.promotionType) && !this.isReadonly;
        },
        setLimitSelectProduct() {
            const singleSelectItem = ['FREE_ITEM', 'CATEGORY_X_FREE_Y']
            if (singleSelectItem.includes(this.promotionType)) {
                this.limitSelectProduct = 1
            } else {
                this.limitSelectProduct = 10
            }
        },
        handleSelectedItems(items) {
            const singleSelectItem = ['FREE_ITEM', 'CATEGORY_X_FREE_Y']
            if (!singleSelectItem.includes(this.promotionType)) {
                items.forEach(item => {
                    const existingItem = this.results[this.selectedIndex].items.find(
                        existing => existing.id === item.id
                    );
                    if (!existingItem && this.results[this.selectedIndex]?.items?.length < 10) {
                        this.results[this.selectedIndex].items.push({
                            id: item.id,
                            objectId: item.objectId,
                            name: item.name || item.ProductSKU?.SKUName,
                            price: item.ProductPRU?.exceedUc || item.ProductSKU?.SKUPrice,
                            pluCode: item.PLUCode || '',
                            remoteImagePath: item.remoteImagePath || '',
                            category: item.category || {},
                            indexColor: item.indexColor || '',
                            ProductSKU: item.ProductSKU || {}
                        })
                    }
                });
            } else {
                this.results[this.selectedIndex].product = {
                    id: items[0].id,
                    objectId: items[0].objectId,
                    name: items[0].name,
                }
            }
            this.refreshKey += 1
        },
        openSelectProductModal(index) {
            this.selectedIndex = index
            this.$refs.select_product_component.openModal()
        },
        removeBadgeItem(indexResult, indexItem) {
            this.results[indexResult].items.splice(indexItem, 1)

        },
        removeBadgeCategory(indexResult, indexCategory) {
            this.results[indexResult].categories.splice(indexCategory, 1)
        },
        removeBadgeFreeItem(indexResult) {
            this.results[indexResult].product = null
        },
        async getCategory() {
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            let params = { page: 1, limit: 1000 }
            try {
                const URL = '/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId
                const res = await pos.get(URL, { params })
                this.allCategories = res.data?.data
            } catch (error) {
                console.log(error)
            }
        },
        handleCategoryChange(resultIndex) {
            const existingItem = this.results[resultIndex].categories.find(
                existing => existing.id === this.selectedCategory.id
            );
            if (!existingItem) {
                this.results[resultIndex].categories.push({
                    id: this.selectedCategory.id,
                    objectId: this.selectedCategory.objectId,
                    name: this.selectedCategory.name,
                })
            }
            this.selectedCategory = null
        },
        getResults() {
            this.isCalledFromParent = true
            return this.results
        },
        getItems(index) {
            return this.results[index].items.map((item, index) => ({
                pluCode: item.pluCode,
                remoteImagePath: item.remoteImagePath || '',
                name: item.name || '',
                category: item.category?.name || '',
                price: item.price,
                indexColor: item.indexColor || '',
                index: index + 1 || 0,
            }));
        },
        calculatePercentageDiscount(price, discount, cap) {
            const calculatedDiscount = Math.min(price * discount / 100, cap);
            return this.convertCurrency(price - calculatedDiscount);
        },
        getCategoryItems(index) {
            if (this.promotionType == 'CATEGORY_X_FREE_Y' && this.isReadonly) {
                let data = []
                data.push(this.results[index].category)
                data[0].index = 1
                return data
            } else {
                return this.results[index].categories.map((category, index) => ({
                    name: category.name,
                    id: category.id,
                    index: index + 1,
                }));
            }
        },
        noImgUrl(event) {
            event.target.src = process.env.VUE_APP_NOIMAGE
        },
        validateFixedPriceInput(itemPrice, index) {
            if (this.results[0].items[index].fixedPrice > itemPrice) {
                this.results[0].items[index].fixedPrice = itemPrice
            } else if (this.results[0].items[index].fixedPrice < 0) {
                this.results[0].items[index].fixedPrice = 0
            }
        },
        convertCurrency(number) {
            return util.convertCurrency(number);
        },
        setComponentDisplay() {
            if (this.isReadonly) {
                this.results = this.resultsDisplay
            }
            this.loading = false
        },
        getMaximunPercentageDiscount(cap, limitQuantity) {
            if (typeof limitQuantity !== 'number' || limitQuantity == -1) {
                return '∞'
            } else {
                return cap * limitQuantity
            }
        },
        getFormValidateClass(value) {
            if (this.isCalledFromParent && (!value || value === null || value < 0 || value === '')) {
                return 'form-control is-invalid infinite-form';
            }
            return 'form-control infinite-form';
        },
        getQuantityLimitFormValidateClass(value) {
            return 'form-control infinite-form';
        },
        getFormCapValidateClass(value) {
            if (this.isCalledFromParent && (!value || value === null || value < 0 || value === '')) {
                return 'form-control infinite-form';
            }
            return 'form-control infinite-form';
        },
        validateQuantityLimit(value, index) {
            if (value < 0) {
                this.results[index].quantityLimit = 1
            }
        },
        getQuantityLimitDisplay(value) {
            if (value == -1) {
                return '∞'
            } else {
                return value
            }
        },
        validateResultIntegerInput(index) {
            if (typeof this.results[index].value == 'number') {
                this.results[index].value = parseInt(this.results[index].value)
            }
        },
        getCapDisplay(cap) {
            return cap >= 9999999 ? '∞' : cap;
        }
    },
    watch: {
        resultsDisplay: {
            immediate: true,
            handler(newVal) {
                this.setComponentDisplay()
            },
        },
    },
}
</script>
<style>
.infinite-form::placeholder {
    font-size: 24px;
    transform: translateY(3px);
    display: inline-block;
}
</style>
