<template>
    <div>
        <CModal :show.sync="AddProductModal" centered style="max-width: 915px;" :footer="footer" size="lg">
            <template #header-wrapper class="header-modal">
                <header class="modal-header header-modal">
                    <h4 class="modal-title font-weight-normal">
                        {{ $t('selectdProduct') }}
                    </h4>
                    <button type="button" aria-label="Close" class="close" @click="AddProductModal = false">
                        ×
                    </button>
                </header>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CAlert color="danger" :show.sync="isshowAlert" closeButton>
                        {{ $t('limit') }} {{ limitItem }} {{ $t('item') }}
                    </CAlert>
                    <CRow>
                        <label class="col-sm-1  col-form-label text-left text-dark">
                            {{ $t('search') }}
                        </label>
                        <CCol>
                            <select v-model="searchBy" class="custom-select">
                                <option value="PLUCode">{{ $t('pluCode') }}</option>
                                <option value="name">{{ $t('productName') }}</option>
                                <option value="category">{{ $t('category') }}</option>
                            </select>
                        </CCol>
                        <CCol class="pl-0 col-6">
                            <template v-if="searchBy === 'category'">
                                <select @change="searchProductPLU" v-model="selectedCategory"
                                    class="form-control text-dark">
                                    <option selected disabled value="">{{ $t('selectCategory') }}</option>
                                    <option v-for="category in dataCategory" :key="category.objectId"
                                        :value="category.objectId">
                                        {{ category.name }}
                                    </option>
                                </select>
                            </template>
                            <template v-else>
                                <input class="form-control text-dark" v-model="keywordEntered" @input="searchProductPLU"
                                    :placeholder="$t('searchProduct')" />
                            </template>
                        </CCol>
                        <CCol class="p-0">
                            <CButton block color="success" v-if="loadingButton === true" v-on:click="searchProductPLU">
                                {{ $t('search') }}
                            </CButton>
                        </CCol>
                        <CCol class="pl-1">
                            <CButton class="btn btn-outline-secondary text-dark font-weight-normal"
                                style="color: black; width: inherit" v-on:click="reset">
                                {{ $t('reset') }}
                            </CButton>
                        </CCol>
                    </CRow>
                    <div class="mt-2">
                        <CBadge v-for="(item, index) in selectedItems" :key="'badgeItem' + index" color="info h6 m-1">
                            {{ item.name }}</CBadge>
                    </div>
                    <div style="max-height: 400px; overflow-y: auto;" class="mt-2 text-dark">
                        <CDataTable pagination class="text-dark" :fields="fields" :items="autoCompleteResult" hover
                            v-model="autoCompleteResult" border clickableRows
                            @row-clicked="onSelectedAutoCompleteEvent">
                            <template slot="selected-header">
                            </template>
                            <template #selected="{ item, index }">
                                <td style="vertical-align: middle;">
                                    <div class="custom-control custom-checkbox text-dark" style="padding-left: 2rem">
                                        <input :disabled="selectedItems.length >= limitItem" type="checkbox"
                                            class="custom-control-input success cursor" :checked="item.selected"
                                            @change="onSelectedAutoCompleteEvent(item)" :id="index" />
                                        <label :key="refreshKey" class="custom-control-label cursor" :for="index">
                                        </label>
                                    </div>
                                </td>
                            </template>
                            <template #name="{ item, index }">
                                <td style="vertical-align: middle;border-left: hidden;" :for="index">
                                    <img v-if="item.remoteImagePath" :src="item.remoteImagePath"
                                        style="border-radius: 2px;aspect-ratio: 4 / 3;min-width: 30px;"
                                        class="img-fluid" @error="noImgUrl" />
                                    <div v-else style="border-radius: 2px" :style="{
                                        'aspect-ratio': '4 / 3',
                                        backgroundColor: item.indexColor,
                                    }"></div>
                                </td>
                            </template>
                            <template #nameproduct="{ item }">
                                <td class="text-dark font-weight-normal"
                                    style="vertical-align: middle;border-left: hidden;">
                                    {{ item.name }}
                                </td>
                            </template>
                            <template #categoryName="{ item }">
                                <td class="text-dark font-weight-normal"
                                    style="vertical-align: middle;border-left: hidden;">
                                    {{ item.categoryName }}
                                </td>
                            </template>
                            <template #pluCode="{ item }">
                                <td class="text-dark font-weight-normal"
                                    style="vertical-align: middle;border-left: hidden;">
                                    {{ item.PLUCode }}
                                </td>
                            </template>
                            <template #price="{ item, index }">
                                <td class="text-dark text-right font-weight-normal" :for="index"
                                    style="vertical-align: middle;border-left: hidden ">
                                    {{ currencyFormat(item.ProductPRU?.exceedUc || item.ProductSKU?.SKUPrice) }}

                                </td>
                            </template>
                            <template #onhanQTY="{ item, index }">
                                <td class="text-right" :for="index" style="vertical-align: middle; border-left: hidden"
                                    v-if="
                                        item.ProductPLUStock.onhandQty === '' ||
                                        item.ProductPLUStock.onhandQty === undefined
                                    ">
                                    N/A
                                </td>
                                <td class="text-right" :for="index" style="vertical-align: middle; border-left: hidden"
                                    v-else>
                                    <div v-if="item.ProductPLUStock.onhandQty < 0 && Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                                        class="text-danger">
                                        {{ parseFloat(item.ProductPLUStock.onhandQty) }}
                                    </div>
                                    <div v-else-if="item.ProductPLUStock.onhandQty < 0 && !Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                                        class="text-danger">
                                        {{ parseFloat(item.ProductPLUStock.onhandQty).toFixed(3) }}
                                    </div>
                                    <div v-else-if="!Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                                        class="text-dark">
                                        {{ parseFloat(item.ProductPLUStock.onhandQty).toFixed(3) }}
                                    </div>
                                    <div v-else class="text-dark">
                                        {{ parseFloat(item.ProductPLUStock.onhandQty) }}
                                    </div>
                                </td>
                            </template>
                            <template #no-items-view>
                                <div class="text-center my-5">
                                    <h4 style="color: #ced2d8;">
                                        {{ $t('noItem') }}
                                    </h4>
                                </div>
                            </template>
                        </CDataTable>
                    </div>
                </CCardBody>
            </template>
            <template #footer-wrapper>
                <CRow class="justify-content-end col-md-12">
                    <CCol col="12" lg="6" class="row">
                        <div class="col-6">
                            <CButton class="btn btn-outline-secondary text-dark font-weight-normal"
                                style="color: black; width: inherit" @click="AddProductModal = false">
                                {{ $t('cancel') }}
                            </CButton>
                        </div>
                        <div class="col-6">
                            <CButton block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                                {{ $t('confirm') }}
                            </CButton>
                            <CButton block color="success" v-else-if="loadingButton === false" disabled>
                                <CSpinner color="white" size="sm" /> {{ $t('save') }}
                            </CButton>
                        </div>
                    </CCol>
                </CRow>
                <br />
            </template>
        </CModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/local'
import util from '@/util/util'
import Multiselect from 'vue-multiselect'
import permis from '@/util/permission'
import pos from '@/services/local'
import { CButton } from '@coreui/vue'

export default {
    components: {
        Multiselect,
    },
    props: {
        limitItem: {
            type: Number,
            required: false,
            default: 200
        },
    },
    data() {
        return {
            isSuccess: false,
            alert: false,
            data: [],
            datetime: new Date(),
            shop: this.$store.getters.shopObjectId,
            member: null,
            members: [],
            isVatIncluded: false,
            billNo: '',
            note: '',
            discount: '0',
            searchBy: 'name',
            label: 'name',
            keyword: '',
            placeHolderInputText: 'พิมพ์ชื่อสินค้าที่ต้องการค้นหา .​.​.',
            autoCompleteResult: [],
            autoCompleteProgress: false,
            autoCompleteText: 'name',
            autoCompleteText2: 'PLUCode',
            autoCompleteText3: 'unitName',
            autoCompleteFieldId: 'alpha3Code',
            items: [],
            vatType: '',
            validateShop: '',
            validateItem: '',
            popupModal: false,
            footer: '',
            loadingButton: true,
            duplicateItem: '',
            warning: '',
            permission: false,
            AddProductModal: false,
            AddCheckStockModal: false,
            keywordEntered: '',
            selectedItems: [],
            all: false,
            isshowAlert: 0,
            checkStockItem: [],
            dataCategory: [],
            selectedCategory: '',
            refreshKey: 0,
            CategoryModal: true
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'access', 'permissions']),
        uid() {
            return `${localStorage.getItem('shopsUid')}`
        },
        isPermission() {
            let path = '/inventory/stock-in'
            return permis.findPermissionRead('inventory', path)
        },
        isEditData() {
            let path = '/inventory/stock-in'
            return permis.findPermissionEdit('inventory', path)
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        startDate() {
            return moment(String(this.date.start)).format('DD MMMM YYYY')
        },
        shopOptions() {
            let detail = []
            this.shops.forEach((shop) => {
                detail.push({
                    value: shop,
                    label: shop.shopName + ' - ' + shop.branchName,
                })
            })
            return detail
        },
        memberOptions() {
            let detail = []
            this.members.forEach((member) => {
                detail.push({
                    value: member,
                    label: member.name,
                })
            })
            return detail
        },
        vat() {
            let vat = 0;
            this.items.forEach(item => {
                vat += this.calculateVat(item);
            });

            return vat;
        },
        vatCurrencyString() {
            return util.convertCurrency(this.vat);
        },
        grandTotal() {
            let grandTotal = ''
            let summary = this.netTotal || 0
            let vat = this.vat || 0

            if (this.isVatIncluded === false) {
                grandTotal = summary - this.discount
            } else if (this.isVatIncluded === true) {
                grandTotal = summary + vat - this.discount
            }

            return util.convertCurrency(grandTotal)
        },
        netTotal() {
            let total = 0;
            this.items.forEach(item => {
                total += this.grandTotalItem(item);
            });
            return total;
        },
        netTotalCurrencyString() {
            return util.convertCurrency(this.netTotal)
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
        fields() {
            return [
                {
                    key: 'selected',
                    label: this.$i18n.t('selectd'),
                    _style: 'width:5%; vertical-align: middle; text-dark',
                    _classes: 'text-left font-weight-normal text-dark',
                },
                {
                    key: 'name',
                    label: '',
                    _style: 'width:7%; vertical-align: middle;border-left: hidden text-dark',
                    _classes: 'text-left font-weight-normal text-dark',
                },
                {
                    key: 'nameproduct',
                    label: this.$i18n.t('product'),
                    _style: 'width:30%; vertical-align: middle;border-left: hidden text-dark',
                    _classes: 'text-left font-weight-normal text-dark',
                },
                {
                    key: 'pluCode',
                    label: this.$i18n.t('pluCode'),
                    _style: 'width:25%; vertical-align: middle;border-left: hidden text-dark',
                    _classes: 'text-left font-weight-normal text-dark',
                },
                {
                    key: 'categoryName',
                    label: this.$i18n.t('category'),
                    _style: 'width:23%; vertical-align: middle;  text-dark',
                    _classes: 'text-left font-weight-normal text-dark',
                },
                {
                    key: 'price',
                    label: this.$i18n.t('price'),
                    _style: 'width:23%; vertical-align: middle;  text-dark',
                    _classes: 'text-right font-weight-normal text-dark',
                },

            ]
        },
        memberslist() {
            const member = this.members || []
            let detail = []
            for (let i = 0; i < member.length; i++) {
                const item = member[i]
                const fullName = (item.firstname || item.name || '') + ' ' + (item.lastname || '')
                detail.push({
                    id: item.id,
                    objectId: item.objectId,
                    name: fullName,
                })
            }
            return detail
        },
        checkStockFields() {
            return [
                {
                    key: 'selected',
                    label: this.$i18n.t('selectd'),
                    _style: 'width:10%; vertical-align: middle;',
                    _classes: 'text-center text-dark font-weight-normal',
                },
                {
                    key: 'name',
                    label: this.$i18n.t('checkStockName'),
                    _style: 'width:90%; vertical-align: middle;border-left: hidden',
                    _classes: 'text-left text-dark font-weight-normal',
                },
            ]
        },
    },
    created() {
        this.searchProductPLU()
        this.getCheckStock()
        this.getCategory()
        this.shop = this.shops.find((i) => i.objectId === this.shop)
    },
    methods: {
        ...util,
        getCategory() {
            const uid = `${localStorage.getItem('shopsUid')}`;
            const shopObjectId = this.shopObjectId;
            let params = {
                page: 1,
                limit: 1000,
            };
            const headers = {
                shopObjectId: shopObjectId,
            }

            pos
                .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
                    params,headers
                })
                .then((res) => {
                    let detail = []
                    let data = res.data.data
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].enabled === true) {
                            detail.push({
                                id: data[i].id,
                                name: data[i].name,
                                objectId: data[i].objectId,
                            })
                        }
                    }
                    this.dataCategory = detail
                })
                .catch((error) => {
                    console.error("Error fetching categories:", error);
                });
        },
        getCheckStock(page = 1) {
            this.loadingButton = false
            const uid = this.uid
            const shopObjectId = this.shopObjectId
            // const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
            // const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

            const params = {
                shopObjectId: shopObjectId,
                page: page,
                limit: 50,

            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            axios({
                url: '/api/v2.2/' + uid + '/CheckStockDocument',
                params: params,
                headers: headers,
                method: 'GET',
            })
                .then((res) => {
                    this.data = res.data.data
                    this.data = this.data.filter(item => item.shop.objectId === this.shopObjectId);
                    this.loadingButton = true
                    let checkStockDoc = this.data.map(item => {
                        return { ...item, selected: false };
                    });
                    this.checkStockItem = checkStockDoc
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        noImgUrl(event) {
            event.target.src = process.env.VUE_APP_NOIMAGE
        },
        reset() {
            this.selectedItems.forEach(item => {
                item.selected = false
            })
            this.selectedItems = []
            this.all = false
            this.keywordEntered = ''
            this.searchBy = 'name'
            this.selectedCategory = ''
            this.searchProductPLU()
        },
        handlePopup() {
            if (this.shopObjectId === '') {
                this.popupModal = false
                this.validateShop = this.$i18n.t('selectShopPlease')
            } else {
                this.validateShop = ''
            }

            if (this.items.length === 0) {
                this.popupModal = false
                this.validateItem = this.$i18n.t('selectProductPlease')
            } else {
                this.validateItem = ''
            }

            if (this.items.length != 0 && this.shopObjectId != '') {
                this.popupModal = true
            }
        },
        removeProduct(i) {
            let items = this.items
            let newItems = items.filter((item) => item.orderIndex != i.orderIndex)
            this.items = newItems
            let index = this.autoCompleteResult.findIndex(
                (x) => x.objectId == i.productPLU.objectId
            )
            if (index != -1) {
                this.autoCompleteResult[index].selected = false
            }

            let find = this.selectedItems.findIndex(
                (x) => x.objectId === i.productPLU.objectId
            )
            if (find != -1) {
                this.selectedItems.splice(i, 1)
            }
        },
        totalQuantity(item) {
            let totalQty = 0;

            for (const item of this.items) {
                totalQty += item.quantity;
            }
            return totalQty;
        },
        calculateDiscount(item) {
            const totalQty = this.totalQuantity();
            let discountPerQty = 0;
            if (totalQty !== 0) {
                discountPerQty = this.discount / totalQty;
            } else {
                discountPerQty = 0;
            }

            return discountPerQty
        },
        grandTotalItem(item) {
            let total = 0
            if (item.vatType === 'N') {
                total = item.quantity * item.cost - item.discount
            }
            if (item.vatType === 'V') {
                total = item.quantity * item.cost - item.discount
            } else {
                total = (item.quantity * item.cost) - item.discount;
            }
            return item.total = total
        },
        grandTotalItemDisperQty(item) {
            let totalnonvat = 0;
            let totalincvat = 0;
            let discountPerQty = this.calculateDiscount();
            if (item && item.vatType === 'N') {
                totalnonvat = item.quantity * item.cost - item.discount - (item.quantity * discountPerQty);
                item.total = totalnonvat;
                return totalnonvat
            } else if (item && item.vatType === 'V') {
                totalincvat = item.quantity * item.cost - item.discount - (item.quantity * discountPerQty);
                item.total = totalincvat;
                return totalincvat
            }
            const total = totalnonvat + totalincvat;
        },
        calculateVat(item) {
            let vat = 0;

            if (item.vatType === 'V' && this.isVatIncluded === false) {
                vat = (this.grandTotalItemDisperQty(item) * 7) / 107;
            }
            else if (item.vatType === 'V' && this.isVatIncluded === true) {
                vat = this.grandTotalItemDisperQty(item) * (7 / 100);
            }
            return vat;
        },
        getMember() {
            const uid = this.uid
            const shopObjectId = this.shopObjectId

            const params = {
                shopObjectId: shopObjectId,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            axios({
                url: '/api/v1.0/' + uid + '/getmember',
                params: params,
                headers: headers,
                method: 'GET',
            })
                .then((res) => {
                    this.members = res.data.data.documents
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        onSelectedAutoCompleteEvent(item) {
            let cont = this.selectedItems.length
            if (cont < this.limitItem) {
                item.selected = !item.selected
                if (item.selected) {
                    this.selectedItems.push(item)
                } else {
                    let i = this.selectedItems.findIndex(
                        (x) => x.objectId === item.objectId
                    )
                    if (i != -1) {
                        this.selectedItems.splice(i, 1)
                    }
                }

            } else {
                if (item.selected == true) {
                    let i = this.selectedItems.findIndex(
                        (x) => x.objectId === item.objectId
                    )
                    if (i != -1) {
                        item.selected = !item.selected
                        this.selectedItems.splice(i, 1)
                    }
                } else {
                    this.isshowAlert = 5
                }
            }
        },
        searchProductPLU() {
            if (this.shopObjectId === '') {
                this.validateShop = this.$i18n.t('selectShopPlease')
            } else {
                this.validateShop = ''
            }

            const uid = `${localStorage.getItem('shopsUid')}`
            let params = {}
            if (this.searchBy === 'category' && this.selectedCategory) {
                params = {
                    shopObjectId: this.$store.getters.shopObjectId,
                    categoryObjectId: this.selectedCategory,
                }
            } else if (this.keywordEntered != '') {
                params = {
                    shopObjectId: this.$store.getters.shopObjectId,
                    searchBy: this.searchBy,
                    keyword: this.keywordEntered,
                }
            } else {
                params = {
                    shopObjectId: this.$store.getters.shopObjectId,
                    searchBy: this.searchBy,
                }
            }
            this.label = this.searchBy
            let selectedItems = this.selectedItems
            // if (keywordEntered.length > 0) {
            const headers = { shopObjectId: this.$store.getters.shopObjectId }
            axios({
                url: '/api/v1.0/' + uid + '/getproductpluwithallskutype',
                params: params,
                headers: headers,
                method: 'GET',
            })
                .then((response) => {
                    this.alert = false
                    let newData = []
                    response.data.data.forEach((item) => {
                        if (selectedItems.length > 0) {
                            let i = selectedItems.findIndex(
                                (x) => x.objectId === item.objectId
                            )
                            if (i != -1) {
                                item.selected = true
                            } else {
                                item.selected = false
                            }
                        } else {
                            item.selected = false
                        }
                        const unit = item.unit || {}
                        const category = item.category || {}
                        item.unitName = unit.name || '-'
                        item.categoryName = category.name || '-'
                        item.indexColor = util.generateColor(item.ProductSKU.indexColor)
                        if (item.selected == false) {
                            newData.push(item)
                        }
                    })
                    this.autoCompleteResult = newData
                })
                .catch((e) => {
                    this.alert = true
                    console.log(e)
                })
            // }
        },
        formSubmit(e) {
            this.loadingButton = false
            this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            e.preventDefault()

            const plan = this.users.currentPlan
            this.items.forEach((item) => {
                delete item.remoteImagePath
                delete item.indexColor
            })

            let data = {
                billNo: this.billNo,
                date: new Date(this.datetime).toISOString(),
                discount: util.currencyStringToNumber(this.discount),
                grandTotal: util.currencyStringToNumber(this.grandTotal),
                isVatIncluded: !this.isVatIncluded,
                netTotal: this.netTotal,
                note: this.note,
                shop: {
                    id: this.shop.id,
                    objectId: this.shop.objectId,
                    shopName: this.shop.shopName,
                    branchName: this.shop.branchName,
                },
                shopId: this.shop.id,
                stockDocType: 1,
                vatBase: 7,
                uid: this.uid,
                vat: this.vat,
                items: this.items,
                vatType: this.vatType,

            }

            if (this.member != null) {
                data['customerId'] = this.member.id || ''
                data['customer'] = {
                    id: this.member.id || '',
                    objectId: this.member.objectId || '',
                    name: this.member.name || '',
                }
            }

            const headers = { shopObjectId: this.$store.getters.shopObjectId }
            if (data.shopId != undefined && data.items.length != 0) {
                axios({
                    method: 'post',
                    url: '/api/v1.0/' + data.uid + '/stockdocument/save',
                    params: { shopObjectId: data.shopObjectId, plan: plan },
                    data: data,
                    headers: headers,
                })
                    .then(() => {
                        // setTimeout(() => {
                        //   (this.isSuccess = true),
                        this.isSuccess = true
                        this.$router.push('/inventory/stock-in')
                        // }, 5000);
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        },
        onCheckboxChange() {
            let data = this.selectedItems
            let items = this.items
            let select = []
            let isshowAlert = 0
            data.forEach((item, index) => {
                if (items.length < 200) {
                    let stdCost = 0
                    let SKURatio = 1
                    let totalCost = 0
                    if (item.selected) {
                        select.push(item)
                        if (
                            item.ProductSKU.stdCost === undefined ||
                            item.ProductSKU.stdCost === null
                        ) {
                            stdCost = 0
                        } else {
                            stdCost = item.ProductSKU.stdCost
                        }

                        if (item.SKURatio == undefined) {
                            SKURatio = 1
                        } else {
                            SKURatio = item.SKURatio
                        }

                        totalCost = SKURatio * stdCost
                        let checkCount = item.checkCount;
                        let quantity = checkCount !== undefined ? checkCount : 0;
                        let vatType = item.ProductSKU.vatType
                        if (items.length > 0) {
                            let i = items.findIndex(
                                (x) => x.productPLU.objectId === item.objectId
                            )
                            if (i == -1) {
                                items.push({
                                    cost: totalCost,
                                    discount: 0,
                                    orderIndex: index,
                                    productPLU: {
                                        id: item.id,
                                        objectId: item.objectId,
                                        PLUCode: item.PLUCode,
                                        name: item.name,
                                        unit: item.unit,
                                        SKURatio: SKURatio,
                                    },
                                    productPLUId: item.id,
                                    quantity: quantity,
                                    total: 0,
                                    productSKUObjectId: item.ProductSKU.objectId,
                                    productSKU: item.ProductSKU || {},
                                    remoteImagePath: item.remoteImagePath,
                                    indexColor: util.generateColor(item.indexColor),
                                    vatType: vatType,
                                })
                            }
                        } else {
                            items.push({
                                cost: totalCost,
                                discount: 0,
                                orderIndex: index,
                                productPLU: {
                                    id: item.id,
                                    objectId: item.objectId,
                                    PLUCode: item.PLUCode,
                                    name: item.name,
                                    unit: item.unit,
                                    SKURatio: SKURatio,
                                },
                                productPLUId: item.id,
                                quantity: quantity,
                                total: 0,
                                productSKUObjectId: item.ProductSKU.objectId,
                                productSKU: item.ProductSKU || {},
                                remoteImagePath: item.remoteImagePath,
                                indexColor: util.generateColor(item.indexColor),
                                vatType: vatType
                            })
                        }
                    } else {
                        let index = items.findIndex(
                            (x) => x.productPLU.objectId === item.objectId
                        )
                        if (index != -1) {
                            items.splice(index, 1)
                        }
                    }
                } else {
                    isshowAlert = 5
                }
            })
            this.isshowAlert = isshowAlert
            this.items = items
            this.selectedItems = select
            this.AddProductModal = false
            this.AddCheckStockModal = false
            this.all = false
            this.$emit('update-selected-items', this.selectedItems)
        },

        currencyFormat(number) {
            return util.convertCurrency(number)
        },
        floatValue(value) {
            return parseFloat(value)
        },
        openModal() {
            this.AddProductModal = true
            this.reset()
            this.selectedItems = []
        },
        closeModal() {
            this.AddProductModal = false
            this.reset()
            this.selectedItems = []
        },
    },
}
</script>
<style scoped>
.multiselect__tags {
    min-height: 35px;
    padding: 0px 40px 0 8px;
}

.multiselect__placeholder {
    margin-top: 6px;
    margin-bottom: 7px;
}

.multiselect__single {
    margin-top: 6px;
    padding-left: 5px;
    margin-bottom: 8px;
}

.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.category-card {
    width: 150px;
    aspect-ratio: 1/1.2;
    border: 1px solid #dddddd;
    border-radius: 5px;
}
</style>
