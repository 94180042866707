<template>
    <div v-if="!loading" class="w-100 text-dark">
        <div class=" mt-2 row p-4">
            <div class=" text-dark w-100 ">
                <div class="promotion-form mt-2">
                    <h5>
                        {{ $t('promotionCoverImg') }}
                    </h5>
                    <span style="color: gray;">
                        {{ $t('promotionCoverImgDesp') }}
                    </span>
                    <div class="img-inputbox mt-2  position-relative">
                        <div v-if="promotion.details.remoteImagePath !== ''">
                            <div v-if="!disabledForm()" @click=" clearImg()"
                                class="delete-icon-container position-absolute">
                                <CBadge style="background-color: white;">
                                    <i class="fi fi-rr-trash-xmark"></i>
                                </CBadge>
                            </div>
                            <img v-if="promotion.details.remoteImagePath !== ''"
                                :src="promotion.details.remoteImagePath" style="width: 100%;object-fit: cover;" />
                        </div>
                        <div class="w-100 h-100 d-flex justify-content-center align-items-center"
                            @click="toggleImageInput()" v-else>
                            <div v-if="!disabledForm()" class="pt-1">
                                <i class="fi fi-rr-plus-small h1  p-0"></i>
                                <input ref="input_img" style="visibility: hidden;display: none;" type="file"
                                    @change="onFileChange" accept="image/*" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="promotion-form mt-5">
                    <div class="d-flex">
                        <h5>
                            <!-- <span class="text-danger">*</span>  -->
                            {{ $t('promotionName') }}
                        </h5>
                    </div>
                    <div>
                        <input maxlength="200" :readonly="disabledForm()" :placeholder="$t('promotionNameDesp')"
                            class="form-control" v-model="promotion.details.title" />
                    </div>
                </div>
                <div class="promotion-form mt-4">
                    <h5>
                        <!-- <span class="text-danger">*</span>  -->
                        {{ $t('conditionInput') }}
                    </h5>
                    <div>
                        <textarea maxlength="1000" :readonly="disabledForm()" :placeholder="$t('conditionInputDesp')"
                            class="form-control" v-model="promotion.details.description" />
                    </div>
                </div>
                <div class="promotion-form mt-5 mb-4">
                    <div>
                        <h5>
                            <!-- <span class="text-danger">*</span>  -->
                            {{ $t('promotionDuration') }}
                        </h5>
                        <span style="color: gray;">
                            {{ $t('promotionDurationDesp') }}
                        </span>
                        <span v-if="isGrabTemplate" style="color: gray;">
                            {{ $t('grabPromotionDurationDesp') }}
                        </span>
                    </div>
                    <div class="row mt-2" style="gap: 10px;">
                        <div class="d-flex col-12">
                            <span style="width: 50px;" class="align-self-center mr-2">{{ $t('startAt') }}</span>
                            <VueDatePicker :disabled="disabledForm()"
                                style="max-width: 200px;margin-right: 1cap;display: inline-table;"
                                :min-date="new Date()" :max-date="maxDate" :class="getFormValidateClass(startDate)"
                                color="#29B46B" :placeholder="$t('startAt')" :locale="getDateLocale()"
                                :visible-years-number="90" v-model="startDate" format="DD-MM-YYYY" />
                            <vue-timepicker :disabled="disabledForm()" v-model="startTime" hide-clear-button
                                :input-class="getTimeFormValidateClass(startTime)">
                            </vue-timepicker>
                        </div>
                        <div class="d-flex col-12">
                            <span style="width: 50px;" class="align-self-center mr-2">{{ $t('endAt') }}</span>
                            <VueDatePicker :disabled="disabledForm()"
                                style="max-width: 200px;margin-right: 1cap;display: inline-table;"
                                :min-date="new Date()" :max-date="maxDate" :class="getFormValidateClass(endDate)"
                                color="#29B46B" :placeholder="$t('endAt')" :locale="getDateLocale()"
                                :visible-years-number="90" v-model="endDate" format="DD-MM-YYYY" />
                            <vue-timepicker :disabled="disabledForm()" v-model="endTime" hide-clear-button
                                :input-class="getTimeFormValidateClass(endTime)"></vue-timepicker>
                        </div>
                    </div>
                </div>
                <div class="promotion-form mt-5">
                    <h5>
                        <!-- <span class="text-danger">*</span> -->
                        {{ $t('promotionPriority') }}
                    </h5>
                    <span style="color: gray;">
                        {{ $t('promotionPriorityDesp') }}
                    </span>
                    <div style="position: relative;">
                        <button :disabled="disabledForm()" style="position: relative;" @click="setPriority(priority)"
                            v-for="priority in 3" :class="getPriorityButtonClass(priority)"
                            :key="'protity_btn' + priority">
                            {{ $t(getPriorityName(priority)) }}
                        </button>
                    </div>

                </div>
                <div class="promotion-form mt-5">
                    <h5>
                        <!-- <span class="text-danger">*</span>  -->
                        {{ $t('promotionScope') }}
                    </h5>
                    <span style="color: gray;">
                        {{ $t('promotionScopeDesp') }}
                    </span><br>
                    <button :disabled="disabledForm()" @click="setScope(scope)" v-for="(scope, index) in scopeOptions"
                        :class="getScopeButtonClass(scope)" :key="'scope_btn' + index">
                        <template v-if="scope == 'GRAB'">
                            <div class="pl-4 pr-4"> <img width="65px" :src="grabIcon(scope)"></div>
                        </template>
                        <template v-else>
                            <i :class="getScopeIcon(scope)"></i> {{ $t(scope) }}
                        </template>

                    </button>
                </div>

                <hr />
                <div class="promotion-form d-flex justify-content-between mt-4">
                    <div>
                        <h5 class="d-flex align-items-center">
                            {{ $t('automaticPromotion') }}
                        </h5>
                        <span style="color: gray;">
                            {{ $t('automaticPromotionDesp') }}
                        </span>
                    </div>
                    <div>
                        <CSwitch :disabled="disabledForm()" class="ml-2" color="success"
                            :checked.sync="promotion.isAutoApply" />
                    </div>
                </div>

                <div v-if="!promotion.isAutoApply" class="promotion-form d-flex justify-content-between mt-5">
                    <div>
                        <h5 class="d-flex align-items-center">
                            {{ $t('useReferenceCode') }}
                        </h5>
                        <span style="color: gray;">
                            {{ $t('useReferenceCodeDesp') }}
                        </span>
                    </div>
                    <div>
                        <CSwitch :disabled="disabledForm()" class="ml-2" color="success" :checked.sync="isHasRef" />
                    </div>
                </div>
                <div v-if="isHasRef && !promotion.isAutoApply" class="input-group mt-2 mb-3">
                    <span class="input-group-text">{{ $t('defineRefCode') }}</span>
                    <input :readonly="disabledForm()" v-model="promotion.hasRef" type="text"
                        :class="getFormValidateClass(promotion.hasRef)">
                </div>

                <div v-if="isGrabTemplate" class="promotion-form d-flex justify-content-between mt-5">
                    <div>
                        <h5 class="d-flex align-items-center">
                            <span class="mr-2">{{ $t('newCustomerOnly') }} </span><img v-if="isGrabTemplate"
                                :key="'tooltip1' + refreshKey" v-c-tooltip="$t('defineForGrabCampagin')"
                                src="/img/grabFood.svg" width="60px">
                        </h5>
                        <span style="color: gray;">
                            {{
                                $t('newCustomerOnlyDesp')
                            }}
                        </span><br>
                        <span class="text-danger">* {{ $t('forGrabCampagin') }}</span>
                    </div>
                    <div>
                        <CSwitch :disabled="disabledForm()" class="ml-2" color="success"
                            :checked.sync="isNewEaterType" />
                    </div>
                </div>

                <div class="promotion-form d-flex justify-content-between mt-5">
                    <div>
                        <h5 class="d-flex align-items-center">
                            <span class="mr-2">{{ $t('promotionUsageLimit') }} </span> <img v-if="isGrabTemplate"
                                :key="'tooltip2' + refreshKey" v-c-tooltip="$t('defineForGrabCampagin')"
                                src="/img/grabFood.svg" class="" width="60px">
                        </h5>
                        <span style="color: gray;">
                            {{ $t('promotionUsageLimitDesp') }}
                        </span>
                    </div>
                    <div>
                        <CSwitch :disabled="disabledForm()" class="ml-2" color="success" :checked.sync="isHasQuota" />
                    </div>
                </div>
                <div v-if="isHasQuota" class="input-group mt-2 mb-3">
                    <span class="input-group-text">{{ $t('limitQuota') }}</span>
                    <input :readonly="disabledForm()" @input=validateQuota()
                        v-model.number="promotion.quotas.totalCount" type="number"
                        :class="getFormValidateClass(promotion.quotas.totalCount)">
                    <span class="input-group-text input-icon-right">{{ $t('receiptRenew') }}</span>
                </div>
                <span v-if="isHasQuota && promotion.usageCount">* {{ $t('promotionApplied') }} {{ promotion.usageCount
                    }} {{
                        $t('usedPromotionBadgeDesp') }}
                </span>

                <div v-if="isGrabTemplate" class="promotion-form d-flex justify-content-between mt-5">
                    <div>
                        <h5 class="d-flex align-items-center">
                            <span class="mr-2">{{ $t('promotionUsageLimitPerCustomer') }}</span> <img
                                v-if="isGrabTemplate" :key="'tooltip3' + refreshKey"
                                v-c-tooltip="$t('defineForGrabCampagin')" src="/img/grabFood.svg" class="" width="60px">
                        </h5>
                        <span style="color: gray;">
                            {{ $t('promotionUsageLimitDespPerCustomer') }}
                        </span><br>
                        <span class="text-danger">* {{ $t('forGrabCampagin') }}</span>
                    </div>
                    <div>
                        <CSwitch :disabled="disabledForm()" class="ml-2" color="success"
                            :checked.sync="isHasQuotaPerMember" />
                    </div>
                </div>
                <div v-if="isHasQuotaPerMember" class="input-group mt-2 mb-3">
                    <span class="input-group-text">{{ $t('limitQuota') }}</span>
                    <input :readonly="disabledForm()" @input=validateQuota()
                        v-model.number="promotion.quotas.totalCountPerUser" type="number"
                        :class="getFormValidateClass(promotion.quotas.totalCountPerUser)">
                    <span class="input-group-text input-icon-right">{{ $t('timePerUser') }}</span>
                </div>


                <div class="promotion-form d-flex justify-content-between mt-5">
                    <div>
                        <h5 class="d-flex align-items-center">
                            {{ $t('canCombineWithOtherPromotions') }}
                        </h5>
                        <span style="color: gray;">
                            {{ $t('canCombineWithOtherPromotionsDesp') }}
                        </span>
                    </div>
                    <div>
                        <CSwitch :disabled="disabledForm()" class="ml-2" color="success"
                            :checked.sync="promotion.isStackable" />
                    </div>
                </div>

                <div class="mt-5">
                    <div class=" promotion-form">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5 class="font-weight-normal text-dark">
                                    <span class="mr-2"> {{
                                        $t('promotionUsageDaysLimit')
                                    }} </span>
                                    <img v-if="isGrabTemplate" src="/img/grabFood.svg" :key="'tooltip4' + refreshKey"
                                        v-c-tooltip="$t('defineForGrabCampagin')" class="" width="60px">
                                </h5>
                                <span style="color: gray;" class="text-description">{{
                                    $t('promotionUsageDaysLimitDesp')
                                }}</span>
                            </div>
                            <div>
                                <CSwitch :disabled="disabledForm()" class="ml-2" color="success"
                                    :checked.sync="isHasSpecificDay" />
                            </div>
                        </div>
                        <div v-if="isHasSpecificDay">
                            <div class="d-flex mt-3">
                                <CButton class="mb-2" color="secondary" @click="applyToAllDays()">{{
                                    $t('applyToAllDay')
                                }}
                                </CButton>
                            </div>
                            <table style="width: 100%;border: 1px solid #ebedef;">
                                <thead class="time-picker-header">
                                    <tr>
                                        <th class="pl-3"></th>
                                        <th>{{ $t('startAt') }}</th>
                                        <th>{{ $t('endAt') }}</th>
                                        <th class="text-center">{{ $t('active') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(day, key) in days" :key="key" class="row-padding">
                                        <td class="pl-3">{{ getDays(day.name) }}</td>
                                        <td>
                                            <vue-timepicker hide-clear-button input-class="form-control mt-1 mb-1"
                                                v-model="day.openTime"
                                                :disabled="!isEnabledDays(day.isActive)"></vue-timepicker>
                                        </td>
                                        <td>
                                            <vue-timepicker hide-clear-button input-class="form-control mt-1 mb-1"
                                                v-model="day.closeTime"
                                                :disabled="!isEnabledDays(day.isActive)"></vue-timepicker>
                                        </td>
                                        <td @click="day.isActive = !day.isActive" class="text-center">
                                            <input :disabled="disabledForm()" style="accent-color: var(--success);"
                                                type="checkbox" v-model="day.isActive" class="mt-1 mb-1" />
                                        </td>
                                    </tr>
                                    <br>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CModal :show.sync="alertModal" centered :title="$t('invalidInput')" color="danger" header="false">
            <div class="text-center p-3">
                <h5 style="line-height: 180%;">{{ $t(errorMessage.title) }}</h5>
                <span style="color: gray">{{ $t(errorMessage.description) }}</span>
            </div>
            <template #footer>
                <div class="w-100 d-flex justify-content-center">
                    <CButton style="width: 100%;margin-left: 1cap;" @click="alertModal = false" color="light">
                        <CSpinner v-if="loading" color="white" size="sm" />
                        <b>{{ $t("agreed") }}</b>
                    </CButton>
                </div>
            </template>
        </CModal>
        <CModal :show.sync="grabInfoModal" centered :title="$t(getAlertModaHeader())" color="warning" header="false">
            <div v-if="isGrabPromotionAllow" class="text-left p-2">
                <span v-html="$t('createPromotionGrabInfoDesp')" />
                <ol class="p-3">
                    <li class="mb-2">{{ $t('grabPromotionGuidelines.guideline1') }}</li>
                    <li class="mb-2">{{ $t('grabPromotionGuidelines.guideline2') }}</li>
                    <li class="mb-2">{{ $t('grabPromotionGuidelines.guideline3') }}</li>
                    <li class="mb-2 text-danger">{{ $t('grabPromotionGuidelines.guideline4') }}</li>
                </ol>
            </div>
            <div v-else class="text-left p-2">
                <span v-html="$t('createPromotionInfoDesp')" />
                <ol class="p-3">
                    <li class="mb-2">{{ $t('promotionGuidelines.guideline1') }}</li>
                    <li class="mb-2">{{ $t('promotionGuidelines.guideline2') }}</li>
                </ol>
            </div>

            <template #footer>
                <div class="w-100 d-flex justify-content-center">
                    <CButton style="width: 100%;margin-right: 1cap;" @click="grabInfoModal = false" color="light">
                        <b>{{ $t("agreed") }}</b>
                    </CButton>
                </div>
            </template>
        </CModal>
        <!-- <div>
            <div class="json-display">
                <pre>{{ prettyJson(getDetails()) }}</pre>
            </div>
        </div> -->
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import '@/assets/styles/promotion.css'
import 'vue2-datepicker/index.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueTimepicker from 'vue2-timepicker'
import moment from 'moment'
import pos from '@/services/local';

export default {
    components: {
        VueTimepicker
    },
    props: {
        promotion: {
            type: Object,
            required: false,
            default: () => ({
                details: {
                    title: '',
                    description: '',
                    remoteImagePath: ''
                },
                startDate: null,
                endDate: null,
                priority: null,
                isAutoApply: false,
                scopes: [],
                isSpecificDay: 'NO_SPECIFIC_DAY',
                hasRef: 'NO_REF',
                isStackable: false,
                quotas: {
                    totalCount: -1,
                    totalCountPerUser: -1,
                },
                eaterType: 'all',
            }),
        },
        isEmptyForm: {
            type: Boolean,
            required: false,
            default: false,
        },
        isGrabPromotionAllow: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            isHasQuota: false,
            isHasQuotaPerMember: false,
            isHasRef: false,
            isHasSpecificDay: false,
            startDate: null,
            endDate: null,
            isCalledFromParent: false,
            isNewEaterType: false,
            days: {
                Mon: { index: 0, name: "Monday", openTime: "09:00", closeTime: "17:00", isActive: true },
                Tue: { index: 1, name: "Tuesday", openTime: "09:00", closeTime: "17:00", isActive: true },
                Wed: { index: 2, name: "Wednesday", openTime: "09:00", closeTime: "17:00", isActive: true },
                Thu: { index: 3, name: "Thursday", openTime: "09:00", closeTime: "17:00", isActive: true },
                Fri: { index: 4, name: "Friday", openTime: "09:00", closeTime: "17:00", isActive: true },
                Sat: { index: 5, name: "Saturday", openTime: "09:00", closeTime: "17:00", isActive: true },
                Sun: { index: 6, name: "Sunday", openTime: "09:00", closeTime: "17:00", isActive: true }
            },
            orderShopSetting: {},
            grabInfoModal: false,
            startTime: null,
            endTime: null,
            refreshKey: 0,
            isEditStartDate: false,
            oldStartDate: null,
            alertModal: false,
            errorMessage: {
                title: '',
                description: '',
            },
            suggestTitle: '',
            suggestDescription: '',
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        isPermission() {
            // return permis.findPermissionRead('inventory', this.$route.path)
            return true;
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId;
            },
        },
        uid() {
            return localStorage.getItem('uid');
        },
        isGrabTemplate() {
            if (this.isGrabPromotionAllow) {
                return true
            } else {
                return this.isEmptyForm && this.orderShopSetting?.isGrabOrderEnabled && this.isGrabPromotionAllow
            }
        },
        scopeOptions() {
            if (this.isEmptyForm) {
                if (this.orderShopSetting?.isGrabOrderEnabled && this.isGrabPromotionAllow) {
                    return ['GRAB', 'DINE_IN', 'PICK_UP', 'TAKE_AWAY', 'DELIVERY'];
                } else {
                    return ['DINE_IN', 'PICK_UP', 'TAKE_AWAY', 'DELIVERY'];
                }
            } else {
                if (this.orderShopSetting?.isGrabOrderEnabled && this.isGrabPromotionAllow && this.editableGrabPromotion) {
                    return ['GRAB', 'DINE_IN', 'PICK_UP', 'TAKE_AWAY', 'DELIVERY'];
                } else {
                    return ['DINE_IN', 'PICK_UP', 'TAKE_AWAY', 'DELIVERY'];
                }
            }
        },
        editableGrabPromotion() {
            return !(this.isEmptyForm || !this.promotion?.scopes?.includes('GRAB'));
        },
        maxDate() {
            const today = new Date();
            today.setMonth(today.getMonth() + 2); // Add 2 months to the current date
            return today;
        },
    },
    created() {
        this.getOrderShopSetting()
    },
    methods: {
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        // Set canvas dimensions to the desired size
                        canvas.width = 1280;
                        canvas.height = 720;
                        ctx.drawImage(img, 0, 0, 1280, 720);

                        // Get the resized image as a Base64 string
                        this.promotion.details.remoteImagePath = canvas.toDataURL('image/jpeg'); // Set to Base64
                    };
                };
                reader.readAsDataURL(file);
            }
        },
        getDateLocale() {
            if (this.$i18n.locale == 'en') {
                return { lang: 'en' }
            } else {
                return { lang: 'de' }
            }
        },
        setPriority(i) {

            this.promotion.priority = i

        },
        getPriorityButtonClass(i) {
            if (this.isCalledFromParent && this.promotion.priority == null) {
                return 'priority-btn invalid-border mt-2'
            }

            if (i == this.promotion.priority) {
                return ' priority-btn-selected mt-2'
            } else {
                return 'priority-btn mt-2'
            }
        },
        setScope(scope) {

            if (scope === 'GRAB') {
                if (!this.promotion.scopes.includes(scope)) {
                    this.grabInfoModal = true;
                } else if (!this.isEmptyForm) {
                    this.grabInfoModal = true;
                    return;
                }
            }

            this.promotion.scopes = this.promotion.scopes.includes(scope)
                ? this.promotion.scopes.filter(item => item !== scope)
                : [...this.promotion.scopes, scope];

        },
        getScopeButtonClass(scope) {
            if (this.isCalledFromParent && this.promotion.scopes?.length == 0) {
                return 'scope-btn invalid-border mt-2'
            }

            if (this.promotion.scopes?.includes(scope)) {
                return 'scope-btn-selected mt-2'
            } else {
                return 'scope-btn mt-2'
            }
        },
        applyToAllDays() {
            if (this.disabledForm() == false) {
                const openTime = this.days.Mon.openTime;
                const closeTime = this.days.Mon.closeTime;
                for (let key in this.days) {
                    this.days[key].openTime = openTime;
                    this.days[key].closeTime = closeTime;
                }
            }
        },
        getDays(day) {
            return this.$i18n.t(day.toLowerCase())
        },

        prettyJson(jsonData) {
            return JSON.stringify(jsonData, null, 2);
        },

        clearImg() {
            this.promotion.details.remoteImagePath = ''
        },
        toggleImageInput() {
            this.$refs.input_img.click()
        },
        validateQuota() {
            if (this.isHasQuota) {
                if (this.promotion.quotas.totalCount < 0 || isNaN(this.promotion.quotas.totalCount)) {
                    this.promotion.quotas.totalCount = 1;
                }
                this.promotion.quotas.totalCount = parseInt(this.promotion.quotas.totalCount);
            }
            if (this.isHasQuotaPerMember) {
                if (this.promotion.quotas.totalCountPerUser < 0 || isNaN(this.promotion.quotas.totalCountPerUser)) {
                    this.promotion.quotas.totalCountPerUser = 1;
                }
                this.promotion.quotas.totalCountPerUser = parseInt(this.promotion.quotas.totalCountPerUser);
                if (this.isHasQuota && this.promotion.quotas.totalCountPerUser > this.promotion.quotas.totalCount) {
                    this.promotion.quotas.totalCountPerUser = this.promotion.quotas.totalCount;
                }
            }
        },
        setForm() {
            if (!this.isEmptyForm) {
                this.isHasRef = this.promotion.hasRef !== 'NO_REF';
                this.isHasQuota = this.promotion.quotas?.totalCount !== -1;
                this.isHasQuotaPerMember = this.promotion.quotas?.totalCountPerUser !== -1;
                this.isHasSpecificDay = this.promotion.isSpecificDay !== 'NO_SPECIFIC_DAY';
                this.isNewEaterType = this.promotion.eaterType == 'new';
                this.oldStartDate = this.promotion.startDate

                if (this.isHasSpecificDay == true) {
                    this.days = this.promotion.isSpecificDay
                }

                // Convert ISO startDate and endDate to match VueDatePicker and vue-timepicker formats
                if (this.promotion.startDate) {
                    const startDateTime = moment(this.promotion.startDate);
                    this.startDate = startDateTime.format('YYYY-MM-DD'); // For VueDatePicker
                    this.startTime = startDateTime.format('HH:mm'); // For vue-timepicker
                }
                if (this.promotion.endDate) {
                    const endDateTime = moment(this.promotion.endDate);
                    this.endDate = endDateTime.format('YYYY-MM-DD');  // For VueDatePicker
                    this.endTime = endDateTime.format('HH:mm'); // For vue-timepicker
                }
            } else {
                // Handle the case when the form is empty
                const now = new Date();
                const startDateTime = new Date(now.getTime() + 20 * 60 * 1000); // Current time + 1 minute in ICT
                const endDateTime = new Date(now.getTime() + 59 * 24 * 60 * 60 * 1000); // Current time + 59 days in ICT

                // Set startDate and startTime
                this.startDate = startDateTime.toISOString().split('T')[0]; // For VueDatePicker
                this.startTime = startDateTime.toTimeString().slice(0, 5); // For vue-timepicker

                // Set endDate and endTime
                this.endDate = endDateTime.toISOString().split('T')[0]; // For VueDatePicker
                this.endTime = endDateTime.toTimeString().slice(0, 5); // For vue-timepicker

                this.combineStartDateTime()
                this.combineEndDateTime()
                this.setPriority(2)
                this.setScope('DINE_IN')
            }
        },
        getDetails() {
            this.validateQuota()
            this.isCalledFromParent = true
            const { type, condition, results, ...filteredPromotion } = this.promotion;
            return filteredPromotion;
        },
        getFormValidateClass(value) {
            if (this.isCalledFromParent && (value === null || value === 0 || value === '')) {
                return 'form-control is-invalid';
            }
            return 'form-control';
        },
        getTimeFormValidateClass(value) {
            if (this.isCalledFromParent && (value === null || value === 0 || value === '')) {
                return 'form-control h-100 is-invalid';
            }
            return 'form-control h-100';
        },
        grabIcon(scope) {
            if (this.promotion.scopes?.includes(scope)) {
                return '/img/grabFoodWhite.svg'
            } else {
                return '/img/grabFood.svg'
            }
        },
        getOrderShopSetting() {
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                this.orderShopSetting = res?.data?.data[0] || null
            })

        },
        combineStartDateTime() {
            if (this.startDate && this.startTime) {
                let hour, minute;

                // Check if startTime is an object or a string
                if (typeof this.startTime === 'object' && this.startTime.HH && this.startTime.mm) {
                    hour = parseInt(this.startTime.HH, 10);
                    minute = parseInt(this.startTime.mm, 10);
                } else if (typeof this.startTime === 'string') {
                    const [parsedHour, parsedMinute] = this.startTime.split(':').map(Number);
                    hour = parsedHour;
                    minute = parsedMinute;
                } else {
                    // console.error('Invalid startTime format:', this.startTime);
                    return; // Exit if format is invalid
                }

                const combinedDateTime = moment(this.startDate)
                    .set({ hour, minute, second: 0, millisecond: 0 })
                    .toISOString();

                this.promotion.startDate = combinedDateTime;
                this.startDate = combinedDateTime; // Update startDate to the combined value
            }
        },

        combineEndDateTime() {
            if (this.endDate && this.endTime) {
                let hour, minute;

                // Check if endTime is an object or a string
                if (typeof this.endTime === 'object' && this.endTime.HH && this.endTime.mm) {
                    hour = parseInt(this.endTime.HH, 10);
                    minute = parseInt(this.endTime.mm, 10);
                } else if (typeof this.endTime === 'string') {
                    const [parsedHour, parsedMinute] = this.endTime.split(':').map(Number);
                    hour = parsedHour;
                    minute = parsedMinute;
                } else {
                    // console.error('Invalid endTime format:', this.endTime);
                    return; // Exit if format is invalid
                }

                const combinedEndDateTime = moment(this.endDate)
                    .set({ hour, minute, second: 0, millisecond: 0 })
                    .toISOString();

                this.promotion.endDate = combinedEndDateTime;
                this.endDate = combinedEndDateTime; // Update endDate to the combined value
            }
        },
        disabledForm() {
            if (!this.isEditStartDate) {
                if (!this.isEmptyForm) {
                    const currentDate = new Date();
                    const startDate = this.promotion.startDate ? new Date(this.promotion.startDate) : null;

                    if (startDate) {
                        const oneHourBefore = new Date(startDate.getTime() - 60 * 60 * 1000); // Subtract one hour
                        return currentDate >= oneHourBefore;
                    }
                }
                return false;
            } else {
                if (!this.isEmptyForm) {
                    const currentDateMinusOneHour = new Date(new Date().getTime() - 60 * 60 * 1000);
                    if (this.promotion.startDate) {
                        const startDate = new Date(this.promotion.startDate);
                        if (startDate < currentDateMinusOneHour) {
                            this.promotion.startDate = this.oldStartDate
                            this.showAlertModal('invalidDate', 'invalidDateDesp')
                        }
                    }
                    return false
                }
            }
        },
        isEnabledDays(dayActive) {
            if (this.disabledForm() == true) {
                return false
            } else {
                return dayActive

            }
        },
        toggleGrabInfoModal() {
            this.grabInfoModal = true
        },
        showAlertModal(title, description) {
            this.errorMessage.title = title
            this.errorMessage.description = description
            this.alertModal = true
        },
        getAlertModaHeader() {
            if (this.isGrabPromotionAllow) {
                return 'createPromotionGrabInfoHeader'
            } else {
                return 'createPromotionInfoHeader'
            }
        },
        getScopeIcon(scope) {
            switch (scope) {
                case 'DINE_IN':
                    return 'fi fi-rr-utensils'
                case 'PICK_UP':
                    return 'fi fi-rr-picnic'
                case 'TAKE_AWAY':
                    return 'fi fi-rr-house-chimney-heart'
                case 'DELIVERY':
                    return 'fi fi-rr-biking-mountain'

                default:
                    return ''
            }
        },
        getPriorityName(i) {
            switch (i) {
                case 1:
                    return 'high'
                case 2:
                    return 'medium'
                case 3:
                    return 'low'
                default:
                    return ''
            }
        },
    },
    watch: {
        promotion: {
            immediate: true,
            handler(newVal) {
                this.setForm()
            },
        },
        isHasRef(newValue) {
            if (newValue == false) {
                this.promotion.hasRef = 'NO_REF'
            } else {
                this.promotion.hasRef = ''
            }
        },
        isHasQuota(newValue) {
            if (newValue == false) {
                this.promotion.quotas.totalCount = -1
            } else {
                this.promotion.quotas.totalCount = 1
            }
        },
        isHasQuotaPerMember(newValue) {
            if (newValue == false) {
                this.promotion.quotas.totalCountPerUser = -1
            } else {
                this.promotion.quotas.totalCountPerUser = 1
            }
        },
        isHasSpecificDay(newValue) {
            if (newValue == false) {
                this.promotion.isSpecificDay = 'NO_SPECIFIC_DAY'
            } else {
                this.promotion.isSpecificDay = this.days
            }
        },
        startDate(newDate) {
            if (newDate && this.startTime) {
                this.combineStartDateTime();
            }
        },
        startTime(newTime) {
            if (newTime && this.startDate) {
                this.isEditStartDate = true
                this.combineStartDateTime();
            }
        },
        endDate(newDate) {
            if (newDate && this.endTime) {
                this.combineEndDateTime();
            }
        },
        endTime(newTime) {
            if (newTime && this.endDate) {
                this.combineEndDateTime();
            }
        },

        isNewEaterType(newValue) {
            if (newValue == true) {
                this.promotion.eaterType = 'new'
            } else {
                this.promotion.eaterType = 'all'
            }
        },
        '$i18n.locale'(newVal, oldVal) {
            this.refreshKey += 1
        }
    },

}
</script>
<style>
.input-icon-right {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-right: 0.25rem;
    border: 1px solid #ced4da;
}

.invalid-border {
    border: 1px solid #ee3e37 !important;
}
</style>