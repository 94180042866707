import pos from '../services/local'
import i18n from '@/plugins/i18n'

const state = {
  shopsetting: [],
  allPOS: [],
  shopOperatingHours: {},
  shopLocation: {},
  permissionSetting: [],
}

const mutations = {
  GET_SHOPSETTING(state, shopsetting) {
    state.shopsetting = shopsetting
  },
  GET_ALL_POS(state, allPOS) {
    state.allPOS = allPOS
  },
  GET_PERMISSION_SETTING(state, permissionSetting) {
    state.permissionSetting = permissionSetting
  },
  SET_SHOPSETTING(state, subset) {
    state.shopsetting = subset
  },
  SET_SHOP_OPENING_HOURS(state, subset) {
    state.shopOperatingHours = subset
  },
}

const actions = {
  getAllPOS({ commit }) {
    this.loadingButton = false
    const uid = `${localStorage.getItem('uid')}`
    let shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const params = { shopObjectId: shopObjectId }

    pos
      .get('/api/v1.0/' + uid + '/Shop/pos/all', { params })
      .then((res) => {
        const allPOS = res.data.data
        localStorage.setItem('allPOS', allPOS)
        commit('GET_ALL_POS', allPOS)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  getPermissionSetting({ commit }) {
    this.loadingButton = false
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const headers = { shopObjectId: shopObjectId }

    pos({
      url: '/api/v1.0/' + uid + '/Shop/access/' + shopObjectId,
      headers: headers,
      method: 'GET',
    }).then((res) => {
      const permissionSetting = res.data.data
      localStorage.setItem('permissionSetting', permissionSetting)
      commit('GET_PERMISSION_SETTING', permissionSetting)
    })
  },
  getOperatingHours() {
    const shopsetting = state.shopsetting
    const worktime = {
      start: shopsetting.open,
      end: shopsetting.close,
    }
    let operatingHours = shopsetting.operatingHours
    let detail = {}
    let worktime_fri = {}
    let worktime_mon = {}
    let worktime_sat = {}
    let worktime_sun = {}
    let worktime_thur = {}
    let worktime_tues = {}
    let worktime_wed = {}

    if (operatingHours !== undefined) {
      if (operatingHours.Friday.dayoff) {
        worktime_fri = worktime
      } else {
        worktime_fri = operatingHours.Friday.worktime
      }

      if (operatingHours.Monday.dayoff) {
        worktime_mon = worktime
      } else {
        worktime_mon = operatingHours.Monday.worktime
      }

      if (operatingHours.Saturday.dayoff) {
        worktime_sat = worktime
      } else {
        worktime_sat = operatingHours.Saturday.worktime
      }

      if (operatingHours.Sunday.dayoff) {
        worktime_sun = worktime
      } else {
        worktime_sun = operatingHours.Sunday.worktime
      }

      if (operatingHours.Thursday.dayoff) {
        worktime_thur = worktime
      } else {
        worktime_thur = operatingHours.Thursday.worktime
      }

      if (operatingHours.Tuesday.dayoff) {
        worktime_tues = worktime
      } else {
        worktime_tues = operatingHours.Tuesday.worktime
      }

      if (operatingHours.Wednesday.dayoff) {
        worktime_wed = worktime
      } else {
        worktime_wed = operatingHours.Wednesday.worktime
      }

      detail = {
        Friday: {
          dayoff: !operatingHours.Friday.dayoff,
          worktime: worktime_fri,
        },
        Monday: {
          dayoff: !operatingHours.Monday.dayoff,
          worktime: worktime_mon,
        },
        Saturday: {
          dayoff: !operatingHours.Saturday.dayoff,
          worktime: worktime_sat,
        },
        Sunday: {
          dayoff: !operatingHours.Sunday.dayoff,
          worktime: worktime_sun,
        },
        Thursday: {
          dayoff: !operatingHours.Thursday.dayoff,
          worktime: worktime_thur,
        },
        Tuesday: {
          dayoff: !operatingHours.Tuesday.dayoff,
          worktime: worktime_tues,
        },
        Wednesday: {
          dayoff: !operatingHours.Wednesday.dayoff,
          worktime: worktime_wed,
        },
      }
    } else {
      detail = {
        Friday: {
          dayoff: true,
          worktime: worktime,
        },
        Monday: {
          dayoff: true,
          worktime: worktime,
        },
        Saturday: {
          dayoff: false,
          worktime: worktime,
        },
        Sunday: {
          dayoff: false,
          worktime: worktime,
        },
        Thursday: {
          dayoff: true,
          worktime: worktime,
        },
        Tuesday: {
          dayoff: true,
          worktime: worktime,
        },
        Wednesday: {
          dayoff: true,
          worktime: worktime,
        },
      }
    }
    localStorage.setItem('shopOperatingHours', detail)
    state.shopOperatingHours = detail
  },
  getShopSetting({ commit }) {
    const uid = `${localStorage.getItem('uid')}`
    let shopObjectId = `${localStorage.getItem('shopObjectId')}`
    pos
      .get('/api/v1.0/' + uid + '/Shop/getshop/' + shopObjectId)
      .then((res) => {
        const shopsetting = res.data.data
        localStorage.setItem('shopsetting', shopsetting)
        let newData = {}
        if (shopsetting.language == undefined) {
          newData = Object.assign(shopsetting, { language: 'en' })
        } else {
          newData = shopsetting
        }
        if (shopsetting.timezone == undefined) {
          newData = Object.assign(shopsetting, { timezone: 'Asia/Bangkok' })
        } else {
          newData = shopsetting
        }

        if (shopsetting.isMenuEnabled == undefined) {
          newData = Object.assign(shopsetting, { isMenuEnabled: false })
        } else {
          newData = shopsetting
        }

        if (shopsetting.isLoyaltyEnabled == undefined) {
          newData = Object.assign(shopsetting, { isLoyaltyEnabled: false })
        } else {
          newData = shopsetting
        }
        state.shopsetting = shopsetting
        i18n.locale = shopsetting.language
        localStorage.setItem('shopObjectId', shopsetting.objectId)
        localStorage.setItem('shopsetting', newData)
        commit('GET_SHOPSETTING', newData)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  setNewSetting() {
    const uid = `${localStorage.getItem('uid')}`
    pos({
      method: 'post',
      url: '/api/v1.0/' + uid + '/Shop/updateconfig',
      data: state.shopsetting,
    })
      .then((res) => {
        const shopsetting = res.data.data
        localStorage.setItem('shopsetting', shopsetting)
        i18n.locale = shopsetting.language
      })
      .catch(function(error) {
        console.log(error)
      })
  },
  setShopOperatingHours(context, subset) {
    context.commit('SET_SHOP_OPENING_HOURS', subset)
  },
  setNewLocation(context, subset) {
    const coordinates = []
    const lat = subset.lat
    const lng = subset.lng

    coordinates.push(lng)
    coordinates.push(lat)

    const shopsetting = state.shopsetting
    const location = {}
    Object.assign(location, { type: 'Point' })
    Object.assign(location, { coordinates: coordinates })

    Object.assign(shopsetting, { location: location })
    context.commit('SET_SHOPSETTING', shopsetting)
  },
  setShopSetting(context, subset) {
    context.commit('SET_SHOPSETTING', subset)
  },
}

const getters = {
  shopOperatingHours: (state) => state.shopOperatingHours,
  shopsetting: (state) => state.shopsetting,
  allPOS: (state) => state.allPOS,
  permissionSetting: (state) => state.permissionSetting,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
