import axios from 'axios'

let headers = {
  'cache-control': 'no-cache',
}
let accessToken = `${localStorage.getItem('token')}`
if (accessToken && accessToken !== '') {
  headers.Authorization = accessToken
}
const instance = axios.create({
  baseURL: process.env.VUE_APP_SERVICE_URL,
  headers: headers,
})

let count = sessionStorage.getItem('checkLogin') || 0

instance.interceptors.response.use(
  (response) => {
    if (
      response.data.error.code === 402 ||
      response.data.error.code === 401 ||
      response.data.error.code === 405
    ) {
      localStorage.clear()
      sessionStorage.setItem('checkLogin', Number(count) + 1)

      if (count >= 5) {
        sessionStorage.removeItem('checkLogin')
        return (window.location.href = '/error')
      } else {
        return (window.location.href = '/login')
      }
    }

    return response
  },
  (error) => {
    console.log(error.message)
    return (window.location.href = '/error')
  }
)

export default instance
