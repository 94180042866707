<template>
  <CCard>
    <div class="access-con">
          <!-- style="opacity: 0.5;" -->
          <CImg src="/img/delivery/grab_integration_info_1.png"  class="imgAdd" width="100%" />
          <CAlert id="addSalesHoursInfoAlertBox" color="success" class="action-grab d-inline-block col-12 col-lg-5 mt-0 mb-0 text-center">
              <div class="m-4">
                  <p>{{ $t('connectGrab') }}</p>
                  <CButton color="success" size="lg" @click="$router.push({name: 'GrabIntergration',})"> {{$t('grabIntegration')}}</CButton>
                  <p class="mt-3">**{{ $t('stepToIntergrate') }}<a href="https://support.silompos.com/th/article/silom-grabfood-dashboard-q3wya5/" target="_blank"> "{{$t('stepGrabandPOS') }}"</a></p> 
              </div>       
          </CAlert>     
      </div>
    </CCard>
  </template>
<script>
export default {
  methods: {

  }
}
</script>
<style>
  .access-con{
      position: relative;
      width: 100%;
  }
  .access-con .action-grab{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* background-color: #555;
    color: white; */
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .imgAdd{
    opacity: 0.8;
  }
  .access-con:hover .imgAdd{
    opacity: 0.5;
  }
  .access-con:hover .action-grab{
    opacity: 1;
  }
</style>