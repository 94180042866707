import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import CoreuiVue from '@coreui/vue'
import VCalendar from 'v-calendar'
import { iconsSet as icons } from './assets/icons/icons.js'
import VMdDateRangePicker from 'v-md-date-range-picker'
import VueAnalytics from 'vue-analytics'
import i18n from './plugins/i18n'
import regeneratorRuntime from 'regenerator-runtime'
import VueHtml2Canvas from 'vue-html2canvas'
import Modal from './containers/Modal'
import cssPrint from '../src/assets/styles/print.css'
import VueHtmlToPaper from 'vue-html-to-paper'
import css from '../src/assets/scss/style.scss'
import AccessData from './containers/AccessData'
import AccessGrab from './containers/AccessGrab'
import LoadingPage from './containers/LoadingPage'
import * as VueGoogleMaps from 'vue2-google-maps'
import firebase from 'firebase/app'
import './firebaseInit'
import SelectShop from '../src/containers/SelectShop'
import ShowDate from '../src/containers/ShowDate'
import VueBarcode from 'vue-barcode'
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import * as Sentry from "@sentry/vue";
import VueContentPlaceholders from 'vue-content-placeholders'

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    css,
    cssPrint,
  ],
  timeout: 1000,
  autoClose: true,
  windowTitle: window.document.title,
}
const api_key = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
Vue.use(VueGoogleMaps, {
  load: {
    key: api_key,
    libraries: 'places',
  },
})
Vue.use(VueContentPlaceholders)
Vue.use(VueHtmlToPaper, options)
Vue.use(VueHtmlToPaper)
Vue.use(VueDatePicker, { lang: 'en' })

Vue.use(VueDatePicker)
Vue.component('barcode', VueBarcode)
Vue.component('access-data', AccessData)
Vue.component('access-grab',AccessGrab)
Vue.component('loading-page', LoadingPage)
Vue.component('select-shop', SelectShop)
Vue.component('show-date', ShowDate)
Vue.component('modal', Modal)
Vue.use(VueHtml2Canvas)
Vue.config.performance = true
Vue.config.productionTip = false
Vue.use(CoreuiVue)
Vue.use(VCalendar)
Vue.use(VMdDateRangePicker)
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_ANALYTICS,
  router,
})

let app = ''

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      icons,
      i18n,
      render: (h) => h(App),
    }).$mount('#app')
  }
})
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", process.env.VUE_APP_SERVICE_URL, process.env.VUE_APP_API_URL, process.env.VUE_APP_API_URL, process.env.VUE_APP_PAYMENT_API_URL, process.env.VUE_APP_CRM_API_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
