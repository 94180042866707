<template>
  <div>
    <div :class="modalClasses" tabindex="-1" role="dialog" @click="modalClick($event)">
      <div :class="dialogClasses" role="document">
        <div :class="contentClasses">
          <slot name="header-wrapper">
            <header class="modal-header">
              <slot name="header">
                <h5 class="modal-title">
                  {{ title }}
                </h5>
                <CButtonClose @click="hide($event)" />
              </slot>
            </header>
          </slot>
          <slot name="body-wrapper">
            <div class="modal-body">
              <slot></slot>
            </div>
          </slot>
          <slot name="footer-wrapper">
            <footer class="modal-footer">
              <slot name="footer">
                <CRow class="justify-content-center col-md-12 col-12">
                  <CCol col="5" lg="4" v-if="color === 'danger'">
                    <CButton block :class="btnClasses" v-if="loading === true" @click="isSave($event)">
                      {{ $t('delete') }}
                    </CButton>
                    <CButton block :class="btnClasses" v-else-if="loading === false" disabled>
                      <CSpinner color="white" size="sm" /> {{ $t('delete') }}
                    </CButton>
                  </CCol>
                  <CCol col="5" lg="4" v-else>
                    <CButton block :class="btnClasses" v-if="loading === true" @click="isSave($event)">
                      {{ $t('save') }}
                    </CButton>
                    <CButton block :class="btnClasses" v-else-if="loading === false" disabled>
                      <CSpinner color="white" size="sm" /> {{ $t('save') }}
                    </CButton>
                  </CCol>
                  <CCol lg="2" col="1"> </CCol>
                  <CCol col="5" lg="4">
                    <CButton block color="light" @click="hide($event)">
                      {{ $t('cancel') }}
                    </CButton>
                  </CCol>
                </CRow>
              </slot>
            </footer>
          </slot>
        </div>
      </div>
    </div>
    <div v-if="backdrop && (visible || isTransitioning)" :class="backdropClasses"></div>
  </div>
</template>

<script>
export default {
  name: 'CModal',
  components: {
    // CButtonClose
  },
  props: {
    alertError: Boolean,
    loading: Boolean,
    show: Boolean,
    centered: Boolean,
    title: String,
    size: {
      type: String,
      validator: (val) => ['', 'sm', 'lg'].includes(val),
    },
    color: String,
    borderColor: String,
    fade: {
      type: Boolean,
      default: true,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    closeOnBackdrop: {
      type: Boolean,
      default: true,
    },
    addContentClasses: [String, Array, Object],
  },
  data() {
    return {
      visible: this.show,
      isTransitioning: false,
      timeout: null,
    }
  },
  computed: {
    backdropClasses() {
      return {
        'modal-backdrop': true,
        fade: this.fade,
        show: this.visible || !this.fade,
      }
    },
    modalClasses() {
      return [
        'modal overflow-auto',
        {
          fade: this.fade,
          show: this.visible,
          'd-block': this.visible || this.isTransitioning,
          [`modal-${this.color}`]: this.color,
        },
      ]
    },
    dialogClasses() {
      return [
        'modal-dialog',
        {
          'modal-dialog-centered': this.centered,
          [`modal-${this.size}`]: this.size,
        },
      ]
    },
    contentClasses() {
      return [
        this.addContentClasses,
        'modal-content',
        {
          [`border-${this.borderColor}`]: this.borderColor,
        },
      ]
    },
    btnClasses() {
      return [`btn btn-${this.color || 'primary'}`]
    },
  },
  watch: {
    show(val) {
      this.toggle(val)
    },
  },
  methods: {
    modalClick(e) {
      if (e.target === this.$el.firstElementChild && this.closeOnBackdrop) {
        this.hide(e)
      }
    },
    isSave() {
      this.$emit('isSave')
    },
    hide(e) {
      this.$emit('update:loading', true, e)
      this.$emit('update:show', false, e)
    },
    toggle(newVal) {
      setTimeout(() => {
        this.visible = newVal
      }, 0)
      if (this.fade) {
        this.isTransitioning = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.isTransitioning = false
        }, 150)
      }
    },
  },
}
</script>
<style>
.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
</style>
