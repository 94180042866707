import pos from '../services/local'
import util from '@/util/util'


const state = {
    productSKUdetail : {},
    productSKUObjectId : '',
    editProductSKU : {},
    addProductSKUDetail :{}
}

const mutations = {
    GET_PRODUCT_DETAIL(state,productSKUdetail){
        state.productSKUdetail = productSKUdetail
    },
    SET_PRODUCT_DETAIL(state,productSKUdetail){
        state.productSKUdetail = productSKUdetail
    },
    GET_PRODUCT_SKU_ID(state,productSKUObjectId){
        state.productSKUObjectId = productSKUObjectId
    },
    SET_PRODUCT_SKU_ID(state,productSKUObjectId){
        state.productSKUObjectId = productSKUObjectId
    },
    SET_EDIT_PRODUCT_SKU_DETAIL(state,editProductSKU){
        state.editProductSKU = editProductSKU
    },
    GET_EDIT_PRODUCT_SKU_DETAIL(state,editProductSKU){
        state.editProductSKU = editProductSKU
    },
    GET_NEW_PRODUCT_SKU_DETAIL(state, addProductSKUDetail){
      state.addProductSKUDetail = addProductSKUDetail
    },
    SET_NEW_PRODUCT_SKU_DETAIL(state, addProductSKUDetail){
      state.addProductSKUDetail =  addProductSKUDetail
    }
}

const actions = {
    setProductID(content , ID){
        let productSKUObjectId = ID
        localStorage.setItem('productSKUObjectId', productSKUObjectId)
        content.commit('SET_PRODUCT_SKU_ID',ID)         
    },
    setNewItem({ commit }){
      
      const data = {
        SKUName: '',
        SKUPrice: 0,
        category : null,
        // categoryId: this.category.id,
        enabled: true,
        favorite: true,
        indexColor: 1,
        colori: util.generateColor(1),
        isOnScreen: true,
        productPLU: {
          PLUCode: '',
        },
        serviceCharge: '',
        // shop: shop,
        // shopId: this.shop.id,
        skuType: 'P',
        stdCost: 0,
        unit: null,
        unitId: '',
        vatType: 'V',
        negotiatePrice: false,
        additionImagePath: '',
        remoteVideoPath: '',
        imageData : '',
        productDescription : '',
        productcode : ''
      }
      localStorage.setItem('addProductSKUDetail', data)
            commit('GET_NEW_PRODUCT_SKU_DETAIL', data)
            commit('SET_NEW_PRODUCT_SKU_DETAIL', data)

            
      
    },
    getProductSKUDatail({ commit }){
        this.loading = false
        this.isLoadingProduct = true
        const uid = `${localStorage.getItem('shopsUid')}`
        const shopObjectId = `${localStorage.getItem('shopObjectId')}`
        const productSKUObjectId  = `${localStorage.getItem('productSKUObjectId')}`
  
        let params = {
          shopObjectId: shopObjectId,
          categoryObjectId: '',
          objectId: productSKUObjectId
        }
       
        try {
          const headers = { shopObjectId: shopObjectId }
          pos({
            url: '/api/v1.0/' + uid + '/productsku/getwithcategory/',
            params: params,
            headers: headers,
            method: 'GET',
          }).then((res) => {
            const data = res.data.data

            let videoOption =  ''
            let currentVideo =  ''
            let currentVideoHolder = ''
            let trackingOldVideoPath = ''
            let colori = util.generateColor(0)
            let imageData = ''
            let imageDataBtn = ''
            let isMenu =false
            let remoteImagePath = ''
            let productDescription = ''
            let productcode = ''
            const trackingFirebaseItem  = []


            if (data.remoteVideoPath != undefined && data.remoteVideoPath != '') {
                trackingFirebaseItem.push(data.remoteVideoPath)
                trackingOldVideoPath = data.remoteVideoPath
                currentVideo = data.remoteVideoPath
               let  currentVideoHolder = data.remoteVideoPath
                if (currentVideoHolder.startsWith("https://firebasestorage")) {
                  videoOption = 'Upload'
                } else {
                  videoOption = 'URL'
                }
               
            }

            if (data.additionImagePath != undefined) {
                this.localAdditionImagePath = data.additionImagePath
                data.additionImagePath.forEach(element => {
                  trackingFirebaseItem.push(element)
                });
            }

            if (data.stdCost == undefined) {
                data.stdCost = 0
            } else {
                data.stdCost = parseFloat(data.stdCost).toFixed(2);
            }
            
            if (
                data.remoteImagePath != undefined &&
                data.remoteImagePath != ''
              ) {
                trackingFirebaseItem.push(data.remoteImagePath)
                imageData = data.remoteImagePath
                imageDataBtn = data.remoteImagePath,
                remoteImagePath = data.remoteImagePath
            }

            if(data.productDescription != undefined ){
                productDescription = data.productDescription
            }

            data.remoteImagePath = remoteImagePath

            if(data.isMenu !== null){
                isMenu = data.isMenu
            }
            data.isMenu = isMenu

            if (data.ProductPLU == undefined) {
                productcode = ''
            } else {
                productcode = data.ProductPLU.PLUCode
            }


            colori = util.generateColor(data.indexColor)

            Object.assign(data, {
                colori,
                imageData,
                imageDataBtn,
                productDescription,
                productcode,
                videoOption,
                currentVideo,
                currentVideoHolder,
                trackingOldVideoPath
            })

            
            localStorage.setItem('productSKUdetail', data)
            commit('GET_PRODUCT_DETAIL', data)
            commit('SET_PRODUCT_DETAIL', data)

          })
          this.loading = true
        } catch (error) {
          console.log(error)
        }
    },

    async saveProductSKUdata({ commit }) {
        this.loading = false
        if (!this.currentVideo.startsWith("https://firebasestorage")) {
          await this.uploadVideoToFirebase()
        }
  
        let productSKUdetail = state.productSKUdetail
        let category = {
          id: productSKUdetail.Category.id,
          objectId: productSKUdetail.Category.objectId,
          name: productSKUdetail.Category.name
        }
        this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
        const uid = `${localStorage.getItem('shopsUid')}`
        const shopObjectId = this.shopObjectId
        const plan = this.users.currentPlan
  
        productSKUdetail.additionImagePath.shift()
        await this.getImageURL2()
        let data = []
        if (this.imageData == '') {
          const oldImagePath = this.imagePath
          this.imagePath = ''
          productSKUdetail.uploadImage.deleteImage2(oldImagePath)
        }
  
        //Delete Additional Image 
        productSKUdetail.trackingOldImagePath.forEach(element => {
          let deleteImg = productSKUdetail.uploadImage.deleteImage(uid, shopObjectId, element)
        });
  
        if (this.remoteImagePath != '') {
          data = {
            objectId: productSKUdetail.productSKUObjectId,
            SKUName: productSKUdetail.SKUName,
            SKUPrice: productSKUdetail.SKUPrice,
            category: category,
            categoryId: productSKUdetail.Category.id,
            enabled: true,
            favorite: productSKUdetail.favorite,
            indexColor: productSKUdetail.indexColor,
            isOnScreen: productSKUdetail.isOnScreen,
            productPLU: { PLUCode: productSKUdetail.productcode },
            serviceCharge: productSKUdetail.serviceCharge,
            shop: {
              objectId: productSKUdetail.shop.objectId,
              id: productSKUdetail.shop.id,
              branchName: productSKUdetail.shop.branchName,
            },
            shopId: productSKUdetail.shop.id,
            skuType: productSKUdetail.skuType,
            stdCost: parseFloat(productSKUdetail.stdCost),
            unit: {
              id: productSKUdetail.unit.id,
              name: productSKUdetail.unit.name,
              objectId: productSKUdetail.unit.objectId,
            },
            unitId: productSKUdetail.unit.id,
            remoteImagePath: productSKUdetail.remoteImagePath,
            imagePath: productSKUdetail.imagePath,
            vatType: productSKUdetail.vatType,
            negotiatePrice: productSKUdetail.negotiatePrice,
            'additionImagePath': productSKUdetail.additionImagePath,
            'remoteVideoPath': productSKUdetail.currentVideo,
  
          }
        } else {
          data = {
            objectId: productSKUdetail.productSKUObjectId,
            SKUName: productSKUdetail.SKUName,
            SKUPrice: productSKUdetail.SKUPrice,
            category: {
              id: productSKUdetail.Category.id,
              name: productSKUdetail.Category.name,
              objectId: productSKUdetail.Category.objectId,
            },
            categoryId: productSKUdetail.Category.id,
            enabled: productSKUdetail,
            favorite: productSKUdetail.favorite,
            indexColor: productSKUdetail.indexColor,
            isOnScreen: productSKUdetail.isOnScreen,
            productPLU: { PLUCode: productSKUdetail.productcode },
            serviceCharge: productSKUdetail.serviceCharge,
            shop: {
              objectId: productSKUdetail.shop.objectId,
              id: productSKUdetail.shop.id,
              branchName: productSKUdetail.shop.branchName,
            },
            shopId: productSKUdetail.shop.id,
            skuType: productSKUdetail.skuType,
            stdCost: parseFloat(productSKUdetail.stdCost),
            unit: {
              id: productSKUdetail.unit.id,
              name: productSKUdetail.unit.name,
              objectId: productSKUdetail.unit.objectId,
            },
            unitId: productSKUdetail.unit.id,
            vatType: productSKUdetail.vatType,
            remoteImagePath: '',
            imagePath: productSKUdetail.imagePath,
            negotiatePrice: productSKUdetail.negotiatePrice,
            'additionImagePath': productSKUdetail.additionImagePath,
            'remoteVideoPath': productSKUdetail.currentVideo,
          }
        }
        if (this.shop.isMenuEnabled) {
          Object.assign(data, { isMenu: this.isMenu })
        }
        const headers = { shopObjectId: shopObjectId }
  
        this.uploadProgress = 90
        this.onUploadText = this.$i18n.t('productDataSaving')
        pos({
          method: 'post',
          url: '/api/v1.0/' + uid + '/productsku/edit',
          params: { shopObjectId: shopObjectId, plan: plan },
          data: data,
          headers: headers,
        })
          .then((response) => {
            setTimeout(() => {
              if (response.data.error.code === 4000) {
                this.textError = this.$i18n.t('failedToSave')
                this.textMessage = response.data.error.message
                this.alertError = true
                this.loading = true
              } else {
                this.loading = true
                this.confirmModal = false
                this.isSuccess = true
                this.$router.push('/product' + this.queryURL)
              }
            }, 1000)
          })
          .catch((error) => {
            console.log(error)
          })
    },

    deleteProductSKU() {
        const uid = `${localStorage.getItem('shopsUid')}`
        const plan = this.users.currentPlan
        const shopObjectId = this.shopObjectId
        let data = {
          shopObjectId: shopObjectId,
          objectId: this.productSKUObjectId,
        }
        const headers = { shopObjectId: shopObjectId }
  
        pos({
          method: 'post',
          url: '/api/v1.0/' + uid + '/ProductSKU/softdelete',
          params: { plan: plan },
          data: data,
          headers: headers,
        })
          .then(
            setTimeout(() => {
              this.deleteModal = false
              this.$router.go(-1)
            }, 100)
          )
          .catch((error) => {
            console.log(error)
          })
      },
}

const getters = {
    productSKUObjectId : (state)=> state.productSKUObjectId,
    productSKUdetail : (state)=> state.productSKUdetail,
    editProductSKU : (state)=> state.editProductSKU,
    addProductSKUDetail : (state) => state.addProductSKUDetail
}

export default {
  state,
  actions,
  mutations,
  getters,
}
