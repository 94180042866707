import { result } from "lodash"
import i18n from '@/plugins/i18n'

export default {
  getDeliveryImg(id) {
    switch (id) {
      case '1':
        return '/img/delivery/store.png'
      case '2':
        return '/img/delivery/DISCOUNT.jpg'
      case '3':
        return '/img/delivery/Member.png'
      case '101':
        return '/img/delivery/grab.png'
      case '102':
        return '/img/delivery/LINEMAN.png'
      case '103':
        return '/img/delivery/foodpanda.png'
      case '104':
        return '/img/delivery/Shopee-food.png'
      case '105':
        return '/img/delivery/Robinhood.png'
      case '106':
        return '/img/delivery/truefood.png'
    }
  },
  getDayOfWeek(dayOfWeek) {
    switch (dayOfWeek) {
      case '1':
        return 'Sun'
      case '2':
        return 'Mon'
      case '3':
        return 'Tue'
      case '4':
        return 'Wed'
      case '5':
        return 'Thu'
      case '6':
        return 'Fri'
      case '7':
        return 'Sat'

    }
  },
  getDayName(day) {
    switch (day) {
      case 'mon':
        return 'monday'
      case 'tue':
        return 'tuesday'
      case 'wed':
        return 'wednesday'
      case 'thu':
        return 'thursday'
      case 'fri':
        return 'friday'
      case 'sat':
        return 'saturday'
      case 'sun':
        return 'sunday'

    }
  },
  createObjectId() {
    let timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
    return (
      timestamp +
      'xxxxxxxxxxxxxxxx'
        .replace(/[x]/g, () => {
          return ((Math.random() * 16) | 0).toString(16)
        })
        .toLowerCase()
    )
  },

  generateObjectId() {
    // Generate a random timestamp value between now and January 1, 1970 (UNIX epoch)
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16).padStart(8, '0');

    // Generate a random machine ID (using the first 24 bits of a random number)
    const machineId = Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0');

    // Generate a random process ID (using the first 12 bits of a random number)
    const processId = Math.floor(Math.random() * 0xFFF).toString(16).padStart(4, '0');

    // Generate a random counter value (using the first 24 bits of a random number)
    const counter = Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0');

    // Concatenate the timestamp, machine ID, process ID, and counter into a single string
    const objectId = timestamp + machineId + processId + counter;

    return objectId;
  },

  generateFilename(extension = '') {
    // Define a string of characters to use for the random portion of the filename
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';

    // Generate a random 16-character string of characters
    let randomString = '';
    for (let i = 0; i < 16; i++) {
      randomString += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    // Combine the random string with the current timestamp to create the filename
    const timestamp = Date.now();
    const filename = `${timestamp}-${randomString}${extension}`;

    return filename;
  },
  currencyStringToNumber(currency) {
    return Number(currency.replace(/[^0-9\.-]+/g, ''))
  },

  convertCurrency(currency) {
    try {
      return currency.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    } catch (e) {
      return 0
    }
  },
  formatNumber(value) {
    return Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value);
  },

  convertNumber(text) {
    return text.toLocaleString()
  },

  convertOnhand(onhand) {
    try {
      return onhand.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    } catch (e) {
      return 0
    }
  },
  compare(a, b) {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  },

  generateRef() {
    var d = new Date().getTime()
    if (
      typeof performance !== 'undefined' &&
      typeof performance.now === 'function'
    ) {
      d += performance.now()
    }
    var newGuid = 'xxxxxxxx-xxxx'.replace(/[xy]/g, (c) => {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })

    return newGuid
  },

  generateBarcode() {
    var newGuid = 'PLUxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 9) | 0
      return (c === 'x' ? r : r).toString(16)
    })
    return newGuid
  },

  generateSerial() {
    var newGuid = 'SNxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 9) | 0
      return (c === 'x' ? r : r).toString(16)
    })

    return newGuid
  },

  generateColor(indexColor) {
    switch (indexColor) {
      case 0:
        return 'rgba(250, 80, 64, 1)'
      case 1:
        return 'rgba(41, 178, 137, 1)'
      case 2:
        return 'rgba(84, 0, 116, 1)'
      case 3:
        return 'rgba(224, 111, 78, 1)'
      case 4:
        return 'rgba(179, 169, 175, 1)'
      case 5:
        return 'rgba(240, 166, 214, 1)'
      case 6:
        return 'rgba(206, 11, 44, 1)'
      case 7:
        return 'rgba(117, 50, 67, 1)'
      case 8:
        return 'rgba(244, 180, 0, 1)'
      case 9:
        return 'rgba(41, 180, 107, 1)'
      case 10:
        return 'rgba(163, 115, 97, 1)'
      case 11:
        return 'rgba(244, 175, 90, 1)'
      case 12:
        return 'rgba(109, 204, 147, 1)'
      case 13:
        return 'rgba(77, 164, 107, 1)'
      case 14:
        return 'rgba(144, 206, 93, 1)'
      case 15:
        return 'rgba(188, 218, 122, 1)'
      case 16:
        return 'rgba(249, 232, 145, 1)'
      case 17:
        return 'rgba(224, 209, 118, 1)'
      case 18:
        return 'rgba(163, 222, 194, 1)'
      case 19:
        return 'rgba(172, 223, 230, 1)'
      case 20:
        return 'rgba(166, 197, 228, 1)'
      case 21:
        return 'rgba(85, 135, 224, 1)'
      case 22:
        return 'rgba(153, 158, 248, 1)'
      case 23:
        return 'rgba(179, 157, 248, 1)'
      case 24:
        return 'rgba(143, 143, 143, 1)'
      case 25:
        return 'rgba(200, 189, 191, 1)'
      case 26:
        return 'rgba(198, 168, 170, 1)'
      case 27:
        return 'rgba(233, 150, 177, 1)'
      case 28:
        return 'rgba(193, 123, 224, 1)'
      case 29:
        return 'rgba(157, 126, 220, 1)'
      default:
        return 'rgba(41, 178, 137, 1)'
    }
  },

  generateColorBG(indexColor) {
    switch (indexColor) {
      case 0:
        return '#F0F8FF'
      case 1:
        return '#FAEBD7'
      case 2:
        return '#7FFFD4'
      case 3:
        return '#F0FFFF'
      case 4:
        return '#F5F5DC'
      case 5:
        return '#FFEBCD'
      case 6:
        return '#DEB887'
      case 7:
        return '#6495ED'
      case 8:
        return '#E9967A'
      case 9:
        return '#8FBC8F'
      case 10:
        return '#00CED1'
      case 11:
        return '#00BFFF'
      case 12:
        return '#F0FFF0'
      case 13:
        return '#CD5C5C'
      case 14:
        return '#FFF0F5'
      case 15:
        return '#E6E6FA'
      case 16:
        return '#ADD8E6'
      case 17:
        return '#E0FFFF'
      case 18:
        return '#FFB6C1'
      case 19:
        return '#FFA07A'
      case 20:
        return '#20B2AA'
      case 21:
        return '#FAF0E6'
      case 22:
        return '#66CDAA'
      case 23:
        return '#9370DB'
      case 24:
        return '#48D1CC'
      case 25:
        return '#FFE4E1'
      case 26:
        return '#FFA500'
      case 27:
        return '#AFEEEE'
      case 28:
        return '#AFEEEE'
      case 29:
        return '#FFC0CB'
      case 30:
        return '#DDA0DD'
      case 31:
        return '#FA8072'
      case 32:
        return '#F4A460'
      case 33:
        return '#FFF5EE'
      case 34:
        return '#87CEEB'
      case 35:
        return '#FFFAFA'
      case 36:
        return '#D8BFD8'
      case 37:
        return '#FF6347'
      case 38:
        return '#008080'
      case 39:
        return '#F5F5F5'
      case 40:
        return '#BC8F8F'
      default:
        return '#F0FFF0'
    }
  },

  ThaiBaht(Number) {
    for (var i = 0; i < Number.length; i++) {
      Number = Number.replace(',', '')
      Number = Number.replace(' ', '')
    }

    var TxtNumArr = [
      'ศูนย์',
      'หนึ่ง',
      'สอง',
      'สาม',
      'สี่',
      'ห้า',
      'หก',
      'เจ็ด',
      'แปด',
      'เก้า',
      'สิบ',
    ]
    var TxtDigitArr = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']
    var BahtText = ''

    if (isNaN(Number)) {
      return 'ข้อมูลนำเข้าไม่ถูกต้อง'
    } else {
      if (Number - 0 > 9999999.9999) {
        return 'ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้'
      } else {
        Number = Number.split('.')
        if (Number[1].length > 0) {
          Number[1] = Number[1].substring(0, 2)
        }
        var NumberLen = Number[0].length - 0
        for (let i = 0; i < NumberLen; i++) {
          var tmp = Number[0].substring(i, i + 1) - 0
          if (tmp != 0) {
            if (tmp < 10) { // Check if tmp is less than 10
              BahtText += TxtNumArr[tmp] // Append Thai numeral directly
            } else {
              if (i == NumberLen - 1 && tmp == 1) {
                BahtText += 'เอ็ด'
              } else if (i == NumberLen - 2 && tmp == 2) {
                BahtText += 'ยี่'
              } else if (i == NumberLen - 2 && tmp == 1) {
                BahtText += ''
              } else {
                BahtText += TxtNumArr[tmp]
              }
            }
            BahtText += TxtDigitArr[NumberLen - i - 1]
          }
        }
        BahtText += 'บาท'
        if (Number[1] == '0' || Number[1] == '00') {
          Number
          BahtText += 'ถ้วน'
        } else {
          let DecimalLen = Number[1].length - 0
          for (let i = 0; i < DecimalLen; i++) {
            let tmp = Number[1].substring(i, i + 1) - 0
            if (tmp != 0) {
              if (i == DecimalLen - 1 && tmp == 1) {
                BahtText += 'เอ็ด'
              } else if (i == DecimalLen - 2 && tmp == 2) {
                BahtText += 'ยี่'
              } else if (i == DecimalLen - 2 && tmp == 1) {
                BahtText += ''
              } else {
                BahtText += TxtNumArr[tmp]
              }
              BahtText += TxtDigitArr[DecimalLen - i - 1]
            }
          }
          BahtText += 'สตางค์'
        }
        return BahtText
      }
    }
  },

  hideID(merchantId) {
    let hideNum = []
    for (let i = 0; i < merchantId.length; i++) {
      if (i < merchantId.length - 3 && i > 2) {
        hideNum.push('*')
      } else {
        hideNum.push(merchantId[i])
      }
    }
    return hideNum.join('')
  },
  generatePromotionResultTitle(type, results) {
    let part1 = '';
    let part2 = '';
    let part3 = '';

    const formatItems = (items, formatter) =>
      items.map(formatter).join(', ');

    switch (type) {
      case 'RECEIPT_DOLLAR_DISCOUNT':
        part1 = i18n.t('receiptDiscount')
        part2 = results[0].value;
        part3 = i18n.t('baht')
        break;

      case 'RECEIPT_PERCENTAGE_DISCOUNT':
        part1 = i18n.t('receiptDiscount')
        part2 = results[0].value + '%'
        if (results[0].cap && results[0].cap > 0) {
          part3 = `% ${i18n.t('maxDiscount')} ${results[0].cap} ${i18n.t('baht')}`;
        }
        break;

      case 'ITEM_DOLLAR_DISCOUNT':
      case 'ITEM_PERCENTAGE_DISCOUNT':
        part1 = `${i18n.t('itemDiscountMapper')} ${formatItems(
          results.flatMap(result => result.items),
          item => item.name
        )}`;
        part2 = results[0].value;
        if (type === 'ITEM_DOLLAR_DISCOUNT') {
          part3 = i18n.t('baht')
        } else if (type === 'ITEM_PERCENTAGE_DISCOUNT' && results[0].cap && results[0].cap > 0) {
          part3 = `% ${i18n.t('maxDiscount')} ${results[0].cap} ${i18n.t('baht')}`
        } else if (type === 'ITEM_PERCENTAGE_DISCOUNT' && !results[0].cap) {
          part3 = `%`
        }


        if (results[0].quantityLimit && results[0].quantityLimit !== -1) {
          part3 += ` ${i18n.t('limit')} ${results[0].quantityLimit} ${i18n.t('item')}`;
        }
        if (results.length > 1) {
          part3 += i18n.t('andMoreDiscount')
        }
        break;

      case 'CATEGORY_DOLLAR_DISCOUNT':
      case 'CATEGORY_PERCENTAGE_DISCOUNT':
        part1 = `${i18n.t('categoryDiscount')} ${formatItems(
          results.flatMap(result => result.categories),
          category => category.name
        )}`;
        part2 = results[0].value;
        if (type === 'CATEGORY_DOLLAR_DISCOUNT') {
          part3 = i18n.t('baht')
        } else if (type === 'CATEGORY_PERCENTAGE_DISCOUNT' && results[0].cap && results[0].cap > 0) {
          part3 = `% ${i18n.t('maxDiscount')} ${results[0].cap} ${i18n.t('baht')}`
        } else if (type === 'CATEGORY_PERCENTAGE_DISCOUNT' && !results[0].cap) {
          part3 = `%`
        }


        if (results[0].quantityLimit && results[0].quantityLimit !== -1) {
          part3 += ` ${i18n.t('limit')} ${results[0].quantityLimit} ${i18n.t('item')}`;
        }
        if (results.length > 1) {
          part3 += i18n.t('andMoreDiscount')
        }
        break;

      case 'ITEM_FIXED_PRICE':
        part1 = `${i18n.t('FIXED_PRICE_OPTION')} ${formatItems(
          results.flatMap(result => result.items),
          item => `${item.name} ${item.fixedPrice} ${i18n.t('baht')}`
        )}`;
        if (results[0].quantityLimit && results[0].quantityLimit !== -1) {
          part3 = ` ${i18n.t('limit')} ${results[0].quantityLimit} ${i18n.t('item')}`;
        }
        if (results.length > 1) {
          part3 += i18n.t('andMoreDiscount')
        }
        break;

      case 'CATEGORY_FIXED_PRICE':
        part1 = `${i18n.t('CATEGORY_FIXED_PRICE')} ${formatItems(
          results.flatMap(result => result.categories),
          category => category.name
        )}`;
        part2 = `${i18n.t('specialPrice')} ${results[0].value} ${i18n.t('baht')} `;
        if (results[0].cap && results[0].cap > 0) {
          part2 = `${part2} *${i18n.t('forUnderPrice')} ${results[0].cap} ${i18n.t('baht')}`
        }

        if (results[0].quantityLimit && results[0].quantityLimit !== -1) {
          part3 = ` ${i18n.t('limit')} ${results[0].quantityLimit} ${i18n.t('item')}`;
        }
        if (results.length > 1) {
          part3 += i18n.t('andMoreDiscount')
        }
        break;

      case 'FREE_ITEM':
        part1 = i18n.t('freeItemMapper')
        part2 = results[0].product.name;
        part3 = `${i18n.t('QTYinput')} ${results[0].quantity} ${i18n.t('item')}`;
        break;

      default:
        break;
    }
    return `${part1} ${part2} ${part3}`.trim();
  }, generatePromotionConditionTitle(condition) {
    const conditionMapper = {
      'PRICE_RECEIPT_EXCEED': (condition) => {
        return `${i18n.t('whenPriceReceiptReach')} ${condition.threshold} ${i18n.t('exceedPrice')}`;
      },
      'QUANTITY_RECEIPT_EXCEED': (condition) => {
        return `${i18n.t('whenQuantityReceiptReach')} ${condition.threshold} ${i18n.t('exceedQuantity')}`;
      },
      'PRICE_ITEM_EXCEED': (condition) => {
        const items = condition.items.map(item => item.name).join(', ');
        return `${i18n.t('whenBuySome')} ${items} ${i18n.t('forTotalPrice')} ${condition.threshold} ${i18n.t('exceedPrice')}`;
      },
      'QUANTITY_ITEM_EXCEED': (condition) => {
        const items = condition.items.map(item => item.name).join(', ');
        return `${i18n.t('whenBuySome')} ${items} ${i18n.t('forTotalQuantity')} ${condition.threshold} ${i18n.t('exceedQuantity')}`;
      },
      'PRICE_CATEGORY_EXCEED': (condition) => {
        const categories = condition.categories.map(category => category.name).join(', ');
        return `${i18n.t('whenBuySomeCategory')} ${categories} ${i18n.t('forTotalPrice')} ${condition.threshold} ${i18n.t('exceedPrice')}`;
      },
      'QUANTITY_CATEGORY_EXCEED': (condition) => {
        const categories = condition.categories.map(category => category.name).join(', ');
        return `${i18n.t('whenBuySomeCategory')} ${categories} ${i18n.t('forTotalQuantity')} ${condition.threshold} ${i18n.t('exceedQuantity')}`;
      },
      'NO_CONDITION': () => {
        return i18n.t('NO_CONDITION');
      }
    };

    // Check if a mapper exists for the given condition type
    if (conditionMapper[condition.type]) {
      return conditionMapper[condition.type](condition);
    }

    // Default case if no mapper exists
    return ''
  }


}
