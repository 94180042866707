import pos from '@/services/local'
import store from '../store/store'

export default {
  getRefresh() {
    const uid = `${localStorage.getItem('uid')}`
    const params = { refresh: true }

    pos
      .get('/api/v1.0/' + uid + '/Shop/accesslist/data', { params })
      .then((res) => {
        store.dispatch('getPermission')
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
