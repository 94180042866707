<template>
    <div>
        <select-shop @getData="handleShopObjectIdChange" :loadingButton="!loadingButton"></select-shop>
        <div v-if="isPermission">
            <div v-show="!loading">
                <CCard class="shadow-sm">
                    <CCardBody class="p-4">
                        <div class=" row">
                            <div class="col-12 d-flex justify-content-between">
                                <CButton color="light" @click="$router.push('/promotion')"
                                    class="align-items-center pr-4 pl-4">
                                    <span><i class="fi fi-rr-arrow-left mr-2"
                                            style="position: relative; top: 2px;"></i></span>{{ $t('exitPage') }}
                                </CButton>
                                <img @click="deleteModal = true" src="/img/trash.png"
                                    style="width: 24px; height: 24px; cursor: pointer;" />
                            </div>
                            <div class="col-12 text-center mt-4">
                                <h3>{{ $t('promotionDetailHeader') }}</h3>
                                <span>{{
                                    $t('promotionDetailHeaderDesp')
                                    }}</span>
                            </div>
                        </div>
                        <div v-if="!isGrabPromotion()" class="promotion-form d-flex justify-content-between mt-5">
                            <div>
                                <h5 class="d-flex align-items-center" @click="toggleActivationModal()">
                                    <CSwitch class="mr-3" color="success" :checked.sync="promotion.isActive" />
                                    <template v-if="promotion.isActive"> {{ $t('promotionActive') }}</template>
                                    <template v-else> {{ $t('promotionNotActive') }}</template>
                                </h5>

                            </div>
                        </div>
                        <div class="mt-4" v-if="disabledForm()">
                            <CAlert class="text-center" color="warning">
                                {{ $t('onGoingPromotionDesp') }}
                                <span @click="toggleGrabInfoModal()" class="ml-1"
                                    style="text-decoration: underline;cursor: pointer;">
                                    {{ $t('moreInfo') }}
                                </span>
                            </CAlert>
                        </div>
                        <div class="pl-2 pr-2">
                            <div v-show="!noConditionRequired.includes(promotion.condition.type)"
                                class="promotion-form mt-5">
                                <div>
                                    <AppPromotionConditionForm :key="'AppPromotionConditionDisplay'"
                                        ref="conditionComponent" class="mt-5" :conditionType="promotion.condition.type"
                                        :isReadonly="true" :conditionDisplay="promotion.condition">
                                    </AppPromotionConditionForm>
                                </div>
                            </div>
                            <div class="promotion-form mt-5">
                                <div>
                                    <AppPromotionResultForm :key="'AppPromotionResultForm'" ref="resultsComponent"
                                        class="mt-5" :promotionType="promotion.type" :resultsDisplay="promotion.results"
                                        :isReadonly="true" :isGrabTemplate="isGrabPromotion()">
                                    </AppPromotionResultForm>
                                </div>
                            </div>

                        </div>
                        <div>
                            <AppPromotionDetailsForm v-if="!loading" ref="detailsComponent" :promotion="promotion"
                                :isGrabPromotionAllow="isGrabPromotion()" :isEmptyForm="false" />
                        </div>
                    </CCardBody>
                </CCard>
                <div v-if="!disabledForm()" class=" row justify-content-end mb-4 mt-2">
                    <div class="col-6 col-md-2 pt-1">
                        <CButton @click="$router.push('/promotion')" block color="light"
                            style="background-color: white;">{{
                                $t('cancel') }}</CButton>
                    </div>
                    <div class="col-6 col-md-2 pt-1">
                        <CButton @click="toggleSaveModal()" block color="success">{{ $t('save') }}</CButton>
                    </div>
                </div>
                <CModal :show.sync="alertModal" centered :title="$t('saveFailedHeader')" color="danger" header="false">
                    <div class="text-center p-3">

                        <h5 style="line-height: 180%;" v-html="getErrorMessage()" />
                        <span v-if="errorMessage !== 'validateInfomation'" style="color: gray">({{ errorMessage
                            }})</span>
                    </div>
                    <template #footer>
                        <div class="w-100 d-flex justify-content-center">
                            <CButton style="width: 100%;margin-left: 1cap;" @click="alertModal = false" color="light">
                                <CSpinner v-if="loading" color="white" size="sm" />
                                <b>{{ $t("agreed") }}</b>
                            </CButton>
                        </div>
                    </template>
                </CModal>
                <CModal :show.sync="saveModal" centered :title="$t('saveMember')" color="success" header="false">
                    <div class="text-center pt-4 pb-4">
                        <!-- <div class="json-display text-left">
                            <pre>{{ prettyJson(promotion) }}</pre>
                        </div> -->
                        <h4>
                            {{ $t('confirmEditPromotion') }}
                        </h4>
                    </div>
                    <template #footer>
                        <div class="row w-100">
                            <div class="col-6">
                                <CButton @click="saveModal = false" block color="light">{{ $t('cancel') }}
                                </CButton>
                            </div>
                            <div class="col-6">
                                <CSpinner v-if="loading" color="white" size="sm" />
                                <CButton :disabled="loading" @click="saveData()" block color="success">{{ $t('submit')
                                    }}</CButton>
                            </div>
                        </div>
                    </template>
                </CModal>
                <CModal :show.sync="activationModal" centered :title="$t('confirmEditPromotion')" color="success"
                    header="false">
                    <div class="p-3 text-center">
                        <h4>{{ $t('confirmEditPromotion') }}</h4>
                        <div class="text-center" style="color: gray;">
                            <div v-if="promotion.isActive" v-html="$t('confirmActivePromotion')">
                            </div>
                            <div v-html="$t('confirmDeActivePromotion')" v-else></div>
                        </div>
                    </div>
                    <template #footer>
                        <div class="row w-100">
                            <div class="col-6">
                                <CButton @click="activationModal = false" block color="light">{{ $t('cancel') }}
                                </CButton>
                            </div>
                            <div class="col-6">
                                <CButton @click="saveData()" block color="success">{{ $t('submit') }}</CButton>
                            </div>
                        </div>
                    </template>
                </CModal>
                <CModal :show.sync="deleteModal" centered :title="$t('confirmEditPromotion')" color="danger"
                    header="false">
                    <div class="text-center p-3">
                        <h4>{{ $t('confirmDeletePromotion') }}</h4>
                        <span style="color: gray" v-html="$t('confirmDeletePromotionDesp')" />
                        <!-- <div class="json-display">
                        <pre>{{ prettyJson(promotionData) }}</pre>
                    </div> -->
                    </div>
                    <template #footer>
                        <div class="row w-100">
                            <div class="col-6">
                                <CButton :disabled="loading" @click="deleteModal = false" block color="light">{{
                                    $t('cancel') }}
                                </CButton>
                            </div>
                            <div class="col-6">
                                <CButton :disabled="loading" @click="deletePromotion()" block color="danger">{{
                                    $t('submit') }}</CButton>
                            </div>
                        </div>
                    </template>
                </CModal>
            </div>
            <div v-show="loading">
                <CCard>
                    <CCardBody style="padding-top: 20cap;">
                        <div style="width: 100%;height: 100vh;display: flex;justify-content: center;">
                            <CSpinner color="success"></CSpinner>
                        </div>
                    </CCardBody>
                </CCard>
            </div>
        </div>
        <div v-else>
            <access-data></access-data>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import permis from '@/util/permission'
import pos from '@/services/local';
import moment from 'moment'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-datepicker/index.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import AppPromotionConditionForm from '@/components/AppPromotionConditionForm';
import AppPromotionResultForm from '@/components/AppPromotionResultForm';
import AppPromotionDetailsForm from '@/components/AppPromotionDetailsForm';
import { CSpinner } from '@coreui/vue';
import '@/assets/styles/promotion.css'

export default {
    components: {
        VueTimepicker,
        AppPromotionConditionForm,
        AppPromotionResultForm,
        AppPromotionDetailsForm
    },
    data() {
        return {
            promotion: {
                type: '',
                condition: {
                    type: '',
                },
                results: [],
                details: {
                    title: '',
                    description: '',
                    remoteImagePath: ''
                },
                scope: [],
                isSpecificDay: {},
                quotas: {
                    totalCount: -1,
                    totalCount: -1,
                }
            },
            loadingButton: false,
            saveModal: false,
            activationModal: false,
            deleteModal: false,
            scopes: ['DINE_IN', 'PICK_UP', 'DELIVERY'],
            days: {
                Mon: { index: 0, name: "Monday", openTime: "09:00", closeTime: "17:00", isActive: false },
                Tue: { index: 1, name: "Tuesday", openTime: "09:00", closeTime: "17:00", isActive: false },
                Wed: { index: 2, name: "Wednesday", openTime: "09:00", closeTime: "17:00", isActive: false },
                Thu: { index: 3, name: "Thursday", openTime: "09:00", closeTime: "17:00", isActive: false },
                Fri: { index: 4, name: "Friday", openTime: "09:00", closeTime: "17:00", isActive: false },
                Sat: { index: 5, name: "Saturday", openTime: "09:00", closeTime: "17:00", isActive: true },
                Sun: { index: 6, name: "Sunday", openTime: "09:00", closeTime: "17:00", isActive: true }
            },
            refText: '',
            isSpecificDay: false,
            loading: false,
            noConditionRequired: ['NO_CONDITION', 'ITEM_X_FREE_Y', 'CATEGORY_X_FREE_Y'],
            alertModal: false,
            errorMessage: ''
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        isPermission() {
            // return permis.findPermissionRead('inventory', this.$route.path)
            const shopObjectId = localStorage.getItem('shopObjectId');
            const selectedShop = this.shops.find(shop => shop.objectId === shopObjectId) || null
            return selectedShop?.currentPlan == "multiplePOS" || false
        },
        shopObjectId: {
            get() {
                return localStorage.getItem('shopObjectId')
            },
        },
        uid: {
            get() {
                return localStorage.getItem('uid')
            },
        },
        promotionObjectId() {
            return this.$route.params.objectId;
        },
        hasRef: {
            get() {
                return this.promotion?.hasRef !== 'NO_REF';
            },
            set(value) {
                this.promotion.hasRef = value ? 'HAS_REF' : 'NO_REF';
            }
        },
    },
    created() {
        this.getPromotion()
    },
    methods: {
        getPromotion() {
            this.loading = true
            const headers = {
                shopObjectId: this.shopObjectId,
                uid: this.uid,
            }
            pos
                .get(`/api/v3.0/shop/promotion/${this.promotionObjectId}`,
                    { headers: headers }
                )
                .then((res) => {
                    this.promotion = res.data?.data || {}
                    if (res.data?.data?.isSpecificDay !== 'NO_SPECIFIC_DAY') {
                        this.isSpecificDay = true
                        this.days = res.data?.data?.isSpecificDay
                    } else {
                        this.isSpecificDay = false
                        this.days = {
                            Mon: { index: 0, name: "Monday", openTime: "09:00", closeTime: "17:00", isActive: false },
                            Tue: { index: 1, name: "Tuesday", openTime: "09:00", closeTime: "17:00", isActive: false },
                            Wed: { index: 2, name: "Wednesday", openTime: "09:00", closeTime: "17:00", isActive: false },
                            Thu: { index: 3, name: "Thursday", openTime: "09:00", closeTime: "17:00", isActive: false },
                            Fri: { index: 4, name: "Friday", openTime: "09:00", closeTime: "17:00", isActive: false },
                            Sat: { index: 5, name: "Saturday", openTime: "09:00", closeTime: "17:00", isActive: true },
                            Sun: { index: 6, name: "Sunday", openTime: "09:00", closeTime: "17:00", isActive: true }
                        }
                    }
                    if (res.data?.data?.hasRef !== 'NO_REF') {
                        this.refText = res.data?.data?.hasRef
                    }

                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                    this.$router.push('/promotion')
                    console.error("Error getting promotion:", error);
                });
        },
        handleShopObjectIdChange() {
            this.getPromotion()
        },
        prettyJson(jsonData) {
            return JSON.stringify(jsonData, null, 2);
        },
        toggleSaveModal() {
            const updatedDetails = this.$refs.detailsComponent.getDetails();
            this.promotion = {
                ...this.promotion,
                ...updatedDetails
            };

            if (!this.validatePromotionData()) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.errorMessage = 'validateInfomation'
                this.alertModal = true
                return;
            } else {
                this.saveModal = true;
            }
        },

        clearImg() {
            this.promotion.details.remoteImagePath = ''
        },
        toggleImageInput() {
            this.$refs.input_img.click()
        },
        validatePromotionData() {
            const isValid = Object.entries(this.promotion).every(([key, value]) => {
                // Skip validation for optional fields
                if (key === 'isSpecificDay' || key === 'hasRef' || key === 'quotas') {
                    return true;
                }

                if (value === null || value === '' || value === undefined) {
                    console.warn(`Field ${key} is invalid: ${value}`);
                    return false;
                }

                // Validate nested objects (e.g., `details` and `condition`)
                if (typeof value === 'object' && !Array.isArray(value)) {
                    return Object.entries(value).every(([subKey, subValue]) => {
                        // Skip validation for `details.remoteImagePath`
                        if (key === 'details' && subKey === 'remoteImagePath') {
                            return true;
                        }
                        if (subValue === null || subValue === '' || subValue === undefined) {
                            console.warn(`Field ${key}.${subKey} is invalid: ${subValue}`);
                            return false;
                        }
                        return true;
                    });
                }

                // For arrays, ensure they are not empty
                if (Array.isArray(value)) {
                    if (value.length === 0) {
                        console.warn(`Field ${key} is invalid: Array length is 0`);
                        return false;
                    }
                    return value.every(item => item !== null && item !== '' && item !== undefined);
                }

                return true;
            });

            // Check startDate and endDate
            const { startDate, endDate } = this.promotion;
            if (startDate && endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);

                if (start > end) {
                    console.warn("Validation failed: startDate cannot be after endDate.");
                    return false;
                }
            }

            return isValid;
        },

        saveData() {
            this.loading = true
            window.scrollTo({ top: 0 });
            const headers = {
                shopObjectId: this.shopObjectId,
                uid: this.uid,
            }
            pos
                .patch(`/api/v3.0/shop/promotion/${this.promotionObjectId}`, this.promotion, { headers: headers })
                .then((res) => {
                    if (res.data.success == true) {
                        this.loading = false
                        this.$router.push('/promotion');
                        this.saveModal = false;
                    } else {
                        this.errorMessage = res.data?.error?.message || ''
                        this.alertModal = true
                        this.saveModal = false;
                        this.loading = false
                        this.getPromotion()
                    }

                })
                .catch((error) => {
                    this.errorMessage = error
                    this.alertModal = true
                    this.saveModal = false;
                    this.loading = false
                });
        },
        toggleActivationModal() {
            const updatedDetails = this.$refs.detailsComponent.getDetails();
            this.promotion = {
                ...this.promotion,
                ...updatedDetails
            };
            if (!this.validatePromotionData()) {
                this.errorMessage = 'validateInfomation'
                this.alertModal = true
                return;
            } else {
                this.activationModal = true
            }
        },
        deletePromotion() {
            this.loading = true
            const headers = {
                shopObjectId: this.shopObjectId,
                uid: this.uid,
            }
            pos
                .delete(`/api/v3.0/shop/promotion/${this.promotionObjectId}`, { headers: headers })
                .then((res) => {
                    this.$router.push('/promotion')
                    this.saveModal = false;
                    this.loading = false
                })
                .catch((error) => {
                    console.error("Error saving promotion:", error);
                    this.saveModal = false;
                    this.loading = false
                });
        },
        isGrabPromotion() {
            const scopes = this.promotion.scopes || []
            if (scopes.length > 0) {
                return scopes.includes('GRAB') || false
            } else {
                return false
            }
        },
        disabledForm() {
            const currentDate = new Date();
            const startDate = this.promotion.startDate ? new Date(this.promotion.startDate) : null;
            if (startDate) {
                const oneHourBefore = new Date(startDate.getTime() - 60 * 60 * 1000)
                return currentDate >= oneHourBefore;
            } else {
                return false;
            }
        },
        toggleGrabInfoModal() {
            this.$refs.detailsComponent.toggleGrabInfoModal()
        },
        getErrorMessage() {
            const errorMessages = {
                '404:catagory': '404Category',
                '400:Invalid parameters!': '400InvalidParameters',
                '400:Category level campaign should have one': '400CategoryOne',
                '400: startTime': '400StartTime',
                '404:item not found': '404Item',
                '400: campaign start time too close for now': '400CampaignStartTime',
                '400:Item(s) are on multiple promotions': '400ItemMultiple',
                '400:Promotion overlaps with other promotions ': '400PromotionOverlaps',
                '400:invalid quotas': '400InvalidQuotas',
                'validateInfomation': 'validateInfomation'
            };

            for (const [key, message] of Object.entries(errorMessages)) {
                if (this.errorMessage.startsWith(key)) {
                    return this.$t(`errorMessagesGrab.${message}`)
                }
            }
            return this.$t('errorMessagesGrab.unknownError')
        }
    },
    watch: {
        activationModal(newValue) {
            if (newValue == false) {
                this.promotion.isActive = !this.promotion.isActive
            }
        },
        shopObjectId(newVal, oldVal) {
            window.location.href = '/promotion'
        },
    },

}
</script>
<style>
.input-icon-right {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-right: 0.25rem;
    border: 1px solid #ced4da;
}
</style>