<template>
  <CCard class="shadow-sm" id="selectShopCard">
    <CCardBody id="selectShopCardBody">
      <CRow id="selectShopRow">
        <CCol class="d-flex col-10 col-md-10 col-sm-6" id="selectShopCol">
          <img src="/img/icon/store.png" style="width: 32px; height:32px; margin-right: 8px;" />
          <select class="custom-select" v-model="shopObjectId" style="cursor: pointer;" @change="getPermission" id="selectShopDropdown">
            <option selected style="cursor: pointer;" value="noshop">
              {{ $t('selectshop') }}
            </option id="selectShopDefaultOption">
            <option :id="`selectShopOption-${shop.objectId}`" style="cursor: pointer;" v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
              {{ shop.shopName }} - {{ shop.branchName }} {{ headquarters(shop) ? '🏠 ' : '' }} {{ shop.connectEcommerce
                ? '🛒' : '' }}
            </option>
          </select>
          <!-- <CButton class="btn-light" v-on:click="getData()" style="margin-left: 7px;">
            <i class="fas fa-redo-alt text-success"> </i>
          </CButton> -->
        </CCol>
        <CCol v-if="!backButton" class="col-2 col-md-2 col-sm-2 mt-0" id="selectShopSubmitCol">
          <CButton id="selectShopSubmitButton" v-if="loadingButton === true" v-on:click="getData()" block color="success">
            {{ $t('submit') }}
          </CButton>
          <CButton id="selectShopLoadingButton" v-else-if="loadingButton === false" block color="success" disabled>
            <CSpinner id="selectShopSpinner" color="white" size="sm" />
            <!-- {{ $t('loading') }} -->
          </CButton>
        </CCol>
        <CCol v-else class="col-2 col-md-2 col-sm-2 mt-0" id="selectShopExitCol">
          <CButton v-on:click="pushBackward()" block color="light" id="selectShopExitButton">
            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('exitPage')
            }}
          </CButton>

        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import access from '@/util/access'

export default {
  props: {
    loadingButton: {
      type: Boolean,
      required: false,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        if (newValue !== 'noshop') {
          this.$store.dispatch('setShop', newValue)
          localStorage.setItem('shopObjectId', newValue)
        }
      },
    },
    connectEcommerce() {
      if (this.shop.connectEcommerce != null && this.shop.connectEcommerce === true) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
    if (this.shop) {
      this.currentPlan = this.shop.currentPlan;
    }
  },
  mounted() {
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
    if (this.shop) {
      this.currentPlan = this.shop.currentPlan;
    }
  },
  methods: {
    getData() {
      this.$emit('getData')
    },
    getPermission() {
      this.$store.dispatch('getPermission')
    },
    getRefresh() {
      access.getRefresh()
    },
    pushBackward() {
      this.$router.go(-1);
    },
    headquarters(shop) {
      return shop.status?.HQ === true;
    },
  },
  watch: {
    shopObjectId(newValue) {
      const storedShopObjectId = localStorage.getItem('shopObjectId')
      if (storedShopObjectId !== newValue) {
        // ถ้าไม่ตรงกับค่าใน localStorage ให้แทนที่ด้วย this.shopObjectId
        localStorage.setItem('shopObjectId', newValue)
      }
    },
  },
}
</script>
