import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import en from '../lang/en'
import th from '../lang/th'

const messages = {
  en,
  th
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'th',
  messages: messages,
  silentTranslationWarn: true,
})

export default i18n