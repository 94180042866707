import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    authorization: `${localStorage.getItem('token')}`,
  },
})

export default instance
