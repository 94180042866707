import axios from '../services/service'
import lineaxios from 'axios'

const state = {
  users: [],
  lineUser: [],
}

const mutations = {
  GET_USER(state, users) {
    state.users = users
  },
  GET_LINE_USER(state, lineUser) {
    state.lineUser = lineUser
  },
}

const actions = {
  getUser({ commit }) {
    const uid = `${localStorage.getItem('uid')}`
    axios
      .get('/user/v1.0/getbyuser/' + uid)
      .then((res) => {
        const users = res.data.data
        commit('GET_USER', users)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  getLineUser({ commit }, payload) {
    if (liff.getContext().type !== 'external') {
      const userId = payload
      lineaxios
        .get(process.env.VUE_APP_LINE_WEBHOOK + '/line/get/user/' + userId)
        .then((res) => {
          const lineUser = res.data
          commit('GET_LINE_USER', lineUser)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
}

const getters = {
  users: (state) => state.users,
  lineUser: (state) => state.lineUser,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
