<template>
  <div class="mt-2 mt-md-0 font-weight-normal">
    <p v-if="startDate == endDate">{{ $t('date') }} {{ startDate }}</p>
    <p v-else>{{ $t('date') }} {{ startDate }} {{ $t('to') }} {{ endDate }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import i18n from '@/plugins/i18n'
export default {
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    startDate() {
      return moment(String(this.date.start))
        .locale(i18n.locale)
        .format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end))
        .locale(i18n.locale)
        .format('DD MMMM YYYY')
    },
  },
}
</script>