<template>
    <div v-if="!loading" class="w-100 text-dark">
        <!-- <div class="w-100 text-center">
            {{ $t(conditionType) }}
        </div> -->
        <div v-if="isReadonly">
            <h5 class="mt-2"><span class="text-danger">*</span> {{ $t('conditionHeader') }} : {{ $t(conditionType) }}
            </h5>
        </div>
        <!-- BUNDLE_ITEM -->
        <div v-if="conditionType == 'BUNDLE_ITEM'" class="promotion-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="input-group">
                        <span class="input-group-text">{{ $t('bundles') }}</span>
                        <input :readonly="isReadonly" @click="openSelectProductModal(0)"
                            v-model="condition.bundle[0].product.name" type="text" class="form-control">
                        <span class="input-group-text input-icon-middle">{{ $t('QTYinput') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.bundle[0].quantity" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('itemQty') }}</span>
                    </div>
                    <div class="w-100 text-center mt-2 mb-1">
                        <i class="fi fi-rr-plus-small h4"></i>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('bundles') }}</span>
                        <input :readonly="isReadonly" @click="openSelectProductModal(1)"
                            v-model="condition.bundle[1].product.name" type="text" class="form-control">
                        <span class="input-group-text input-icon-middle">{{ $t('QTYinput') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.bundle[1].quantity" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('itemQty') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- BUNDLE_CATEGORY -->
        <div v-if="conditionType == 'BUNDLE_CATEGORY'" class="condition-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="input-group">
                        <span class="input-group-text">{{ $t('bundles') }}</span>
                        <select v-if="!isReadonly" class="form-control" v-model="condition.bundle[0].category">
                            <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                            <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                        </select>
                        <input v-else :readonly="isReadonly" v-model="condition.bundle[0].category.name" type="text"
                            class="form-control">
                        <span class="input-group-text input-icon-middle">{{ $t('QTYinput') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.bundle[0].quantity" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('itemQty') }}</span>
                    </div>
                    <div class="w-100 text-center mt-2 mb-1">
                        <i class="fi fi-rr-plus-small h4"></i>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('bundles') }}</span>
                        <select v-if="!isReadonly" class="form-control" v-model="condition.bundle[1].category">
                            <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                            <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                        </select>
                        <input v-else :readonly="isReadonly" v-model="condition.bundle[1].category.name" type="text"
                            class="form-control">
                        <span class="input-group-text input-icon-middle">{{ $t('QTYinput') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.bundle[1].quantity" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('itemQty') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- QUANTITY_ITEM_EXCEED -->
        <div v-if="conditionType == 'QUANTITY_ITEM_EXCEED'" class="condition-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="mt-2">
                        <div class="input-group mb-3">
                            <span class="input-group-text">{{ $t('whenBuyThese') }}</span>
                            <input :readonly="isReadonly" v-model.number="condition.threshold" type="number"
                                class="form-control">
                            <span class="input-group-text input-icon-right">{{ $t('piecesOrMore') }}</span>
                        </div>
                    </div>
                    <div>
                        <CButton v-if="!isReadonly" @click="openSelectProductModal(0)" class="mb-4 mt-4" color="success"
                            block>{{
                                $t('addProduct') }}</CButton>
                        <DataTable :items="getItems()" :fields="fields" responsive border>
                            <template v-for="(item, index) in getItems()" #img="{ item }">
                                <td>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img @error="noImgUrl" v-if="item.remoteImagePath !== ''"
                                            :src="item.remoteImagePath" fluid alt="Product Image"
                                            style="width: 50px; height: 50px; border-radius: 5px; object-fit: cover;" />
                                        <div v-else
                                            :style="{ width: '50px', height: '50px', borderRadius: '5px', backgroundColor: item.indexColor }"
                                            fluid alt="Product Image"></div>
                                    </div>
                                </td>
                            </template>
                            <template v-if="!isReadonly" #delete="{ item, index }">
                                <td @click="removeBadgeItem(index)" class="p-4">
                                    <i class="fi fi-rr-trash text-danger"></i>
                                </td>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>

        <!-- QUANTITY_CATEGORY_EXCEED -->
        <div v-if="conditionType == 'QUANTITY_CATEGORY_EXCEED'" class="condition-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="mt-2">
                        <div class="input-group mb-3">
                            <span class="input-group-text">{{ $t('whenBuyCategoriesTogether') }}</span>
                            <input :readonly="isReadonly" v-model.number="condition.threshold" type="number"
                                class="form-control">
                            <span class="input-group-text input-icon-right">{{ $t('piecesOrMore') }}</span>
                        </div>
                    </div>
                    <div>
                        <select v-if="!isReadonly"
                            style="color: var(--success);border-color: var(--success);text-align: center"
                            ref="categorySelect" class="form-control mb-4 mt-4" @change="handleCategoryChange()"
                            v-model="selectedCategory">
                            <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                            <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                        </select>
                        <DataTable :items="getCategoryItems()" :fields="fieldsCategory" responsive border>
                            <template v-if="!isReadonly" #delete="{ item, index }">
                                <td @click="removeBadgeCategory(index)" class="p-4 text-center">
                                    <i class="fi fi-rr-trash text-danger"></i>
                                </td>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>

        <!-- QUANTITY_RECEIPT_EXCEED -->
        <div v-if="conditionType == 'QUANTITY_RECEIPT_EXCEED'" class="condition-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('whenBuyItem') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.threshold" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('piecesOrMore') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- PRICE_ITEM_EXCEED -->
        <div v-if="conditionType == 'PRICE_ITEM_EXCEED'" class="condition-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('whenTotalItemPrice') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.threshold" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('bahtOrMore') }}</span>
                    </div>
                </div>
                <div>
                    <CButton v-if="!isReadonly" @click="openSelectProductModal(0)" class="mb-4 mt-4" color="success"
                        block>{{
                            $t('addProduct') }}</CButton>
                    <DataTable :items="getItems()" :fields="fields" responsive border>
                        <template v-for="(item, index) in getItems()" #img="{ item }">
                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <img @error="noImgUrl" v-if="item.remoteImagePath !== ''"
                                        :src="item.remoteImagePath" fluid alt="Product Image"
                                        style="width: 50px; height: 50px; border-radius: 5px; object-fit: cover;" />
                                    <div v-else
                                        :style="{ width: '50px', height: '50px', borderRadius: '5px', backgroundColor: item.indexColor }"
                                        fluid alt="Product Image"></div>
                                </div>
                            </td>
                        </template>
                        <template v-if="!isReadonly" #delete="{ item, index }">
                            <td @click="removeBadgeItem(index)" class="p-4">
                                <i class="fi fi-rr-trash text-danger"></i>
                            </td>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>

        <!-- PRICE_CATEGORY_EXCEED -->
        <div v-if="conditionType == 'PRICE_CATEGORY_EXCEED'" class="condition-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('whenTotalItemPrice') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.threshold" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('bahtOrMore') }}</span>
                    </div>
                </div>
                <div>
                    <select v-if="!isReadonly"
                        style="color: var(--success);border-color: var(--success);text-align: center"
                        ref="categorySelect" class="form-control mb-4 mt-4" @change="handleCategoryChange()"
                        v-model="selectedCategory">
                        <option selected :value="null">{{ $t('selectCategoryInput') }}</option>
                        <option v-for="category in allCategories" :value="category">{{ category.name }}</option>
                    </select>
                    <DataTable :items="getCategoryItems()" :fields="fieldsCategory" responsive border>
                        <template v-if="!isReadonly" #delete="{ item, index }">
                            <td @click="removeBadgeCategory(index)" class="p-4 text-center">
                                <i class="fi fi-rr-trash text-danger"></i>
                            </td>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>

        <!-- PRICE_RECEIPT_EXCEED -->
        <div v-if="conditionType == 'PRICE_RECEIPT_EXCEED'" class="condition-card-container row">
            <div class="fixed-price-card text-left mb-2">
                <div class="mt-2">
                    <div class="input-group mb-3">
                        <span class="input-group-text">{{ $t('whenTotalReceiptPrice') }}</span>
                        <input :readonly="isReadonly" v-model.number="condition.threshold" type="number"
                            class="form-control">
                        <span class="input-group-text input-icon-right">{{ $t('bahtOrMore') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <AppProductPicker :key="'pickerCondition' + refreshKey" ref="select_product_component_codition"
            :limitItem="limitSelectProduct" @update-selected-items="handleSelectedItems">
        </AppProductPicker>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppProductPicker from '@/components/AppProductPicker';
import pos from '@/services/local'
import DataTable from "@/containers/DataTable"
import util from '@/util/util'

export default {
    components: {
        AppProductPicker,
        DataTable
    },
    props: {
        conditionType: {
            type: String,
            required: true,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        conditionDisplay: {
            type: Object,
            required: false,
            default: () => ({}),
        },

    },
    data() {
        return {
            condition: {},
            refreshKey: 0,
            limitSelectProduct: 0,
            selectedIndex: 0,
            allCategories: [],
            selectedCategory: null,
            loading: true,
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        shopObjectId() {
            return localStorage.getItem('shopObjectId')
        },
        fields() {
            const baseFields = [
                { key: "index", label: '', _classes: "text-dark text-center", _style: "width:10%; vertical-align: middle; text-dark" },
                { key: "img", label: '', _classes: "text-dark text-center", _style: "width:10%; vertical-align: middle; text-dark" },
                { key: "pluCode", label: this.$t('pluCode'), _classes: "text-dark text-left", _style: "width:15%; vertical-align: middle; text-dark" },
                { key: "name", label: this.$t("productName"), _classes: "text-dark", _style: "width:50%; vertical-align: middle; text-dark" },
                { key: "category", label: this.$t("category"), _classes: "text-dark", _style: "width:50%; vertical-align: middle; text-dark" },
                { key: "price", label: this.$t("price"), _classes: "text-dark text-right", _style: "width:15%; vertical-align: middle; text-dark" },
            ];

            // Add the delete field only if isReadonly is false
            if (!this.isReadonly) {
                baseFields.push({ key: "delete", label: this.$t(""), _classes: "text-dark text-center", _style: "width:15%; vertical-align: middle; text-dark" });
            }

            return baseFields;
        },
        fieldsCategory() {
            const baseCategoryFields = [
                { key: "index", label: this.$t(""), _classes: "text-dark text-center", _style: "width:5%; vertical-align: middle; text-dark" },
                { key: "name", label: this.$t("categoryNameHeader"), _classes: "text-dark", _style: "width:50%; vertical-align: middle; text-dark" },
            ];

            // Add the delete field only if isReadonly is false
            if (!this.isReadonly) {
                baseCategoryFields.push({ key: "delete", label: this.$t(""), _classes: "text-dark text-center", _style: "width:5%; vertical-align: middle; text-dark" });
            }

            return baseCategoryFields;
        },
    },
    created() {
        this.loading = true
        this.setForm()
        this.setLimitSelectProduct()
        this.getCategory()
        this.setComponentDisplay()
    },
    methods: {
        setForm() {
            const defaultForms = {
                'BUNDLE_ITEM': { type: this.conditionType, bundle: [{ product: {}, quantity: null }, { product: {}, quantity: null }] },
                'BUNDLE_CATEGORY': { type: this.conditionType, bundle: [{ category: {}, quantity: null }, { category: {}, quantity: null }] },
                'QUANTITY_ITEM_EXCEED': { type: this.conditionType, items: [], threshold: null },
                'QUANTITY_CATEGORY_EXCEED': { type: this.conditionType, categories: [], threshold: null },
                'QUANTITY_RECEIPT_EXCEED': { type: this.conditionType, threshold: null },
                'PRICE_ITEM_EXCEED': { type: this.conditionType, items: [], threshold: null },
                'PRICE_CATEGORY_EXCEED': { type: this.conditionType, categories: [], threshold: null },
                'PRICE_RECEIPT_EXCEED': { type: this.conditionType, threshold: null },
            };
            const defaultForm = defaultForms[this.conditionType] || {};
            this.condition = defaultForm
        },
        setLimitSelectProduct() {
            if (this.conditionType == 'BUNDLE_ITEM') {
                this.limitSelectProduct = 1
            } else {
                this.limitSelectProduct = 20
            }
        },
        handleSelectedItems(items) {
            if (this.conditionType == 'QUANTITY_ITEM_EXCEED' || this.conditionType == 'PRICE_ITEM_EXCEED') {
                items.forEach(item => {
                    const existingItem = this.condition.items.find(
                        existing => existing.id === item.id
                    );
                    if (!existingItem && item.id !== null) {
                        this.condition.items.push({
                            id: item.id,
                            objectId: item.objectId,
                            name: item.name || '',
                            price: item.ProductSKU.SKUPrice || 0,
                            pluCode: item.PLUCode || '',
                            remoteImagePath: item.remoteImagePath || '',
                            category: item.category || {},
                            indexColor: item.indexColor || ''
                        })
                    }
                });
            } else {
                this.condition.bundle[this.selectedIndex].product = {
                    id: items[0].id,
                    objectId: items[0].objectId,
                    name: items[0].name,
                }
            }
            this.refreshKey += 1
        },
        openSelectProductModal(index) {
            if (!this.isReadonly) {
                this.selectedIndex = index
                this.$refs.select_product_component_codition.openModal()
            }
        },
        logCondition() {
            console.log(this.condition)
        },
        handleCategoryChange(index) {
            if (this.conditionType == 'QUANTITY_CATEGORY_EXCEED' || this.conditionType == 'PRICE_CATEGORY_EXCEED') {
                const existingItem = this.condition.categories.find(
                    existing => existing.id === this.selectedCategory.id
                )
                if (!existingItem) {
                    this.condition.categories.push(
                        {
                            id: this.selectedCategory.id,
                            objectId: this.selectedCategory.objectId,
                            name: this.selectedCategory.name,
                        }
                    )
                }
            } else {
                this.condition.bundle[index].category = {
                    id: this.selectedCategory.id,
                    objectId: this.selectedCategory.objectId,
                    name: this.selectedCategory.name,
                }
            }
            this.selectedCategory = null
        },
        async getCategory() {
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            let params = { page: 1, limit: 1000 }
            try {
                const URL = '/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId
                const res = await pos.get(URL, { params })
                this.allCategories = res.data?.data.map(category => ({
                    id: category.id,
                    objectId: category.objectId,
                    name: category.name || '',
                    indexIcon: category.indexIcon || '',
                    indexColor: category.indexColor || ','
                }))
            } catch (error) {
                console.log(error)
            }
        },
        removeBadgeItem(indexItem) {
            if (!this.isReadonly) {
                this.condition.items.splice(indexItem, 1)
            }

        },
        removeBadgeCategory(indexItem) {
            if (!this.isReadonly) {
                this.condition.categories.splice(indexItem, 1)
            }

        },
        getCondition() {
            if (this.conditionType == 'NO_CONDITION') {
                return {
                    type: "NO_CONDITION"
                }
            } else {
                return this.condition
            }

        },
        getItems() {
            return this.condition.items.map((item, index) => ({
                pluCode: item.pluCode,
                remoteImagePath: item.remoteImagePath,
                name: item.name,
                category: item.category.name || '',
                price: item.price,
                indexColor: item.indexColor || '',
                index: index + 1,
            }));
        },
        getCategoryItems() {
            return this.condition.categories.map((category, index) => ({
                name: category.name,
                id: category.id,
                indexIcon: category.indexIcon || '',
                indexColor: util.generateColor(category.indexColor || 0),
                index: index + 1,
            }));
        },
        setComponentDisplay() {
            if (this.isReadonly) {
                this.condition = this.conditionDisplay
            }
            this.loading = false
        },
        noImgUrl(event) {
            event.target.src = process.env.VUE_APP_NOIMAGE
        },
    },
    watch: {
        conditionDisplay: {
            immediate: true,
            handler(newVal) {
                if (this.isReadonly && newVal) {
                    this.condition = { ...newVal };
                    this.loading = false;
                }
            },
        },
    },
}
</script>
<style>
.bundle-card {
    width: 150px;
    aspect-ratio: 1/1;
    border: 1px solid #dddddd;
}

.fixed-price-card {
    width: 100%;
}

.condition-card-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    padding-bottom: 0px;
}

.badge-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
}

.input-icon-right {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-right: 0.25rem;
    border: 1px solid #ced4da;
}

.badge-custom-select {
    padding: 0.25em 0.4em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #4f5d73;
    background-color: #ebedef;
    border: none;

}

.input-icon-middle {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
}
</style>