import pos from '../services/local'
import i18n from '@/plugins/i18n'

const state = {
  shops: [],
  subsetsShop: `${localStorage.getItem('shopObjectId')}`,
  shopsUid: `${localStorage.getItem('shopsUid')}`,
  subsetsDate: {
    start: new Date(),
    end: new Date(),
  },
  permissions: {},
  isOwner: '',
  isLocked: '',
  lockDescription: '',
  readPath: [],
  dataPermissions: {}
}

const mutations = {
  GET_SHOP(state, shops) {
    state.shops = shops
  },
  SET_SHOP(state, subset) {
    state.subsetsShop = subset
  },
  SET_DATE(state, date) {
    state.subsetsDate = date
  },
  SET_UID(state, uid) {
    state.shopUid = uid
  },
  GET_PERMISSION(state, permissions) {
    state.permissions = permissions
  },
  GET_READPATH(state, readPath) {
    state.readPath = readPath
  },
  GET_DATAPERMISSIONS(state, dataPermissions) {
    state.dataPermissions = dataPermissions
  },
}

const actions = {
  getShop({ commit }) {
    const uid = `${localStorage.getItem('uid')}`
    const params = { refresh: false }

    pos
      .get('/api/v1.0/' + uid + '/Shop/accesslist/data', { params })
      .then((res) => {

        const data = res.data.data || [];
        const shops = [];
  
        data.forEach((shop) => {
          shop.branch.forEach((branch) => {
            if (branch?.objectId) {
              shops.push(branch);
            }
          });
        });
  
        const firstBranch = data[0]?.branch[0];
        if (firstBranch) {
          const { objectId, uid: shopsUid, language } = firstBranch;
          i18n.locale = language || 'en';
  
          // Set defaults if not already defined
          state.subsetsShop ||= objectId;
          state.shopsUid ||= shopsUid;
  
          // Persist in localStorage
          localStorage.setItem('shopsUid', localStorage.getItem('shopsUid') || shopsUid);
          localStorage.setItem('shopObjectId', objectId);
        }
  
        commit('GET_SHOP', shops);
      })
      .catch((error) => {
        console.log(error)
      })
  },
  setShop(context, subset) {
    let shops = state.shops
    let shop = shops.find((i) => i.objectId === subset)
    let shopUid = shop.uid
    localStorage.setItem('shopsUid', shopUid)
    localStorage.setItem('shopObjectId', subset)
    context.commit('SET_UID', shopUid)
    context.commit('SET_SHOP', subset)
  },
  setDate(context, date) {
    context.commit('SET_DATE', date)
  },
  getPermission({ commit }) {
    const uid = `${localStorage.getItem('uid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    
    pos
      .get('/api/v1.0/' + uid + '/Shop/access/permission/' + shopObjectId)
      .then((res) => {
        
        const permissions = res.data.data.permission
        const readPath = res.data.data.readPath
        const dataPermissions = res.data.data
        state.permissions = permissions
        state.readPath = readPath
        state.isOwner = res.data.data.isOwner
        state.isLocked = res.data.data.lock
        state.lockDescription = res.data.data.lockDescription
        commit('GET_PERMISSION', permissions)
        commit('GET_READPATH', readPath)
        commit('GET_DATAPERMISSIONS', dataPermissions )
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

const getters = {
  shops: (state) => state.shops,
  shopObjectId: (state) => state.subsetsShop,
  date: (state) => state.subsetsDate,
  shopsUid: (state) => state.shopsUid,
  permissions: (state) => state.permissions,
  readPath: (state) => state.readPath,
  isOwner: (state) => state.isOwner,
  isLocked: (state) => state.isLocked,
  lockDescription: (state) => state.lockDescription,
  dataPermissions: (state) => state.dataPermissions
}



export default {
  state,
  actions,
  mutations,
  getters,
}
